import md5 from "md5-hash";
import { removeAxiosHeader } from "../ServerConfig/Axios";
import { SystemUnderTest, TestMode } from "../Constants/Constants";
import Cookies from 'js-cookie'

export const md5HashHandler = (params: any) => {
  return md5(params);
};

export const JsonParse = (params: any) => {
  return JSON.parse(params);
};

export const JsonStringify = (params: any) => {
  return JSON.stringify(params);
};

export const AuthorizationHeader = (token: string) => {
  const auth = Cookies.get('token');
  const authToken = auth !== undefined || auth !== null ? auth : token
  const Authorization = { "Authorization" : `Bearer ${authToken}`};
  return Authorization;
};

export function renameSubElements(obj) {
  if (Array.isArray(obj)) {
    return obj.map(renameSubElements);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]: [string, any]) =>
        key === 'subElements' ? [obj.isArg ? 'args' : 'children', value && value.length > 0 ? renameSubElements(value) : null] : [key, renameSubElements(value)]
      )
    );
  }

  return obj;
};

export function FormatSelectOption (payload){
  let finalResult : any = [];
  payload?.map((D:any)=>{
    if(D.id !== undefined && D.name !== undefined ){
    finalResult.push({
      value : D.id,
      label : D.name
    })
    }else{
      finalResult.push({
        value : D,
        label : D
      })
    }
  })
  return finalResult;
}

export function FormatSelectOptionAsLabel (payload){
  let finalResult : any = [];
  payload?.map((D:any)=>{
    if(D.id !== undefined && D.name !== undefined ){
    finalResult.push({
      value : D.name,
      label : D.name
    })
    }else{
      finalResult.push({
        value : D,
        label : D
      })
    }
  })
  return finalResult;
}

export const handleLogoutOrEndSession = (state) => {
  removeAxiosHeader("Authorization");
  state.isAuth = false;
  localStorage.clear();
  localStorage.setItem("IsAuth", "false");
  Cookies.remove("token");
  state.token = "";
};

export function capitalizeFirstLetter(input: string): string {
  if (input.length === 0) {
    return input; 
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export const generateSimulationName = (testMode: string, systemUnderTest: string) => {
  const sut = systemUnderTest.toLowerCase()
  const tm = testMode.toLocaleLowerCase()
  if(sut === SystemUnderTest.Issuer){
    if(tm === TestMode.Client){
      return 'Acquirer'
    }
    else if(tm === TestMode.Server){
      return 'Acquirer & Network'
    }
  }
  else if(sut === SystemUnderTest.Acquirer){
    if(tm === TestMode.Client){
      return 'Issuer'
    }
    else if(tm === TestMode.Server){
      return 'Network & Issuer'
    }
  }
}

// function to get the sub rows keys
export const getSubRowKeys = (data: any): string[] => {
  return data.reduce((keys: string[], item: any) => {
    if (item.children && item.children.length > 0) {
      keys.push(item.id, ...getSubRowKeys(item.children));
    }
    return keys;
  }, []);
}

// accepts args - string: format (Numeric, Alphanumeric, Binary ..etc).
// returns the regex pattern for the given format
export const getPatternByFormat = (format) => {
  switch (format) {
    case 'Numeric':
      return /^\d*$/;
    case 'Alphanumeric':
      return /^[A-Za-z0-9]+$/;
    case 'Alphanumeric with special characters':
      return /^([a-zA-Z0-9 -_ ]+)$/;
    case 'Binary':
      return /^[0-9A-Fa-f]+$/;      
    case 'BinaryWithSemiColon':
      return /^[0-9A-Fa-f;]+$/;    
    default:
      return /^.*$/;
  }
}; 

// accepts args - string: key which is data-field id.
// returns the Data-element id excluding the version as defined in data-field template 
// args -- 23.2-35
// returns -- 35 
export const getParsedDEKey = (key: string) => {
  const keyElements = key.split('-');
  const parsedKey =
    keyElements.length === 2
      ? parseInt(keyElements[1], 10)
      : keyElements.slice(1).join('-');
  return parsedKey
}

export const isStringNullOrEmpty = (str: string) => {
  let result;
  if (typeof str === "string" && str.length === 0) {
    result = true;
  } else if (str === null) {
    result = true;
  } else {
    result = false;
  }
  return result;
}