import { createAsyncThunk } from "@reduxjs/toolkit";
import LithosApiServices from "../Services/ApiServices";

export const LoginHandler: any = createAsyncThunk(
  "lithosapi/login",
  async (req: any) => {
    const res = await LithosApiServices.LoginHandler(req);
    return res.data;
  }
);

export const Logout: any = createAsyncThunk(
  "lithosapi/Logout",
  async (req: any) => {
    const res = await LithosApiServices.Logout(req);
    return res.data;
  }
);

export const ChangePassword: any = createAsyncThunk(
  "lithosapi/ChangePassword",
  async (req: any) => {
    const res = await LithosApiServices.ChangePassword(req);
    return res.data;
  }
)

export const getUserById: any = createAsyncThunk(
  "lithosapi/getUserById",
  async (req: any) => {
    const res = await LithosApiServices.getUserById(req);
    return res.data;
  }
)

export const getUsersByType: any = createAsyncThunk(
  "lithosapi/getUsersByType",
  async (req: any) => {
    const res = await LithosApiServices.getUsersByType(req);
    return res.data;
  }
)

export const CreateUser: any = createAsyncThunk(
  "lithosapi/CreateUser",
  async (req: any) => {
    const res = await LithosApiServices.CreateUser(req);
    return res.data;
  }
)

export const UpdateUser: any = createAsyncThunk(
  "lithosapi/UpdateUser",
  async (req: any) => {
    const res = await LithosApiServices.UpdateUser(req);
    return res.data;
  }
)

export const deleteUser: any = createAsyncThunk(
  "lithosapi/deleteUser",
  async (req: any) => {
    const res = await LithosApiServices.deleteUser(req);
    return res.data;
  }
)

export const EndSession: any = createAsyncThunk(
  "lithosapi/EndSession",
  async (req: any) => {
    const res = await LithosApiServices.EndSession(req);
    return res.data;
  }
);

export const CreateProject: any = createAsyncThunk(
  "lithosapi/createproject",
  async (req: any) => {
    const res = await LithosApiServices.CreateProject(req);
    return res.data;
  }
);

export const getProjectsByUserId: any = createAsyncThunk(
  "lithosapi/getProjectsByUserId",
  async (req: any) => {
    const res = await LithosApiServices.getProjectsByUserId(req);
    return res.data;
  }
);

export const getProjectById: any = createAsyncThunk(
  "lithosapi/getProjectById",
  async (req: any) => {
    const res = await LithosApiServices.getProjectById(req);
    return res.data;
  }
);

export const CreateCardProFile: any = createAsyncThunk(
  "lithosapi/createCardProFile",
  async (req: any) => {
    const res = await LithosApiServices.CreateCardProfile(req);
    return res.data;
  }
);

export const CreateMerchantProFile: any = createAsyncThunk(
  "lithosapi/createMerchantProFile",
  async (req: any) => {
    const res = await LithosApiServices.CreateMerchantProfile(req);
    return res.data;
  }
);

export const UpdateMerchantProFile: any = createAsyncThunk(
  "lithosapi/updateMerchantProFile",
  async (req: any) => {
    const res = await LithosApiServices.UpdateMerchantProfile(req);
    return res.data;
  }
);

export const getCardProfilesByUserId: any = createAsyncThunk(
  "lithosapi/getCardProfilesByUserId",
  async (req: any) => {
    const res = await LithosApiServices.GetCardProfilesByUserId(req);
    return res.data;
  }
);

export const getMerchantProfilesByUserId: any = createAsyncThunk(
  "lithosapi/getMerchantProfilesByUserId",
  async (req: any) => {
    const res = await LithosApiServices.GetMerchantProfilesByUserId(req);
    return res.data;
  }
);

export const CreateChipKey: any = createAsyncThunk(
  "lithosapi/CreateChipKey",
  async (req: any) => {
    const res = await LithosApiServices.CreateChipKey(req);
    return res.data;
  }
);

export const getChipKeyByUserId: any = createAsyncThunk(
  "lithosapi/getChipKeyByUserId",
  async (req: any) => {
    const res = await LithosApiServices.GetChipKeyByUserId(req);
    return res.data;
  }
);

export const CreateTestCase: any = createAsyncThunk(
  "lithosapi/CreateTestCase",
  async (req: any) => {
    const res = await LithosApiServices.CreateTestCase(req);
    return res.data;
  }
);

export const CreateTestPlan: any = createAsyncThunk(
  "lithosapi/CreateTestPlan",
  async (req: any) => {
    const res = await LithosApiServices.CreateTestPlan(req);
    return res.data;
  }
);

export const getTestPlansByUserId: any = createAsyncThunk(
  "lithosapi/getTestPlansByUserId",
  async (req: any) => {
    const res = await LithosApiServices.getTestPlansByUserId(req);
    return res.data;
  }
);

export const getTestPlanTestCasesByUserId: any = createAsyncThunk(
  "lithosapi/getTestPlanTestCasesByUserId",
  async (req: any) => {
    const res = await LithosApiServices.getTestPlanTestCasesByUserId(req);
    return res.data;
  }
)

export const getTestPlanTestCasesByProjectId: any = createAsyncThunk(
  "lithosapi/getTestPlanTestCasesByProjectId",
  async (req: any) => {
    const res = await LithosApiServices.getTestPlanTestCasesByProjectId(req);
    return res.data;
  }
)

export const getTestCasesByProjectId: any = createAsyncThunk(
  "lithosapi/getTestCasesByProjectId",
  async (req: any) => {
    const res = await LithosApiServices.getTestCasesByProjectId(req);
    return res.data;
  }
);

export const fetchMasterData: any = createAsyncThunk(
  "lithosapi/fetchMasterData",
  async () => {
    const res = await LithosApiServices.fetchMasterData();
    return res.data;
  }
);

export const getDataFields: any = createAsyncThunk(
  "lithosapi/getDataFields",
  async (req :any) => {
    const res = await LithosApiServices.getDataFields(req);
    return res.data;
  }
);

export const projectUpdate: any = createAsyncThunk(
  "lithosapi/projectUpdate",
  async (req :any) => {
    const res = await LithosApiServices.projectUpdate(req);
    return res.data;
  }
);

export const projectTestCaseUpdate: any = createAsyncThunk(
  "lithosapi/projectTestCaseUpdate",
  async (req :any) => {
    const res = await LithosApiServices.projectTestCaseUpdate(req);
    return res.data;
  }
);

export const deleteProject: any = createAsyncThunk(
  "lithosapi/deleteProject",
  async (req :any) => {
    const res = await LithosApiServices.deleteProject(req);
    return res.data;
  }
);

export const projectTestcaseDeleteById: any = createAsyncThunk(
  "lithosapi/projectTestcaseDeleteById",
  async (req :any) => {
    const res = await LithosApiServices.projectTestcaseDeleteById(req);
    return res.data;
  }
);

export const copyProjectTestcase: any = createAsyncThunk(
  "lithosapi/copyProjectTestcase",
  async (req :any) => {
    const res = await LithosApiServices.copyProjectTestcase(req);
    return res.data;
  }
);

export const cardProfileUpdate: any = createAsyncThunk(
  "lithosapi/cardProfileUpdate",
  async (req :any) => {
    const res = await LithosApiServices.cardProfileUpdate(req);
    return res.data;
  }
);

export const deleteCardProfile: any = createAsyncThunk(
  "lithosapi/deleteCardProfile",
  async (req :any) => {
    const res = await LithosApiServices.deleteCardProfile(req);
    return res.data;
  }
);

export const getCardProfileByScheme: any = createAsyncThunk(
  "lithosapi/getCardProfileByScheme",
  async (req :any) => {
    const res = await LithosApiServices.getCardProfileByScheme(req);
    return res.data;
  }
);

export const getMerchantProfileByScheme: any = createAsyncThunk(
  "lithosapi/getMerchantProfileByScheme",
  async (req :any) => {
    const res = await LithosApiServices.getMerchantProfileByScheme(req);
    return res.data;
  }
);

export const deleteMerchantProfile: any = createAsyncThunk(
  "lithosapi/deleteMerchantProfile",
  async (req :any) => {
    const res = await LithosApiServices.deleteMerchantProfile(req);
    return res.data;
  }
);

export const copyCardProfile: any = createAsyncThunk(
  "lithosapi/copyCardProfile",
  async (req :any) => {
    const res = await LithosApiServices.copyCardProfile(req);
    return res.data;
  }
);

export const copyMerchantProfile: any = createAsyncThunk(
  "lithosapi/copyMerchantProfile",
  async (req :any) => {
    const res = await LithosApiServices.copyMerchantProfile(req);
    return res.data;
  }
);

export const chipKeyUpdate: any = createAsyncThunk(
  "lithosapi/chipKeyUpdate",
  async (req :any) => {
    const res = await LithosApiServices.chipKeyUpdate(req);
    return res.data;
  }
);

export const keySetUpdate: any = createAsyncThunk(
  "lithosapi/keySetUpdate",
  async (req :any) => {
    const res = await LithosApiServices.keySetUpdate(req);
    return res.data;
  }
);

export const deleteChipKey: any = createAsyncThunk(
  "lithosapi/deleteChipKey",
  async (req :any) => {
    const res = await LithosApiServices.deleteChipKey(req);
    return res.data;
  }
);

export const copyChipKey: any = createAsyncThunk(
  "lithosapi/copyChipKey",
  async (req :any) => {
    const res = await LithosApiServices.copyChipKey(req);
    return res.data;
  }
);

export const testPlanUpdate: any = createAsyncThunk(
  "lithosapi/testPlanUpdate",
  async (req :any) => {
    const res = await LithosApiServices.testPlanUpdate(req);
    return res.data;
  }
);

export const deleteTestPlan: any = createAsyncThunk(
  "lithosapi/deleteTestPlan",
  async (req :any) => {
    const res = await LithosApiServices.deleteTestPlan(req);
    return res.data;
  }
);

export const copyTestPlan: any = createAsyncThunk(
  "lithosapi/copyTestPlan",
  async (req :any) => {
    const res = await LithosApiServices.copyTestPlan(req);
    return res.data;
  }
);

export const getTestPlansById: any = createAsyncThunk(
  "lithosapi/getTestPlansById",
  async (req :any) => {
    const res = await LithosApiServices.getTestPlansById(req);    
    return res.data;
  }
);

export const getChipkeyById: any = createAsyncThunk(
  "lithosapi/getChipkeyById",
  async (req :any) => {
    const res = await LithosApiServices.getChipkeyById(req); 
    return res.data;
  }
);

export const getCardProfileById: any = createAsyncThunk(
  "lithosapi/getCardProfileById",
  async (req :any) => {
    const res = await LithosApiServices.getCardProfileById(req); 
    return res.data;
  }
);

export const getMerchantProfileById: any = createAsyncThunk(
  "lithosapi/getMerchantProfileById",
  async (req :any) => {
    const res = await LithosApiServices.getMerchantProfileById(req); 
    return res.data;
  }
);

export const getTestCaseById: any = createAsyncThunk(
  "lithosapi/getTestCaseById",
  async (req :any) => {
    const res = await LithosApiServices.getTestCaseById(req); 
    return res.data;
  }
);

export const executeTestCase: any = createAsyncThunk(
  "lithosapi/executeTestCase",
  async (req: any) => {
    const res = await LithosApiServices.executeTestCase(req);
    return res.data;
  }
)

export const bulkExecuteTestCases: any = createAsyncThunk(
  "lithosapi/bulkExecuteTestCases",
  async (req: any) => {
    const res = await LithosApiServices.bulkExecuteTestCase(req);
    return res.data;
  }
)

export const updateTestCase: any = createAsyncThunk(
  "lithosapi/updateTestCase",
  async (req :any) => {
    const res = await LithosApiServices.updateTestCase(req); 
    return res.data;
  }
);

export const getTestCaseGroups: any = createAsyncThunk(
  "lithosapi/getTestCaseGroups",
  async (req :any) => {
    const res = await LithosApiServices.getTestCaseGroups(req); 
    return res.data;
  }
);

export const SelectCategoryTestCases: any = createAsyncThunk(
  "lithosapi/SelectCategoryTestCases",
  async (req :any) => {
    const res = await LithosApiServices.SelectCategoryTestCases(req);
    return res.data;
  }
);

export const SearchLogs: any = createAsyncThunk(
  "lithosapi/SearchLogs",
  async (req :any) => {
    const res = await LithosApiServices.SearchLogs(req);
    return res.data;
  }
);

export const saveConfiguration: any = createAsyncThunk(
  "lithosapi/saveConfiguration",
  async (req:any) => {
    const res = await LithosApiServices.saveConfiguration(req);
    return res.data;
  }
);

export const createProfile: any = createAsyncThunk(
  "lithosapi/createProfile",
  async (req:any) => {
    const res = await LithosApiServices.createProfile(req);
    return res.data;
  }
);

export const deleteProfile: any = createAsyncThunk(
  "lithosapi/deleteProfile",
  async (req:any) => {
    const res = await LithosApiServices.deleteProfile(req);
    return res.data;
  }
);

export const GetAllUser: any = createAsyncThunk(
  "lithosapi/GetAllUser",
  async () => {
    const res = await LithosApiServices.GetAllUser();
    return res.data;
  }
);

export const CopyProject: any = createAsyncThunk(
  "lithosapi/CopyProject",
  async (req:any) => {
    const res = await LithosApiServices.CopyProject(req);
    return res.data;
  }
);

export const getTestCasesByTestPlanId: any = createAsyncThunk(
  "lithosapi/getTestCasesByTestPlanId",
  async (req:any) => {
    const res = await LithosApiServices.getTestCasesByTestPlanId(req);
    return res.data;
  }
);

export const createTestCaseByTestPlan: any = createAsyncThunk(
  "lithosapi/createTestCaseByTestPlan",
  async (req:any) => {
    const res = await LithosApiServices.createTestCaseByTestPlan(req);
    return res.data;
  }
);

export const updateTestCaseByTestPlan: any = createAsyncThunk(
  "lithosapi/updateTestCaseByTestPlan",
  async (req:any) => {
    const res = await LithosApiServices.updateTestCaseByTestPlan(req);
    return res.data;
  }
);

export const getTestPlanTestCaseById: any = createAsyncThunk(
  "lithosapi/getTestCaseByTestPlan",
  async (req:any) => {
    const res = await LithosApiServices.getTestPlanTestCaseById(req);
    return res.data;    
  }
);

export const deleteTestCaseByTestPlan: any = createAsyncThunk(
  "lithosapi/deleteTestCaseByTestPlan",
  async (req:any) => {
    const res = await LithosApiServices.deleteTestCaseByTestPlan(req);
    return res.data;    
  }
);

export const copyTestPlanTestCase: any = createAsyncThunk(
  "lithosapi/copyTestPlanTestCase",
  async (req:any) => {
    const res = await LithosApiServices.copyTestPlanTestCase(req);
    return res.data;    
  }
);

export const getTestCaseGroupsByTestPlanId: any = createAsyncThunk(
  "lithosapi/getTestCaseGroupsByTestPlanId",
  async (req:any) => {
    const res = await LithosApiServices.getTestCaseGroupsByTestPlanId(req);
    return res.data;    
  }
); 

export const getTestPlanGroups: any = createAsyncThunk(
  "lithos/getTestPlanGroups",
  async (req:any) => {
    const res = await LithosApiServices.getTestPlanGroups(req);
    return res.data;
  }
)

export const SelectCategoryTestCasesByTestPlanId: any = createAsyncThunk(
  "lithosapi/SelectCategoryTestCasesByTestPlanId",
  async (req:any) => {
    const res = await LithosApiServices.SelectCategoryTestCasesByTestPlanId(req);
    return res.data;    
  }
);

export const getHostLog: any = createAsyncThunk(
  "lithosapi/getHostLog",
  async (req: any) => {
    const res = await LithosApiServices.getHostLog(req);
    return res.data;
  }
);

export const downloadLogs: any = createAsyncThunk(
  "lithosapi/downloadLogs",
  async (req: any) => {
    const res = await LithosApiServices.downloadLogs(req);
    return res.data;
  }
);

export const acceptHostLogErrors: any = createAsyncThunk(
  "lithosapi/acceptHostLogErrors",
  async (req: any) => {
    const res = await LithosApiServices.acceptHostLogErrors(req);
    return res.data;
  }
);

export const rejectHostLogErrors: any = createAsyncThunk(
  "lithosapi/downloadLogs",
  async (req: any) => {
    const res = await LithosApiServices.rejectHostLogErrors(req);
    return res.data;
  }
);

export const getPassCriteriaLog: any = createAsyncThunk(
  "lithosapi/getPassCriteriaLog",
  async (req: any) => {
    const res = await LithosApiServices.getPassCriteriaLog(req);
    return res.data;
  }
);

export const importTestPlan: any = createAsyncThunk(
  "lithosapi/importTestPlan",
  async (req: any) => {
    const res = await LithosApiServices.importTestPlan(req);
    return res.data;
  }
);

export const getLogs: any = createAsyncThunk(
  "lithosapi/getLogs",
  async (req: any) => {
    const res = await LithosApiServices.getLogs(req);
    return res.data;
  }
)

export const getKeySet: any = createAsyncThunk(  
  "lithosapi/getKeySet",
  async (req: any) => {
    const res = await LithosApiServices.getKeySet(req);
    return res.data;
  }
)

export const checkConnectionStatus: any = createAsyncThunk(
  "lithosapi/checkConnectionStatus",
  async (req: any) => {
    const res = await LithosApiServices.checkConnectionStatus(req);
    return res.data;
  }
)

export const checkStatus: any = createAsyncThunk(
  "lithosapi/checkStatus",
  async () => {
    const res = await LithosApiServices.checkStatus();
    return res.data;
  }
)

export const checkUniqueAcqIdTerId: any = createAsyncThunk(
  "lithosapi/checkUniqueAcqIdTerId",
  async (req: any) => {
    const res = await LithosApiServices.checkUniqueAcqIdTerId(req);
    return res.data;
  }
)

export const checkProjectUniquePANAndAmount: any = createAsyncThunk(
  "lithosapi/checkProjectUniquePANAndAmount",
  async (req: any) => {
    const res = await LithosApiServices.checkProjectUniquePANAndAmount(req);
    return res.data;
  }
)

export const checkTestPlanUniquePANAndAmount: any = createAsyncThunk(
  "lithosapi/checkTestPlanUniquePANAndAmount",
  async (req: any) => {
    const res = await LithosApiServices.checkTestPlanUniquePANAndAmount(req);
    return res.data;
  }
)

export const getDashboardData: any = createAsyncThunk(
  "lithosapi/getDashboardData",
  async (req: any) => {
    const res = await LithosApiServices.getDashboardData(req);
    return res.data;
  }
)

export const getOrganizations: any = createAsyncThunk(
  "lithosapi/getOrganizations",
  async () => {
    const res = await LithosApiServices.getOrganizations();
    return res.data;
  }
)

export const CreateOrganization: any = createAsyncThunk(
  "lithosapi/CreateOrganization",
  async (req: any) => {
    const res = await LithosApiServices.CreateOrganization(req);
    return res.data;
  }
)

export const UpdateOrganization: any = createAsyncThunk(
  "lithosapi/UpdateOrganization",
  async (req: any) => {
    const res = await LithosApiServices.UpdateOrganization(req);
    return res.data;
  }
)

export const DeleteOrganization: any = createAsyncThunk(
  "lithosapi/DeleteOrganization",
  async (req: any) => {
    const res = await LithosApiServices.DeleteOrganization(req);
    return res.data;
  }
)

export const UpdateProjectTestPlanStatus: any = createAsyncThunk(
  "lithosapi/UpdateProjectTestPlanStatus",
  async (req: any) => {
    const res = await LithosApiServices.UpdateProjectTestPlanStatus(req);
    return res.data;
  }
)

export const UpdateProjectTestCaseStatus: any = createAsyncThunk(
  "lithosapi/UpdateProjectTestCaseStatus",
  async (req: any) => {
    const res = await LithosApiServices.UpdateProjectTestCaseStatus(req);
    return res.data;
  }
)