import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { Button, DatePicker, Form } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

const FilterForm = ({ onFinish, isFinancial })  => {
    const location = useLocation()
    const [form] = Form.useForm()
    const [isSystemLogs, setIsSystemLogs] = useState(false)

    const handleClearClick = () => {
      form.resetFields(); 
    }

    useEffect(() => {
      if(location.pathname === '/systemlogs'){
        setIsSystemLogs(true)
      }
      form.resetFields()
    }, [isFinancial])

    const handleSearchClick = async () => {
      try {
        const values = await form.validateFields();
        const startDate = values.DateRange?.[0]
        const endDate = values.DateRange?.[1] 
  
        const startIST = startDate?.format('MM/DD/YYYY');
        const endIST = endDate?.format('MM/DD/YYYY');
  
        const { DateRange, ...modifiedValues } = values;
  
        modifiedValues.startDate = startIST;
        modifiedValues.endDate = endIST;
  
        onFinish(modifiedValues);
      } catch (error) {
        console.error('Form validation error:', error);
      }
    };

    return (
      <div className="filter-form-container">
        <Form
          form={form}
          name="basic"
          className="modal-form-container filter-form"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="modal-form"
            name="STAN"
            rules={[
              { pattern: /^\d{6}$/, message: "STAN must be a 6-digit numeric value" } 
            ]}
          >
            <FloatingLabelInput label='STAN' placeholder="STAN" />
          </Form.Item>
          {isSystemLogs && 
            <Form.Item
            className="modal-form"
            name="AcquirerId"
            rules={[
              { pattern: /^\d+$/, message: "Acquirer ID must be a numeric value" } 
            ]}
          >
            <FloatingLabelInput label='Acquirer ID' placeholder="Acquirer ID" />
          </Form.Item>
          }
          <Form.Item
            className="modal-form"
            name="RRN"
            rules={[
              { pattern: /^[a-zA-Z0-9]{12}$/, message: "RRN must be a 12-digit alphanumeric value" } 
            ]}
          >
            <FloatingLabelInput label='RRN' placeholder="RRN" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="DateRange"
            
          >
              <RangePicker style={{width: '100%'}} size="large"/>
          </Form.Item>
          {isFinancial && (
            <>
            {isSystemLogs && 
              <Form.Item
                className="modal-form"
                name="TerminalId"
                rules={[
                  { pattern: /^[a-zA-Z0-9]{8}$/, message: "Terminal ID must be a 8-digit alphanumeric value" } 
                ]}
              >
                <FloatingLabelInput label='Terminal ID' placeholder="Terminal ID" />
              </Form.Item>
            }
            <Form.Item
              className="modal-form"
              name="Pan"
              rules={[
                { pattern: /^[0-9]{12,19}$/, message: "PAN length shall be between 12 to 19 numeric value" } 
              ]}
            >
              <FloatingLabelInput label='PAN' placeholder="PAN" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="Amount"
              rules={[
                { pattern: /^[0-9]{12}$/, message: "Amount must be a 12-digit numeric value" }
              ]}
            >
              <FloatingLabelInput label='Amount' placeholder="Amount" />
            </Form.Item>
          </>
          )}
        </Form>
        <div className="button-container">
          <Button type="default" className="clear-button" onClick={handleClearClick}>
            Clear
          </Button>
          <Button type="primary" htmlType="submit" className="search-button" onClick={handleSearchClick}>
            Search
          </Button>
        </div>
      </div>
    );
  };
  
  export default FilterForm;