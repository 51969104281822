import { Col, Form, Input, Row, Space, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import TableContainer from "../../Components/Table/TableContainer";
import SelectionIcon from "../../Assets/selectionIcon.svg";
import {
  CreateMerchantProFile,
  UpdateMerchantProFile,
  copyMerchantProfile,
  getMerchantProfilesByUserId,
  deleteMerchantProfile,
} from "../../Redux/Actions/Actions";
import {
  BreadCrumbConfig,
  HeaderTitle,
} from "../../Redux/Reducers/UtilReducer";
import { JsonParse, getPatternByFormat } from "../../Utils/Utils";
import {
  SearchOutlined,
} from "@ant-design/icons";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import Edit from "../../Assets/Edit.svg"
import Copy from "../../Assets/Copy.svg"
import Delete from "../../Assets/Delete.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import { SchemeTag } from "../../Constants/Constants";
import CreateEditMerchantProfileModal from "./CreateEditMerchantProfileModal";
import EditableFormItem from "../../Components/InlineEdit/EditableFormItem";
import { ShowToaster } from "../../Components/Notifications/Notifications";

export default function MerchantProfile() {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [merchantProfile, setMerchantProfile] = useState<any>({})
  const [api, contextHolder] = notification.useNotification()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [loading, setLoading] = useState(false);
  const [merchantProfileList, setMerchantProfileList] = useState<any>(useSelector(
    (state: any) => state.lithosApiReducer.merchantProfileList
  ))
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [merchantProfileId, setMerchantProfileId] = useState('')
  const [searchedText, setSearchedText] = useState('')
  const [merchantProfileNames, setMerchantProfileNames] = useState<any>([''])
  const [referredList, setReferredList] = useState<any>([])

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  useEffect(() => {
    dispatch(HeaderTitle("Merchant profiles"));
    dispatch(
      BreadCrumbConfig({
        title: "Merchant profiles",
        href: "/merchantprofiles",
        preventPush: true,
      } as any)
    );
    RenderLoading()
  }, []);

  const RenderLoading = () => {
    setLoading(true);
    dispatch(getMerchantProfilesByUserId(userDetails?.Id)).then((data: any) => {
      setMerchantProfileList(data?.payload?.merchantProfileList);
      data?.payload?.merchantProfileList?.map((merchantProfile: any) => (
        merchantProfile?.name !== "" && !merchantProfileNames.includes(merchantProfile.name) && setMerchantProfileNames((merchantProfileNames: any) => [...merchantProfileNames, merchantProfile.name.toLowerCase()])
      ))
      setLoading(false);
    });
  };

  const oncloseModal = () => {
    setIsModalOpen(false)
    setIsUpdate(false)
    setIsDelete(false)
    setIsCopy(false)
  };

  const OnClickEvent = () => {
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    setLoading(true)
    if (isModalOpen === true) {
      dispatch(CreateMerchantProFile(values)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsModalOpen(false)
        setMerchantProfileList([res?.payload?.merchantProfile, ...merchantProfileList])
      });
    } else if(isUpdate === true) {
      dispatch(UpdateMerchantProFile(values)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsUpdate(false)
        updateRecord(res)
      });
    } else if(isDelete === true){
      dispatch(deleteMerchantProfile(merchantProfileId)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsDelete(false)
        setMerchantProfileList(merchantProfile =>
          merchantProfile.filter(mp => {
            return mp.id !== merchantProfileId;
          }),
        );
        setMerchantProfileNames(names => 
          names.filter(name => {
            return name != merchantProfile?.name;
          }))
      });
    } else if(isCopy === true){
      dispatch(copyMerchantProfile({values, merchantProfileId: merchantProfileId})).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsCopy(false)
        setMerchantProfileList([res?.payload?.merchantProfile, ...merchantProfileList])
      });
    }
  };
  
  const columns: ColumnsType = [
    {
      title: "Merchant profile",
      dataIndex: "name",
      key: "name",
      width: 330,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record?.name).toLowerCase().includes(value.toLowerCase())
      },
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {
            setMerchantProfile(record);
            setMerchantProfileId(record?.id)
          },
        };
      },
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer" }}>
          {text}
        </div>
      ),
    },
    {...(userType === 'TESTER') 
    ? {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text: any, record: any) => (
        <>
        {userType === "TESTER" && <div className="combined-icon" onClick={() => setMerchantProfile(record)}>
          <Space size={'large'}>
            <button
              onClick={() => {
                setIsCopy(true);
                setMerchantProfileId(record.id)
              }}
              className="project-content no-border-bg-transparent"
            >
              <Tooltip title="Copy">
                <img className="icons" src={Copy} alt="copy icon"/>
              </Tooltip>
            </button>
            {!record?.isCertification && <><button
              onClick={() => {
                  setMerchantProfile(record)
                  setIsUpdate(true)
              }}
              className="project-content no-border-bg-transparent"
            >
              <Tooltip title="Edit">
                <img className="icons" src={Edit} alt="edit icon"/>
              </Tooltip>
            </button>
            <button
              onClick={() => {
                setIsDelete(true)
                setReferredList(record.referredIn)
                setMerchantProfileId(record.id)
              }}
              className="project-content no-border-bg-transparent"
            >
              <Tooltip title="Delete">
                <img className="icons" src={Delete} alt="delete icon" />
              </Tooltip>
            </button></>}
          </Space>
        </div>}
        </>
      ),
    } : null},
  ];
  const MerchantProfileData: any = merchantProfileList;

  const handleUpdate = (changedValues) => {
    const simplifiedValues = Object.fromEntries(
      Object.entries(merchantProfile).map(([key, value]: [string, any]) => {
        if (value && typeof value === 'object' && 'value' in value) {
          return [key, value?.value];
        } else {
          return [key, value];
        }
      })
    );
    const updatedMerchantProfile = { ...simplifiedValues, ...changedValues };
    dispatch(UpdateMerchantProFile(updatedMerchantProfile)).then((res) => {
      updateRecord(res)
    });
  };

  const updateRecord = (res: any) => {
    const updatedMerchantProfiles = merchantProfileList.map(mp => {
      if (mp.id === res?.payload?.merchantProfile?.id) {
        return res?.payload?.merchantProfile;
      } else {
        return mp;
      }
    });
    setMerchantProfileList(updatedMerchantProfiles);
    setMerchantProfile(res?.payload?.merchantProfile)
  }

  const rowClassName = (record) => {
    if (merchantProfile && merchantProfile?.id === record.id) {
      return 'selected-row';
    }
    return '';
  }; 

  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Row className="card-profile-row">
        <Col className="card-profile-col" xs={2} sm={4} md={8} lg={8} xl={8}>
          <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {
              const currValue = e.target.value;
              setSearchedText(currValue)
            }}/>
          <TableContainer
            tableData={MerchantProfileData}
            column={columns}
            className={"card-profile-table"}
            loading={loading}
            scrollY='65vh'
            rowClassName={rowClassName}
          />
        </Col>
        <Col
          className="card-profile-col"
          span={1}
          xs={2}
          sm={4}
          md={15}
          lg={15}
          xl={15}
          style={{marginTop: '0.6%'}}
        >
          {merchantProfile.name === undefined ? (
            <div className="card-profile-container">
              <img className="card-profile-img" src={SelectionIcon} alt="" />
              <div className="card-profile-title">Please select a merchant profile</div>
            </div>
          ) : (
            <div className="card-profile-content-container">
              <div className="card-profile-title-name">{merchantProfile?.name}</div>
              <div className="card-data-container">
                <div className="card-content-container">
                  <div className="key-title">Scheme</div>
                  <div className="card-profile-value">{SchemeTag[merchantProfile?.scheme] || merchantProfile?.scheme}</div>
                </div>
                {userType !== 'TESTER' && <div className="card-content-container">
                  <div className="key-title">Owner</div>
                  <div className="card-profile-value">{merchantProfile?.owner?.email}</div>
                </div>}
                <Form
                  form={form}
                  key={merchantProfile?.id}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="card-content-container">
                    <div className="key-title">Merchant category code - (DE-{merchantProfile?.merchantCategoryCode?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="merchantCategoryCode"
                        rules={[
                          { required: true, message: "Merchant category code cannot be empty!" },
                          { pattern: getPatternByFormat(merchantProfile?.merchantCategoryCode?.format), message: `Merchant category code should be ${merchantProfile?.merchantCategoryCode?.maxLength} digits in ${merchantProfile?.merchantCategoryCode?.format} format` },
                          { max: merchantProfile?.merchantCategoryCode?.maxLength, message: `Merchant category code should be maximum of ${merchantProfile?.merchantCategoryCode?.maxLength} digit`},
                          { min: merchantProfile?.merchantCategoryCode?.minLength, message: `Merchant category code should be minimum of ${merchantProfile?.merchantCategoryCode?.minLength} digit`}
                        ]}
                        value={merchantProfile?.merchantCategoryCode?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Acquirer country code - (DE-{merchantProfile?.acquirerCountryCode?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="acquirerCountryCode"
                        rules={[
                          { required: true, message: "Acquirer country code cannot be empty!" },
                          { pattern: getPatternByFormat(merchantProfile?.acquirerCountryCode?.format), message: `Acquirer country code should be ${merchantProfile?.acquirerCountryCode?.maxLength} digits in ${merchantProfile?.acquirerCountryCode?.format} format` },
                          { max: merchantProfile?.acquirerCountryCode?.maxLength, message: `Acquirer country code should be maximum of ${merchantProfile?.acquirerCountryCode?.maxLength} digit`},
                          { min: merchantProfile?.acquirerCountryCode?.minLength, message: `Acquirer country code should be minimum of ${merchantProfile?.acquirerCountryCode?.minLength} digit`}
                        ]}
                        value={merchantProfile?.acquirerCountryCode?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile?.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Acquirer ID - (DE-{merchantProfile?.acquirerID?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="acquirerID"
                        rules={[
                          { required: true, message: "Acquirer ID cannot be empty" },
                          {
                            message: `Acquirer ID length shall be ${merchantProfile?.acquirerID?.minLength} to ${merchantProfile?.acquirerID?.maxLength} digits!`,
                            validator: (_, val) => {
                              if (val !== "" && (val?.length < merchantProfile?.acquirerID?.minLength || val?.length > merchantProfile?.acquirerID?.maxLength)) {
                                return Promise.reject(new Error('Acquirer ID length shall be 6 to 11 digits!'));
                              } else {
                                return Promise.resolve();
                              }
                            }
                          },
                          { pattern: getPatternByFormat(merchantProfile?.acquirerID?.format), message: `Acquirer ID should be in ${merchantProfile?.acquirerID?.format} format!`} ,
                        ]}
                        value={merchantProfile?.acquirerID?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile?.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Terminal ID - (DE-{merchantProfile?.terminalID?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="terminalID"
                        rules={[
                          { required: true, message: "Terminal ID cannot be empty" },
                          { len: merchantProfile?.terminalID?.maxLength, message: `Terminal ID should be ${merchantProfile?.terminalID?.maxLength} characters` },
                          { pattern: getPatternByFormat(merchantProfile?.terminalID?.format), message: `Terminal ID should be ${merchantProfile?.terminalID?.format}` },
                        ]}  
                        value={merchantProfile?.terminalID?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile?.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Card acceptor ID - (DE-{merchantProfile?.cardAcceptorID?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="cardAcceptorID"
                        rules={[
                          { required: true, message: "Card acceptor ID cannot be empty!" },
                          { len: merchantProfile?.cardAcceptorID?.maxLength, message: `Card acceptor ID should be ${merchantProfile?.cardAcceptorID?.maxLength} characters` },
                          { pattern: getPatternByFormat(merchantProfile?.cardAcceptorID?.format), message: `Card acceptor ID should be ${merchantProfile?.cardAcceptorID?.format}` },
                        ]}    
                        value={merchantProfile?.cardAcceptorID?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile?.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Card acceptor name/location - (DE-{merchantProfile?.cardAcceptorName_Location?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="cardAcceptorName_Location"
                        rules={[
                          { required: true, message: "Card acceptor name/location cannot be empty!" },
                          { pattern: getPatternByFormat(merchantProfile?.cardAcceptorName_Location?.format), message: `Card acceptor name/location length shall be ${merchantProfile?.cardAcceptorName_Location?.minLength} to ${merchantProfile?.cardAcceptorName_Location?.maxLength} digits in ${merchantProfile?.cardAcceptorName_Location?.format} format` },
                        ]} 
                        value={merchantProfile?.cardAcceptorName_Location?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile?.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Transaction currency code - (DE-{merchantProfile?.transactionCurrencyCode?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="transactionCurrencyCode"
                        rules={[
                          { required: true, message: "Transaction currency code cannot be empty!" },
                          { pattern: getPatternByFormat(merchantProfile?.transactionCurrencyCode?.format), message: `Transaction currency code should be ${merchantProfile?.transactionCurrencyCode?.maxLength} digits in ${merchantProfile?.transactionCurrencyCode?.format} format` },
                          { max: merchantProfile?.transactionCurrencyCode?.maxLength, message: `Transaction currency code should be maximum of ${merchantProfile?.transactionCurrencyCode?.maxLength} digit`},
                          { min: merchantProfile?.transactionCurrencyCode?.minLength, message: `Transaction currency code should be minimum of ${merchantProfile?.transactionCurrencyCode?.minLength} digit`}
                        ]}
                        value={merchantProfile?.transactionCurrencyCode?.value}
                        onUpdate={handleUpdate}
                        isEditable={merchantProfile?.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {isModalOpen && <CreateEditMerchantProfileModal
          isOpen={isModalOpen}
          onClose={oncloseModal}
          onFinish={onFinish}
          isUpdate={false}
      />}
      {isUpdate && <CreateEditMerchantProfileModal
          isOpen={isUpdate}
          onClose={oncloseModal}
          onFinish={onFinish}
          value={merchantProfile}
          isUpdate={true}
      />}
      {isDelete && <DeleteModal
          isOpen={isDelete}
          onClose={oncloseModal}
          onFinish={onFinish}
          title="Merchant profile"
          values={referredList}
      />}
      {isCopy && <CopyModal
          isOpen={isCopy}
          onClose={oncloseModal}
          onFinish={onFinish}
          title="Merchant profile"
          values={merchantProfileNames}
      />}
    </>
  );
}