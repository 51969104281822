
import { IModal } from "../../Interface/Interfaces";
import { useSelector } from "react-redux";
import { Form, Modal } from "antd";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";

const ChipKey: React.FC<IModal> = ({isOpen, onClose, onFinish }) => {
    const chipkeyDto = useSelector((state:any) => state.lithosApiReducer.keySet);
    const [form] = Form.useForm();

    const initialValues = {
      ...chipkeyDto?.properties?.reduce((acc, property) => {
        acc[property.type] = property.value;
        return acc;
      }, {}),
      Keytype: chipkeyDto?.keyType,
    };
    
  
    return (
      <Modal
        className="create-chip-key-modal"
        title="Update Chip key"
        centered
        open={isOpen}
        onOk={()=>form.submit()}
        okText="Update"
        cancelText="Discard"
        onCancel={()=>{onClose();form.resetFields()}}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.Item
            className="modal-form"
            name="Keytype"
          >
            <FloatingLabelInput label='Keytype' placeholder="Keytype" disabled="true"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="AC"
            rules={[
              { required: true, message: "Please enter the AC!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "AC should be in hexadecimal format!" },
              { len: 32, message: 'AC should be 32 digit' }
            ]}
          >
            <FloatingLabelInput label='AC' placeholder="AC"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="SMC"
            rules={[
              { required: true, message: "Please enter the SMC!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "SMC should be in hexadecimal format!" },
              { len: 32, message: 'SMC should be 32 digit' }
            ]}
          >
            <FloatingLabelInput label='SMC' placeholder="SMC"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="SMI"
            rules={[
              { required: true, message: "Please enter the SMI!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "SMI should be in hexadecimal format!" },
              { len: 32, message: 'SMI should be 32 digit' }
            ]}
          >
          <FloatingLabelInput label='SMI' placeholder="SMI"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="CVV"
            rules={[
              { required: true, message: "Please enter the CVV!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "CVV should be in hexadecimal format!" },
              { len: 32, message: 'CVV should be 32 digit' }
            ]}
          >
          <FloatingLabelInput label='CVV' placeholder="CVV"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="ICVV"
            rules={[
              { required: true, message: "Please enter the iCVV!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "iCVV should be in hexadecimal format!" },
              { len: 32, message: 'iCVV should be 32 digit' }
            ]}
          >
          <FloatingLabelInput label='iCVV' placeholder="iCVV"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="CVV2"
            rules={[
              { required: true, message: "Please enter the CVV2!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "CVV2 should be in hexadecimal format!" },
              { len: 32, message: 'CVV2 should be 32 digit' }
            ]}
          >
          <FloatingLabelInput label='CVV2' placeholder="CVV2"/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="dCVV"
            rules={[
              { required: true, message: "Please enter the dCVV!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "dCVV should be in hexadecimal format!" },
              { len: 32, message: 'dCVV should be 32 digit' }
            ]}
          >
          <FloatingLabelInput label='dCVV' placeholder="dCVV"/>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

export default ChipKey;