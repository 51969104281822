import { Button, Col, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IOnClick } from "../../Interface/Interfaces";
import BreadCrumbContainer from "../BreadCrumb/BreadCrumbContainer";
import { checkHeaderExistence, isTokenExpired } from "../../ServerConfig/Axios";
import { getTestPlansById } from "../../Redux/Actions/Actions";
import { SystemUnderTest } from "../../Constants/Constants";
import Cookies from 'js-cookie'
import { HubConnectionBuilder } from "@microsoft/signalr";
import { JsonParse } from "../../Utils/Utils";
import { NotificationHandler } from "../../Redux/Reducers/LithosApiReducers";

const SubHeader: React.FC<IOnClick> = ({ OnClickEvent, IsSelectAll, SelectedIds }) => {
  const [api, _] = notification.useNotification()
  const location = useLocation()
  const dispatch = useDispatch()
  const UtilData = useSelector((state: any) => state.utilReducer)
  const navigate = useNavigate()
  const [highlightedButton, setHighlightedButton] = useState('btnFinancialLogs')
  const [highlightedLog, setHighlightedLog] = useState('hostLog')
  const [disabled, setDisabled] = useState(false)
  const testCaseSUT = localStorage.getItem('TestCaseSUT')
  const [connection, setConnection] = useState<any>();
  const userDetails = JsonParse(localStorage.getItem('UserDetails'));

  const testCases = useSelector(
    (state: any) => state.lithosApiReducer.categoryTestCases
  )
  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  );
  const selectedTestCases = testCases?.filter(testCase => SelectedIds?.includes(testCase?.id));

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );

  const hasCustomTestplan = masterData?.some(
    (item) => item?.properties?.CustomTestplan === true
  );

  const hasCertificationTestplan = masterData?.some(
    (item) => item?.properties?.CertificationTestplan === true
  );
  
  useEffect(() => {
    const token = Cookies.get('token');
    const testPlanId = localStorage.getItem('TestPlanId');
    const currentPath = location.pathname;
    if (currentPath !== "/login" && (!checkHeaderExistence('Authorization') || isTokenExpired(token))) {
      navigate("/sessiontimeout");
    }
    if(currentPath === '/testplan/testcases'){
      dispatch(getTestPlansById(testPlanId)).then((res) => {
        setDisabled(res?.payload?.testPlan?.isCertification);
      });
    }
  }, [location.pathname]);
  
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL || "";
    const connection = new HubConnectionBuilder()
      .withUrl(`${baseUrl}hubs/notifications?email=${userDetails?.Id}`)
      .withAutomaticReconnect()
      .build();
    setConnection(connection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("PostMessage", (receivedNotification) => {
            notification.info({
              message: receivedNotification.message,
              description: '',
              placement: "bottomRight",
              className: "custom-notification",
            });
            dispatch(NotificationHandler(receivedNotification))
          });
        })
        .catch((error) => console.error(error));
  
      return () => {
        connection.stop();
      };
    }
  }, [connection]);

  const handleButtonClick = (value: string) => {
    setHighlightedButton(value);
    OnClickEvent(value);
  };

  const handleLogButtonClick = (value : string) =>{
    setHighlightedLog(value);
    OnClickEvent(value);
  }

  return (
    <div className="subheader-container">
        <div className="header-title-breadcrumb-wrapper">
          <div className="subheader-title">{UtilData.header_title}</div>
          <div className="subheader-breadcrumb">
            <BreadCrumbContainer /> 
          </div>
        </div>
        {location.pathname === "/projects" && (
          <div className="subheader-button-container">
            {/* <div className="subheader-btn-1">
              <NavLink to={"/CopyFromOther"}>
                <Button className="subheader-btn">
                  <CopyOutlined />
                  Copy from
                </Button>
              </NavLink>
            </div> */}
            {userType === "TESTER" && <div className="subheader-btn-2">
              <Button
                type="primary"
                onClick={OnClickEvent}
                className="subheader-btn-2"
              >
                Create Project
              </Button>
            </div>}
          </div>
        )}
        {location.pathname === "/projectdetails" && (
          <div className="subheader-button-container">
            {(hasCustomTestplan || hasCertificationTestplan) && userType === "TESTER" && <div className="subheader-btn-2">
              <Button onClick={()=> OnClickEvent('Use-my-test')} className="subheader-btn">Use my Test plan(s)</Button>
            </div>}
          </div>
        )}
        {location.pathname === "/testcases" && (
          <>
          {IsSelectAll ? 
            <div className="subheader-button-container">
              {userType === "TESTER" && 
              <>
                {testCaseSUT === SystemUnderTest.Issuer && 
                  <Button
                    onClick={() => OnClickEvent('BulkExecute')}
                    type="primary"
                    className="subheader-btn-2"
                    disabled={selectedTestCases?.some((testCase:{isExecutable:boolean}) => !testCase.isExecutable)}
                  >
                    Execute
                  </Button>
                  }
              </>}
            </div>
            : 
            <div className="subheader-button-container">
              {(hasCustomTestplan || hasCertificationTestplan) && userType === "TESTER" && <div className="subheader-btn-2">
                <Button onClick={()=> OnClickEvent('Use-my-test-case')} className="subheader-btn">Use my Test case(s)</Button>
              </div>}
            </div>}
          </>
        )}
        {location.pathname === "/testplans" && (
          <>
          {hasCustomTestplan && userType === "TESTER" && <div className="subheader-button-container">
            <Button
                type="primary"
                onClick={() => OnClickEvent('New')}
                className="subheader-btn-2"
                disabled={disabled}
              >
                Create Test plan
            </Button>
          </div>}
          </>
        )}
        {location.pathname === "/testplan/testcases" && (
          <>
          {hasCustomTestplan && !disabled && userType === "TESTER"  && <div className="subheader-button-container">
              <Button
                onClick={OnClickEvent}
                type="primary"
                className="subheader-btn-2"
              >
                Create Test case
              </Button>
            </div>}
          </>
        )}
        {location.pathname === "/organization" && (
          <div className="subheader-button-container">
            <Button
              onClick={OnClickEvent}
              type="primary"
              className="subheader-btn-2"
            >
              Add Organization
            </Button>
          </div>
        )}
        {location.pathname === "/users" && (
          <div className="subheader-button-container">
            <div className="subheader-btn-1">
              <Button
                  type="primary"
                  onClick={OnClickEvent}
                  className="subheader-btn-2"
                >
                Create User
              </Button>
            </div>
          </div>
        )}
        {location.pathname === "/cardprofiles" && (
          <div className="subheader-button-container">
            {userType === "TESTER" && <Button
              onClick={OnClickEvent}
              type="primary"
              className="subheader-btn-2"
            >
              Create Card profile
            </Button>}
          </div>
        )}
        {location.pathname === "/merchantprofiles" && (
          <div className="subheader-button-container">
            {userType === "TESTER" && <Button
              onClick={OnClickEvent}
              type="primary"
              className="subheader-btn-2"
            >
              Create Merchant profile
            </Button>}
          </div>
        )}
        {location.pathname === "/chipkeys" && (
          <div className="subheader-button-container">
            {userType === "TESTER" && <Button
              onClick={OnClickEvent}
              type="primary"
              className="subheader-btn-2"
            >
              Create Chip key
            </Button>}
          </div>
        )}
        {location.pathname === "/systemlogs" && (
          <div className="subheader-button-container">
            <Row className="switch-outline" gutter={36}>
              <Col
                className={`${highlightedButton === "btnNetworkLogs" ? "log-btn-active" : ""}`}
                onClick={() => handleButtonClick('btnNetworkLogs')}
              >
                <span className="log-btn-text">Network logs</span>
              </Col>
              <Col
                className={`${highlightedButton === "btnFinancialLogs" ? "log-btn-active" : ""}`}
                onClick={() => handleButtonClick('btnFinancialLogs')}
              >
                <span className="log-btn-text">Financial logs</span>
              </Col>
            </Row>
          </div>
        )}
        {location.pathname === '/testcases/analyzelogs' && (
          <div className="subheader-button-container">
            <Row className="switch-outline" gutter={36}>
              <Col
                className={`${highlightedLog === "passCriteriaLog" ? "log-btn-active" : ""}`}
                onClick={() => handleLogButtonClick('passCriteriaLog')}
              >
                <span className="log-btn-text">Passcriteria</span>
              </Col>
              <Col
                className={`${highlightedLog === "hostLog" ? "log-btn-active" : ""}`}
                onClick={() => handleLogButtonClick('hostLog')}
              >
                <span className="log-btn-text" style={{marginLeft: '10px', marginRight: '10px'}}>Host log</span>
              </Col>
            </Row>
          </div>
        )}
      </div>
  );
};

export default SubHeader;
