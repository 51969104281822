import { Card, Modal } from "antd";
import { IModal } from "../../Interface/Interfaces";
import { useSelector } from "react-redux";
import { SchemeTag } from "../../Constants/Constants";

const TestCaseInfoModal: React.FC<IModal> = ({isOpen, onClose, onFinish, value}) => {
    const testCase = value;
    
    const cardProfileList = useSelector(
        (state: any) => state.lithosApiReducer.cardProfileList
    )
    const merchantProfileList = useSelector(
        (state: any) => state.lithosApiReducer.merchantProfileList
    )

    return(
        <Modal
            className="testcase-info-modal"
            title="Test case info"
            centered
            open={isOpen}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            onCancel={() => {
                onClose();
            }}
            >
            <Card className="custom-card">
                <div className="card-content-container">
                    <div className="card-key-title">Name</div>
                    <div className="card-value">{testCase?.name}</div>
                </div>
                <div className="card-content-container">
                    <div className="card-key-title">Result</div>
                    <div className={`test-case-${testCase?.result} info-result`}>{testCase?.result}</div>
                </div>
                <div className="card-content-container">
                    <div className="card-key-title">Scheme</div>
                    <div className="card-value">{SchemeTag[testCase.scheme] || testCase.scheme}</div>
                </div>
                <div className="card-content-container">
                    <div className="card-key-title">Version</div>
                    <div className="card-value">{testCase?.version}</div>
                </div>
                {testCase?.matchPan &&             
                <div className="card-content-container">
                    <div className="card-key-title">Match PAN</div>
                    <div className="card-value">{testCase?.matchPan}</div>
                </div>}
                {testCase?.matchAmount &&             
                <div className="card-content-container">
                    <div className="card-key-title">Match amount</div>
                    <div className="card-value">{testCase?.matchAmount}</div>
                </div>}
                {cardProfileList?.map((card: any) => {
                if (card?.id === testCase?.cardID) {
                    return (
                    <div className="card-content-container" key={card?.id}>
                        <div className="card-key-title">Card</div>
                        <div className="card-value">{card?.name}</div>
                    </div>
                    );
                }
                    return null; 
                })}
                {merchantProfileList?.map((merchantProfile: any) => {
                if (merchantProfile?.id === testCase?.merchantProfileID) {
                    return (
                    <div className="card-content-container" key={merchantProfile.id}>
                        <div className="card-key-title">Merchant Profile</div>
                        <div className="card-value" key={merchantProfile.name}>{merchantProfile?.name}</div>
                    </div>
                    );
                }
                return null; 
                })}
                <div className="card-content-container">
                    <div className="card-key-title">Description</div>
                    <div className="card-value">{testCase?.description !== null ? testCase?.description : `N/A` }</div>
                </div>
                <div className="card-content-container">
                    <div className="card-key-title">Test information</div>
                    <div className="card-value" dangerouslySetInnerHTML={{__html: testCase?.objective !== null ? testCase?.objective : `N/A`}} />
                </div>
            </Card>
        </Modal>
    )
};

export default TestCaseInfoModal;