import { Button, Col, Collapse, Pagination, PaginationProps, Row, Tooltip  } from "antd";
import SubHeader from "../../Components/Header/SubHeader";
import { createContext, useEffect, useState } from "react";
import { ArrowsAltOutlined, DownOutlined, ReloadOutlined, ShrinkOutlined, UpOutlined } from "@ant-design/icons";
import { BreadCrumbConfig, HeaderTitle } from "../../Redux/Reducers/UtilReducer";
import { useDispatch, useSelector } from "react-redux";
import { getLogs } from "../../Redux/Actions/Actions";
import dayjs from "dayjs";
import LogModal from "../SystemLogs/LogModal";
import { SchemeTag } from "../../Constants/Constants";

export const ProjectStreamLogContext = createContext({});

export default function ProjectLogs() {
    const dispatch = useDispatch();
    const OnClickEvent = (value: any) => {};
    const [isExpanded, setIsExpanded] = useState(true)
    const [isLogModalOpen, setIsLogModalOpen] = useState(false)
    const { Panel } = Collapse
    const [logData, setLogData] = useState<any>()
    const [streamLog, setStreamLog] = useState<any>()
    const [activeKeys, setActiveKeys] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPageNo, setTotalPageNo] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [searchDto, setSearchDto] = useState<any>({
        acquirerId: null,
        terminalId: null,
        page: 1,
        isFinancial: true,
      })
    const [acquirerId, setAcquirerId] = useState('')
    const [terminalId, setTerminalId] = useState('')

    const project = useSelector(
        (state: any) => state.lithosApiReducer.projectDto
    )    

    useEffect(() => {
        dispatch(HeaderTitle("Project logs"));
        dispatch(
          BreadCrumbConfig({
            title: "Project logs",
            href: '/projectlogs',
            preventPush: false,
          } as any)
        );
        setAcquirerId(project?.acquirerID)
        setTerminalId(project?.terminalID)
      }, [])

      useEffect(() => {
        if (acquirerId || terminalId) {
          const search: any = {
            acquirerId: acquirerId,
            terminalId: terminalId,
            page: currentPage,
            isFinancial: true,
          }
          setSearchDto(search)
          fetchLogs(search);
        }
      }, [acquirerId, currentPage])

      const fetchLogs = (values: any) => {
        dispatch(getLogs(values)).then((res: any) => {
          if (res.payload?.searchResult?.financialTransactionsLogs?.length > 0) {
            setLogData(res.payload?.searchResult.financialTransactionsLogs)
            setTotalPageNo(res?.payload?.searchResult.totalPage)
            setActiveKeys(res.payload?.searchResult.financialTransactionsLogs?.map((log) => log?.uniqueId))
            setIsExpanded(true)
            setPageSize(res.payload?.searchResult.financialTransactionsLogs?.length)
          }
          else{
            setLogData({})
            setTotalPageNo(0)
            setActiveKeys([])
            setPageSize(0)
          }
        });
      };
    
    const customExpandIcon = ( isActive: any ) => {
        const iconClassName = isActive ? 'custom-collapse-icon active' : 'custom-collapse-icon';
        return isActive ? <UpOutlined className={iconClassName} /> : <DownOutlined className={iconClassName} />;
      };

    const onChange: PaginationProps['onChange'] = (page) => {
        setCurrentPage(page)
    };
    
    const handlePanelChange = (keys) => {
        setActiveKeys(keys);
    };
    
    const headerExtra = (data :any) =>{
      return (
        <Row gutter={16}>
          <Col span={3} offset={6} style={{marginLeft:'2%'}}>{data.stan}</Col>
          <Col span={4} offset={1}>{dayjs(data.timeStamp).format("MM/DD/YYYY h:mm A")}</Col>
          <Col span={1} offset={1} style={{marginLeft:'5%'}}>{SchemeTag[data.scheme] || data.scheme}</Col>
      </Row>
      )
  }
    
    const handleToggleAll = () => {
    if (isExpanded) {
        setActiveKeys([]);
        setIsExpanded(false);
    } else {
        const allPanelKeys = logData.map(item => item.uniqueId);
        setActiveKeys(allPanelKeys);
        setIsExpanded(true);
    }
    };

    const handleCardClick = (log: any) => {
        setIsLogModalOpen(true)
        setStreamLog(log)
    }

    const oncloseModal = () => {
        setIsLogModalOpen(false)
        setStreamLog({})
    };

    const onFinish = (values: any) => {
        if(isDropdownOpen === true){
          values.isFinancial = true;
          values.page = currentPage;
          values.acquirerId = searchDto.acquirerId;
          values.terminalId = searchDto.terminalId;
          fetchLogs(values)
          setIsDropdownOpen(false)
        }
    };

    const expandIcon = isExpanded ? <Tooltip title='Collapse all'><ShrinkOutlined style={{ fontSize: '18px' }} /></Tooltip> 
      : <Tooltip title='Expand all'><ArrowsAltOutlined style={{ fontSize: '18px' }} /></Tooltip>;

    return(
        <>
        <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
        <div className='systemlogs-border'>
        <div className="log-header">
          <div className="sticky-header">
            <Row className='header-content'>
              <Col span={1} offset={1}>Stan</Col>
              <Col span={3} offset={3}>Time stamp</Col>
              <Col span={3} offset={2}>Scheme</Col>
              <Col span={1} offset={10}><Button onClick={handleToggleAll} icon={expandIcon} style={{color: '#838383'}}></Button></Col>
            </Row>
          </div>
          <div className="collapse-panels">
              {logData && logData?.length > 0 ? (
                  <Collapse
                    activeKey={activeKeys}
                    onChange={handlePanelChange}
                    expandIcon={({ isActive }) => customExpandIcon(isActive)}
                    >
                    {logData?.map((log: any) => (
                        <Panel key={log.uniqueId} header={headerExtra(log)}>
                            {log.transactions?.map((streamLogs: any) => (
                                <div className={`log-stream-header ${streamLogs?.error?.length > 0 ? 'stream-log-error-header' : 'stream-log-header'}`} key={streamLogs.stream}
                                    onClick={() => handleCardClick(streamLogs)}
                                >
                                  <p className={`stream ${streamLogs?.error?.length > 0 ? 'error-stream-name' : 'stream-name'}`}>{streamLogs.stream}
                                  <div className='error-count'>{streamLogs?.error?.length > 0 && streamLogs?.error?.length}</div></p>
                                </div>
                            ))}
                        </Panel>
                    ))}
                  </Collapse>
              ) : (
                  <p className='para-text'>No logs found!</p>
              )}
          </div>
        </div>
        <div className="pagination">
          <Col className="pagination-reload" flex="auto">
              <Row align="middle" gutter={[8, 0]} >
              <div onClick={() => fetchLogs(searchDto)} style={{display: "flex"}}>
                  <ReloadOutlined />
                  <p style={{ marginLeft: '6px'}}>Refresh</p>
              </div>
              </Row>
          </Col>
          <Col className="pagination-pagination">
              <Pagination current={currentPage} onChange={onChange}  total={totalPageNo * pageSize} pageSize={pageSize} showSizeChanger={false}/>
          </Col>
        </div>
      </div>
        {isLogModalOpen && <ProjectStreamLogContext.Provider value={streamLog}>
            <LogModal
                isOpen={isLogModalOpen}
                onClose={oncloseModal}
                onFinish={onFinish}
            />
        </ProjectStreamLogContext.Provider> }
        </>
    )
    }