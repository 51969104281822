import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BreadCrumbConfig,
  HeaderTitle,
} from "../../Redux/Reducers/UtilReducer";
import SubHeader from "../../Components/Header/SubHeader";
import TableContainer from "../../Components/Table/TableContainer";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { Select, notification } from "antd";
import { CopyProject, GetAllUser, getProjectsByUserId } from "../../Redux/Actions/Actions";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import Copy from "../../Assets/Copy.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import { ShowToaster } from "../../Components/Notifications/Notifications";

export default function CopyFromOther() {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState<any>();
  const [userId, setUserId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectData, setProjectData] = useState<any>();
  const [api, contextHolder] = notification.useNotification();
  const [projectTableData, setProjectTableData] = useState<any>();
  const [projectNames, setProjectNames] = useState([''])

  useEffect(() => {
    setLoading(true);
    dispatch(HeaderTitle("Copy From Other Users"));
    dispatch(
      BreadCrumbConfig({
        title: "Copy From Other Users",
        href: "/copyFromOther",
        preventPush: true,
      } as any)
    );
    dispatch(GetAllUser()).then((D: any) => {
      setUsersList(D.payload?.userList);
      if (userId === undefined) {
        setUserName(D?.payload?.userList[0]?.id);
        dispatch(getProjectsByUserId(D?.payload?.userList[0]?.id)).then((P: any) => {
          setProjectTableData(P?.payload?.projectList);
          P?.payload?.projectList.map((project: any) => (
            project?.name !== "" && !projectNames.includes(project.name) && setProjectNames((projectNames) => [...projectNames, project.name.toLowerCase()])
          ))
          setLoading(false);
        });
      }
    });
  }, [userId]);

  const OnClickEvent = () => {
    setIsModalOpen(true);
  };

  const oncloseModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    setLoading(true)
    const projectId = projectData.id
    dispatch(CopyProject({values, projectId})).then((res)=>{
      ShowToaster(res.payload, api)
      setLoading(false)
      setIsModalOpen(false)
    });
  }

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer" }}>
          {text}
        </div>
      ),
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {},
        };
      },
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name); 
      }
    },
    {
      title: "Last Action At (CST)",
      dataIndex: "lastAccessedAt",
      key: "lastAccessedAt",
      render: (text) => <div className="project-content">{dayjs(text).format('MM-DD-YYYY h:mm:ss A')}</div>,
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: "Scheme",
      dataIndex: "projectScheme",
      key: "projectScheme",
      render: (text) => <div className="project-content">{text}</div>,
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: "Scheme Specification",
      dataIndex: "specificationVersion",
      key: "specificationVersion",
      render: (text) => <div className="project-content">{text}</div>,
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text: any, record: any) => (
        <div className="combined-icon">
          <button onClick={()=> {setProjectData(record);setIsModalOpen(true)}} className="project-content no-border-bg-transparent">
            <img className="icons" src={Copy} alt="copy icon"/>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
    {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Select
        placeholder="Select User"
        className="modal-input copy-fro-other-user-input"
        onChange={(e) => {
          setUserId(e);
          dispatch(getProjectsByUserId(e)).then((P: any) => {
            setProjectTableData(P?.payload?.projectList);
            setLoading(false);
          });
        }}
      >
        {usersList?.map((U: any) => {
          return (
            <Select.Option key={U?.id} value={U?.id}>{U?.name}</Select.Option>
          );
        })}
      </Select>
      <SearchBar className={"copy-from-other"} />
      <TableContainer
        tableData={projectTableData}
        loading={loading}
        column={columns}
        scrollY='65vh'
      />
      <CopyModal isOpen={isModalOpen} onClose={oncloseModal} onFinish={onFinish} title='Project'
      values={projectNames}/>
    </>
  );
}
