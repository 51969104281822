import { useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

const EditableFormItem = ({ fieldKey, rules, value, onUpdate, form, isEditable }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [displayEditIcon, setDisplayEditIcon] = useState(false)
    const inputRef = useRef<any>(null);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isEditing]);

    const handleEdit = () => {
        !isEditable && setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setDisplayEditIcon(false)
        setIsEditing(false);
    };

    const handleSave = () => {
        form.validateFields().then(() => {
            onUpdate({[fieldKey]: form.getFieldValue(fieldKey)});
        });
        setIsEditing(false);
        setDisplayEditIcon(false)
    };

return (
    <>
    {isEditing ? (
        <Form.Item
            name={fieldKey}
            key={value}
            initialValue={value}
            rules={rules}
            className='inline-edit'
        >
            <Input ref={inputRef} className='inline-edit-input' onPressEnter={handleSave}/>
        </Form.Item>
        ) : (
        <div onClick={handleEdit} onMouseEnter={() => setDisplayEditIcon(true)} onMouseLeave={() => setDisplayEditIcon(false)}>
            {value}{' '}
            {(!isEditable && displayEditIcon) && <EditOutlined
            style={{ color: '#8E8E8E', marginLeft: '5px' }}
            onClick={handleEdit}
            />}
        </div>
        )}
    {isEditing && (
    <div className='inline-edit-btn'>
        <Button onClick={handleCancelEdit} style={{marginRight:'6px'}}><CloseOutlined /></Button>
        <Button onClick={handleSave}><CheckOutlined /></Button>
    </div>
    )}
    </>
);
};

export default EditableFormItem;