import { Form, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { IModal } from "../../Interface/Interfaces";
import { useSelector } from "react-redux";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import { capitalizeFirstLetter, generateSimulationName } from "../../Utils/Utils";

const CreateTestPlanModal: React.FC<IModal> = ({isOpen, onClose, onFinish }) => {
  const [form] = Form.useForm();
  const [scheme, setScheme] = useState<any>({})
  const [systemUnderTest, setSystemUnderTest] = useState<any>({})
  const [systemUnderTestOptions, setSystemUnderTestOptions] = useState<any>([]);
  const [testModeOptions, setTestModeOptions] = useState<any>([])
  const [versionOptions, setVersionOptions] = useState<any>({});
  const [schemeOptions, setSchemeOptions] = useState<any>([]);
  const [, contextHolder] = notification.useNotification();
  const [testPlanNames, setTestPlanNames] = useState<any>([""]);

  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  );

  const testPlanDtoList = useSelector(
    (state: any) => state.lithosApiReducer.testPlanDtoList
  );

  useEffect(() => {
    const schemes = masterData
      ?.filter((S: any) => S.properties?.CustomTestplan === true)
      .map((S: any) => ({
        value: S.schemeName,
        label: S.schemeName.toUpperCase(),
      }));
    setSchemeOptions(schemes);
    if (schemes.length === 1) {
      setScheme(schemes[0]?.value);
      form.setFieldsValue({
        scheme: schemes[0]?.value
      });
    }
    testPlanDtoList?.map(
      (testPlan: any) =>
        testPlan?.name !== "" &&
        !testPlanNames.includes(testPlan.name) &&
        setTestPlanNames((testPlanNames: any) => [
          ...testPlanNames,
          testPlan.name.toLowerCase(),
        ])
    );
  }, []);

  useEffect(() => {
    const selectedSchemeData = masterData?.find((data) => data.schemeName === scheme);
    if (selectedSchemeData) {
      const systemUnderTestList = selectedSchemeData?.systemUnderTestList?.map((system) => ({
        value: system.testEntity,
        label: capitalizeFirstLetter(system.testEntity),
      }));
      const versionList = selectedSchemeData?.libraries?.map((system) => ({
        value: system.version,
        label: system.version,
      }));
      setVersionOptions(versionList);
      setSystemUnderTestOptions(systemUnderTestList);
      if (systemUnderTestList.length === 1) {
        form.setFieldsValue({
          systemUnderTest: systemUnderTestList[0]?.value
        });
        setSystemUnderTest(systemUnderTestList[0]?.value)
      }
      if (versionList.length === 1) {
        form.setFieldsValue({
          version: versionList[0]?.value,
        });
      }
    }
  }, [scheme]);

  useEffect(() => {
    const selectedSchemeData = masterData?.find((data) => data.schemeName === scheme);
    if (selectedSchemeData) {
      const testModeData = selectedSchemeData?.systemUnderTestList?.find((data) => data.testEntity.toLowerCase() === systemUnderTest.toLowerCase())
      const testModeList = testModeData?.testMode?.map((system) => ({
        value: system,
        label: generateSimulationName(system, systemUnderTest),
      }));
      setTestModeOptions(testModeList);
      if(testModeList?.length === 1){
        form.setFieldsValue({
          TestMode: testModeList[0]?.value
        });
      }
    }
  }, [systemUnderTest])

  return (
    <>
      {contextHolder}
      <Modal
        className="create-test-plan-modal"
        title="Create Test plan"
        centered
        open={isOpen}
        onOk={() => {form.submit();}}
        okText="Create"
        cancelText="Discard"
        onCancel={()=>{onClose();form.resetFields()}}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="modal-form"
            name="name"
            rules={[
              { required: true, message: "Please enter the Testplan name!" },
              {
                validator: (_, value) => {
                  if (
                    value !== "" &&
                    testPlanNames.includes(value.toLowerCase().trim())
                  ) {
                    return Promise.reject(new Error("Testplan name already exists!"));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <FloatingLabelInput label="Name" placeholder="Name" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="scheme"
            rules={[{ required: true, message: "Please select the scheme!" }]}
          >
            <FloatingLabelSelect
              label="Scheme"
              showSearch
              onChange={(e: any) => {          
                setScheme(e.toLowerCase());
              }}
              className="modal-input"
              optionFilterProp="children"
              options={schemeOptions}
            />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="systemUnderTest"
            rules={[{ required: true, message: "Please select the test!" }]}
          >
            <FloatingLabelSelect
              label="System under test"
              onChange={(e) => {
                setSystemUnderTest(e.toLowerCase())
              }} 
              showSearch
              className="modal-input"
              optionFilterProp="children"
              options={systemUnderTestOptions}
            />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="version"
            rules={[{ required: true, message: "Please select the version!" }]}
          >
            <FloatingLabelSelect
              label="Library version"
              showSearch
              className="modal-input"
              optionFilterProp="children"
              options={versionOptions.length > 0 ? versionOptions : []}
            />
          </Form.Item>
          {testModeOptions?.length === 1 ? (
          <Form.Item
            className="modal-form"
            name="TestMode"
            hidden
          />
          ) : ( 
          <Form.Item
            className="modal-form"
            name="TestMode"
            rules={[{ required: true, message: "Please select a test mode!" }]}
          >
            <FloatingLabelSelect
              label="Simulation"
              className="modal-input"
              optionFilterProp="children"
              options={testModeOptions} 
            />
          </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateTestPlanModal;
