import { Button, Form, Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { IModal } from "../../Interface/Interfaces";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { useSelector } from "react-redux";

const ChangePasswordModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm()
  const [currentView, setCurrentView] = useState("name-email");
  const [isCriteriaError, setIsCriteriaError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordValidation, setPasswordValidation] = useState([
    { criterion: "At least 8 characters", isValid: false },
    { criterion: "At least 1 number", isValid: false },
    { criterion: "At least 1 upper case letter", isValid: false },
    { criterion: "At least 1 lower case letter", isValid: false },
    { criterion: "At least 1 special character", isValid: false },
  ]);
  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  const initialValues = {
    Name: value?.Name,
    EmailId: value?.Email,
    UserType: userType,
  };

  const handlePasswordChange = (value: string) => {
    setErrorMessage('')
    const newValidationResults = passwordValidation.map(validation => ({
      ...validation,
      isValid: validation.criterion === "At least 8 characters"
        ? value.length >= 8
        : validation.criterion === "At least 1 number"
        ? /\d/.test(value)
        : validation.criterion === "At least 1 upper case letter"
        ? /[A-Z]/.test(value)
        : validation.criterion === "At least 1 lower case letter"
        ? /[a-z]/.test(value)
        : validation.criterion === "At least 1 special character"
        ? /[!@#$%^&*(),.?":{}|<>]/.test(value)
        : false,
    }));
    
    setPasswordValidation(newValidationResults);

    const allCriteriaMet = newValidationResults.every(validation => validation.isValid);
    setIsCriteriaError(!allCriteriaMet);
  };

  const handleEditPasswordClick = () => {
    setCurrentView("password"); 
  };

  return (
    <>
      {contextHolder}
      <Modal
        className="create-project-modal"
        centered
        title={currentView === "name-email" ? "My Account" : "Change Password"} 
        okText={currentView === "password" && "Change"}
        cancelText={currentView === "password" && "Discard"}
        open={isOpen}
        onOk={() => {
          if (currentView === "password") {
            if(isCriteriaError){
              form.validateFields()
              setErrorMessage('Please resolve all the criteria errors!')
            }
            else{
              form.submit();
            }
          } else {
            onClose();
            form.resetFields();
            setCurrentView("name-email"); 
          }
        }}
        onCancel={() => {
          onClose();
          form.resetFields();
          setCurrentView("name-email"); 
        }}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
          style={{ border: "1px solid #d9d9d9", borderRadius: "6px", padding: "20px 20px 0px 20px" }}
        >
          {currentView === "name-email" && (
            <>
              <Form.Item
            className="modal-form"
            name="Name"
          >
          <FloatingLabelInput label='Name' placeholder="Name" readonly/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="EmailId"
          >
          <FloatingLabelInput label='Email' placeholder="Email" readonly/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="UserType"
          >
            <FloatingLabelInput label='User type' placeholder="User type" readonly/>
          </Form.Item>
          <Button onClick={handleEditPasswordClick} style={{width: "100%", marginBottom: '20px'}} size="large" className="para-text">Change Password</Button>
            </>
          )}
          {currentView === "password" && (
            <>
            {/* Old password */}
            <Form.Item
              name="OldPassword"
              rules={[{ required: true, message: "Please enter your old password" }]}
            >
              <FloatingLabelInput label='Old Password' placeholder='Old Password' type='password'  pattern="^[^\s]+$" />
            </Form.Item>
            {/* New password */}
            <Form.Item
              name="NewPassword"
              rules={[
                { required: true, message: "Please enter a new password" },
              ]}
            >
              <FloatingLabelInput label='New Password' placeholder='New Password' type='password'  pattern="^[^\s]+$" onChange={(e) => handlePasswordChange(e.target.value)}  />
            </Form.Item>
            {/* Confirm password */}
            <Form.Item
              name="Re_EnteredPassword"
              dependencies={["NewPassword"]}
              rules={[
                { required: true, message: "Please confirm your new password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("NewPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The passwords do not match"));
                  },
                }),
              ]}
            >
              <FloatingLabelInput label='Confirm Password' placeholder='Confirm Password' type='password'  pattern="^[^\s]+$" />
            </Form.Item>
            {/* Password validation criteria */}
            <div className="password-validation">
                {errorMessage && (
                    <p className="invalid-credentials">{errorMessage}</p>
                  )}
                <p className="para-info">Password must contain :</p>
                {passwordValidation.map(validation => (
                  <p key={validation.criterion} className={validation.isValid ? 'pwd-valid' : 'pwd-invalid'}>
                    {validation.criterion}
                  </p>
                ))}
              </div>
              <Form.Item hidden name="EmailId" >
                <Input />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
