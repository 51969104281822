import { useState } from 'react';
import { Form, DatePicker } from 'antd';

const FloatingLabelDatePicker = (props) => {
  const [focus, setFocus] = useState(false);

  const { label, initialValue, ...otherProps } = props;

  const isOccupied = focus || (initialValue && initialValue.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Form.Item name={props.name} initialValue={initialValue}>
        <DatePicker
          className='datepicker-input'
          placeholder='Select month and year'
          {...otherProps} 
        />
      </Form.Item>
      <label className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelDatePicker;
