import React from 'react'
import { IModal } from '../../Interface/Interfaces';
import { Form, Modal } from 'antd';
import FloatingLabelInput from '../../Components/FloatingLabel/Input/FloatingLabelInput';

const SearchLogsModal : React.FC<IModal> = ({isOpen, onClose, onFinish}) => {
const [form] = Form.useForm()

return (
    <Modal
        className="create-project-modal"
        title="Search logs"
        centered
        okText="Search"
        cancelText="Discard"
        open={isOpen}
        onOk={()=>form.submit()}
        onCancel={()=>{onClose();form.resetFields()}}
    >
        <Form
        form={form}
        name="basic"
        className="modal-form-container"
        onFinish={onFinish}
        autoComplete="off"
        >
        <Form.Item
            className="modal-form"
            name="Field7"
            rules={[
                { pattern: /^\d{10}$/, message: "Field 7 should be 10-digit in numeric format" }
            ]}
        >
        <FloatingLabelInput label='Field 7' placeholder="Field 7" />
        </Form.Item>          
        <Form.Item
            className="modal-form"
            name="Field11"
            rules={[
                { required: true, message: "Field 11 cannot be empty" },
                { pattern: /^\d{6}$/, message: "Field 11 should be 6-digit in numeric format" }
            ]}
        >
        <FloatingLabelInput label='Field 11 *' placeholder="Field 11 *" />
        </Form.Item>
        <Form.Item
            className="modal-form"
            name="Field12"
            rules={[
                { pattern: /^\d{12}$/, message: "Field 12 should be 12-digit in numeric format" }
            ]}
        >
        <FloatingLabelInput label='Field 12 *' placeholder="Field 12 *" />
        </Form.Item>
        </Form>
    </Modal>
)
}

export default SearchLogsModal;