import { Card, Col, Modal, Row } from "antd";
import { IModal } from "../../Interface/Interfaces";
import { useContext } from "react";
import { RawLogContext } from "./LogModal";

const RawLogModal: React.FC<IModal> = ({isOpen,onClose}) => {
    let rawLogData = useContext<any>(RawLogContext) 

    let cardStyle = {
        borderRadius: 0
    }

    return(
        <Modal
            className="raw-log-modal"
            title="Raw log"
            centered
            open={isOpen}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            onCancel={() => {
                onClose();
            }}
            >
        <Row className="raw-log-border">
            <Col span={12}>
                <Card title='Request:' bordered={false} headStyle={cardStyle}>
                    {rawLogData.sentValue}
                </Card>
            </Col>
            <Col span={12}>
                <Card title='Response:' bordered={false} headStyle={cardStyle}>
                    {rawLogData.receivedValue}
                </Card>
            </Col>
        </Row>
        </Modal>
    )
};

export default RawLogModal;