import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumbConfig, HeaderTitle } from '../../Redux/Reducers/UtilReducer';
import { Button, Col, Collapse, Row, Tooltip, notification } from 'antd';
import SubHeader from '../../Components/Header/SubHeader';
import dayjs from 'dayjs';
import {
  CheckCircleTwoTone,
  DownOutlined,
  UpOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import LogModal from '../SystemLogs/LogModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import utcPlugin from 'dayjs/plugin/utc';
import { acceptHostLogErrors, downloadLogs, getHostLog, getPassCriteriaLog, rejectHostLogErrors } from '../../Redux/Actions/Actions';
import { ColumnsType } from 'antd/es/table';
import TableContainer from '../../Components/Table/TableContainer';
import GreenStatus from "../../Assets/GreenStatus.svg";
import RedStatus from "../../Assets/RedStatus.svg";
import UnknownStatus from "../../Assets/UnknownStatus.svg"
import NotApplicable from "../../Assets/NotApplicable.svg"
import PendingStatus from "../../Assets/PendingStatus.svg"

dayjs.extend(utcPlugin);

export const HostLogLogStreamLogContext = createContext({});

export default function AnalyzeLogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const [api, contextHolder] = notification.useNotification();
  const [activeKeys, setActiveKeys] = useState<any>([]);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [streamLog, setStreamLog] = useState<any>();
  const [searchParams] = useSearchParams();
  const testCaseName = searchParams.get('testcaseName');
  const [testCaseResult, setTestCaseResult] = useState(searchParams.get('testCaseResult'));
  const testCaseId = searchParams.get('testCaseId');
  const [logData, setLogData] = useState<any>({});
  const [isPassCriteria, setIsPassCriteria] = useState(false)
  const [passCriteriaData, setPassCriteriaData] = useState()
  const [logKey, setLogKey] = useState('')

  useEffect(() =>{
    dispatch(HeaderTitle("Analyze logs"));
    dispatch(
        BreadCrumbConfig({
          title: "Analyze logs",
          href: "/testcases/analyzelogs",
          preventPush: false,
        } as any)
      );
    RenderHostLog()
  }, [testCaseId]);

  const testCase = useSelector(
    (state: any) => state.lithosApiReducer.testCaseDto
  );

  const RenderHostLog = () => {
    dispatch(getHostLog(testCaseId)).then((data: any) => {
      const financialTransactionsLogs = data.payload;
      const keys = Object.keys(financialTransactionsLogs);
      setLogData(financialTransactionsLogs);
      setActiveKeys(keys);
    });
  };

  const RenderPassCriteria = () => {
    dispatch(getPassCriteriaLog(testCaseId)).then((data: any) => {
      setPassCriteriaData(data.payload)
      const keys = Object.keys(data.payload);
      if (keys.length > 0) {
        setActiveKeys([keys[0]]); 
      }
    })
  }

  const OnClickEvent = (value: any) => {
    if(value === 'passCriteriaLog' && !isPassCriteria){
      setIsPassCriteria(!isPassCriteria)
      RenderPassCriteria()
    }  
    if(value === 'hostLog' && isPassCriteria){
      setIsPassCriteria(!isPassCriteria)
      RenderHostLog()
    }
  }

  const DownloadLogs = async () => {
    const responseArrayBuffer = await dispatch(downloadLogs([testCaseId]));
    if(responseArrayBuffer?.payload === undefined || responseArrayBuffer?.payload?.error){
      api.error({
        message: `Something went wrong, please try again later`,
        placement: 'top',
        style: {
          width: 500,
        },
        icon: <WarningTwoTone twoToneColor={'#D13F3F'}/>,
      })
    } else {
      const blob = new Blob([responseArrayBuffer?.payload], { type: 'application/x-zip-compressed' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'TransactionLogs.zip';
      a.click();
      window.URL.revokeObjectURL(url);
      api.success({
        message: `Logs downloaded successfully`,
        placement: "top",
        style: {
          width: 500,
        },
        icon: <CheckCircleTwoTone twoToneColor={'#8BAB58'}/>
      })
    }
  }

  const handlePanelChange = (keys) => {
    setActiveKeys(keys);
  };

  const oncloseModal = () => {
    setIsLogModalOpen(false)
    setStreamLog({})
  };

  const onFinish = (values: any) => {
    if(values.action === 'Accept'){
      dispatch(acceptHostLogErrors({errors: values.errors, testCaseId, stream: values.stream, key: logKey})).then((res) => {
        logData[logKey] = res.payload?.transactions;
        setLogData({ ...logData }); 
        searchParams.set('testCaseResult', res.payload?.testResult);
        setTestCaseResult(res.payload?.testResult);
        oncloseModal();
      });
      
    }
    else{
      dispatch(rejectHostLogErrors({errors: values.errors, testCaseId, stream: values.stream, key: logKey})).then((res) => {
        logData[logKey] = res.payload?.transactions;
        setLogData({ ...logData }); 
        searchParams.set('testCaseResult', res.payload?.testResult);
        setTestCaseResult(res.payload?.testResult)
        oncloseModal()
      })
    }
  }

  const hostLogHeaderExtra = (mti: string, data: any) => {
    return (
      <Row gutter={16}>
        <Col span={2} style={{ marginLeft: '3%' }}>
          {data.stan}
        </Col>
        <Col span={4} offset={1}>
          {data.timeStamp && dayjs.utc(data.timeStamp).format('MMM/DD/YYYY h:mm A')}
        </Col>
        <Col span={2} style={{ marginLeft: '3%' }}>
          {mti.length === 4 ? mti : null} 
        </Col>
      </Row>
    );
  };

  const passCriteriaHeaderExtra = (key: string) => {
    return (
      <Row gutter={16}>
        <Col span={2} style={{ marginLeft: '2%' }} className='card-key-title'>
          {`Case ${key}`} 
        </Col>
      </Row>
    );
  };

  const customExpandIcon = (isActive: any) => {
    const iconClassName = isActive ? 'custom-collapse-icon active' : 'custom-collapse-icon';
    return isActive ? <DownOutlined className={iconClassName} /> : <UpOutlined className={iconClassName} />;
  };

  const handleCardClick = (log: any, key: string) => {
    setIsLogModalOpen(true);
    setStreamLog(log);
    setLogKey(key)
  };

  const generateTooltipTitle = (id: string) => {
    if (testCase?.passCriteria) {
      const passCriteriaArrays = Object.values(testCase.passCriteria);
      const matchingCriteria: any = passCriteriaArrays
        .flatMap(passCriteriaArray => passCriteriaArray)
        .find((criteria: any) => criteria.id === id);
        
      return matchingCriteria ? matchingCriteria.actualValue : '';
    }
    return '';
  };
  
  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 350,
      render: (text) => <div className="project-content">{text}</div>,
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name); 
      }
    },
    {
      title: () => "Expected value",
      dataIndex: "expectedValue",
      key: "expectedValue",
      render: (text) => <div className="project-content">{text !== null ? text : "--"}</div>,
    },
    {
      title: () => "Actual value",
      dataIndex: "actualValue",
      key: "actualValue",
      render: (text, record: any) =>    <div className="project-content">
        <Tooltip title={generateTooltipTitle(record.id)}>
          {text !== null ? text : "--"}
        </Tooltip>
      </div>,
    },
    {
      title: () => "Result",
      dataIndex: "result",
      key: "result",
      render: (text) => {
        const statusMap = {
          PASS: { image: GreenStatus, tooltip: "Pass" },
          FAIL: { image: RedStatus, tooltip: "Fail" },
          UNKNOWN: { image: UnknownStatus, tooltip: "Unknown" },
          PENDING: { image: PendingStatus, tooltip: "Pending" },
          NOTAPPLICABLE: { image: NotApplicable, tooltip: "Not applicable" },
          default: { image: PendingStatus, tooltip: "Pending" },
        };
        const { image, tooltip } = statusMap[text] || statusMap.default;
        return (
          <div className="project-content">
            <Tooltip title={tooltip}>
              <img className="test-case-status" src={image} alt={text} />
            </Tooltip>
          </div>
        );
      },
    }    
  ];

  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Row>
        <div className="test-case-name">{testCaseName}</div>
        <div className={`test-case-${testCaseResult}`}>{testCaseResult}</div>
      </Row>
      {isPassCriteria ? (
        <div className="collapse-panels">
              {passCriteriaData &&
              Object.entries(passCriteriaData).map(([key, log]: [string, any]) => (
                <Collapse
                  accordion 
                  key={key}
                  activeKey={activeKeys}
                  onChange={handlePanelChange}
                  expandIcon={({ isActive }) => customExpandIcon(isActive)}
                >
                  <Panel key={key} header={passCriteriaHeaderExtra(key)}>
                  <TableContainer
                            tableData={log}
                            key={key}
                            column={columns}
                            className={"passcriteria-table"}
                            pagination='false'
                            scrollY='40vh'
                          />
                  </Panel>
                </Collapse>
              ))}
          </div>
        ) : (
        <div className="collapse-panels">
          {logData &&
          Object.entries(logData).map(([key, log]: [string, any]) => (
            <Collapse
              key={key}
              activeKey={activeKeys}
              onChange={handlePanelChange}
              expandIcon={({ isActive }) => customExpandIcon(isActive)}
            >
              <Panel key={key} header={hostLogHeaderExtra(key, log)}>
                {log.transactions?.map((streamLogs: any) => (
                  <div className={`log-stream-header ${streamLogs?.error?.length > 0
                      ? 'stream-log-error-header'
                      : 'stream-log-header'}`}
                    key={streamLogs.stream}
                    onClick={() => handleCardClick(streamLogs, key)}
                  >
                    <p className={`stream ${streamLogs?.error?.length > 0 ? 'error-stream-name' : 'stream-name'}`}>
                      {streamLogs.stream}
                      <div className="error-count">
                        {streamLogs?.error?.length > 0 && streamLogs?.error?.length}
                      </div>
                    </p>
                  </div>
                ))}
              </Panel>
            </Collapse>
          ))}
        </div>
      )}
      <div className="hostLog-btn">
        <button
          className="tertiary-btn no-border-bg-transparent"
          onClick={() => navigate('/testcases')}
        >
          Back
        </button>
        <Button className="primary-btn download-log-btn" onClick={() => DownloadLogs()}>
          Download log
        </Button>
      </div>
      {isLogModalOpen && (
        <HostLogLogStreamLogContext.Provider value={streamLog}>
          <LogModal isOpen={isLogModalOpen} onClose={oncloseModal} onFinish={onFinish} />
        </HostLogLogStreamLogContext.Provider>
      )}
    </>
    )
}
