import { Form, Modal, Select } from "antd";
import { IModal } from "../../Interface/Interfaces";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

const ImportExcel: React.FC<IModal> = ({ isOpen,  onClose, onFinish,  }) => {
    const [form] = Form.useForm();
    const handleFileUpload = (info) => {};
    return(
        <Modal
            className="create-chip-key-modal"
            title="Import Test case(s)"
            centered
            open={isOpen}
            onOk={()=>form.submit()}
            okText="Create"
            cancelText="Discard"
            onCancel={()=>{onClose();form.resetFields()}}
            >
            <div style={{border:"1px solid #d9d9d9", borderRadius: "6px", padding: "20px 20px 20px 20px"}}>
                <Select
                    placeholder="Chip key"
                    className="excel-input"
                >
                    <Select.Option value={'Copy'} key={'Copy'}>Copy</Select.Option>
                    <Select.Option value={'Override'} key={'Override'}>Override</Select.Option>
                    <Select.Option value={'Ignore'} key={'Ignore'}>Ignore</Select.Option>
                </Select>
                <Dragger onChange={handleFileUpload}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Supports only for single upload. Strictly prohibited from uploading company data or other
                    banned files.
                    </p>
                </Dragger>
            </div>
        </Modal>
    )
};

export default ImportExcel;
