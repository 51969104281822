import React, { useEffect, useState } from "react";
import { IModal } from "../../Interface/Interfaces";
import { Card, Input, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import MultiSelectionTableContainer from "../../Components/Table/MultiSelectionTableContainer";
import OverwriteWarningModal from "./OverwriteWarningModal";
import { SearchOutlined } from "@ant-design/icons";

const DuplicateTestCaseWarningModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value, isUpdate  }) => {
    const [testCaseNameAndId, setTestCaseNameAndId] = useState<any>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isConfirmWarning, setIsConfirmWarning] = useState(false)
    const [searchedText, setSearchedText] = useState('')

    const handleSelectedRows = (rowKeys: React.Key[]) => {
        setSelectedRowKeys(rowKeys);
    };

    useEffect(() => {
        const nameIdArray = value?.split(';')
            .map((val: any) => {
                const [id, name] = val?.split(':');
                return { id, name };
            })
            .filter((item: any) => item.id && item.name) || [];
        setTestCaseNameAndId(nameIdArray);
    }, []);

    const handleSubmit = () => {
        if(selectedRowKeys && selectedRowKeys?.length > 0){
            setIsConfirmWarning(true)
        }
    };

    const handleConfirm = (value: boolean) => {
        if(value){
            onFinish(selectedRowKeys);
            onClose()
        }
        else{
            onClose()
        }
    }
    
const columns: ColumnsType = [
{
    title: "Name",
    dataIndex: "name",
    key: "name",
    filteredValue: [searchedText],
    onFilter: (value: any, record: any) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase())
    },
    render: (text, record : any) => <div className="project-content" key={record.id + record.version}>{text}</div>,
}
];

const onCloseModal = () => {
    setIsConfirmWarning(false)
};

return (
    <> 
        <Modal
            className="import-warning-modal"
            centered
            title={`Action required for ${testCaseNameAndId?.length} duplicate Test case(s)`}
            open={isOpen}
            onOk={handleSubmit}
            okText={`Overwrite selected (${selectedRowKeys?.length})`}
            cancelText="Skip all"
            onCancel={() => {onClose()}}
        >
            <Card>
                <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {setSearchedText(e.target.value)}}/>
                <MultiSelectionTableContainer
                        tableData={testCaseNameAndId}
                        column={columns}
                        className={"log-data-antd-table"}
                        loading={value === undefined}
                        pagination={false}
                        scrollX='0'
                        scrollY='40vh'
                        hideCheckbox={false} 
                        selectedRowKeys={selectedRowKeys}
                        onSelectedRowsChange={handleSelectedRows}   
                        rowClassName={null}
                        onRow={null}
                />
            </Card>
        </Modal>
        {isConfirmWarning && <OverwriteWarningModal
            isOpen={isConfirmWarning}
            onClose={onCloseModal}
            onConfirm={handleConfirm}
        />}
    </>
);
};

export default DuplicateTestCaseWarningModal;
