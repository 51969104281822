import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbConfig, HeaderTitle } from "../../Redux/Reducers/UtilReducer";
import SubHeader from "../../Components/Header/SubHeader";
import TableContainer from "../../Components/Table/TableContainer";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Segmented, Select, Space, Tabs, Tooltip, Typography, notification } from "antd";
import { createProfile, deleteProfile, getDataFields, getTestCaseById, getTestPlanTestCaseById, saveConfiguration } from "../../Redux/Actions/Actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { getParsedDEKey, getPatternByFormat, getSubRowKeys, isStringNullOrEmpty, renameSubElements } from "../../Utils/Utils";
import SaveWithErrorsModal from "./SaveWithErrorsModal";
import { CloseOutlined } from "@ant-design/icons";
import CreateProfileModal from "./CreateProfileModal";
import { ShowToaster } from "../../Components/Notifications/Notifications";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import { ApplicabilityText, SegmentValue, SelectConstants, SystemUnderTest } from "../../Constants/Constants";
import { SegmentedValue } from "antd/es/segmented";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";

export default function TestCaseConfiguration() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [dataFieldList, setDataFieldList] = useState<any>()
  const scheme = useMemo(() => searchParams.get('scheme'), [])
  const testCaseId = searchParams.get('testCaseId')
  const schemeSpecific = useMemo(() => searchParams.get("isSchemeSpecific"), [])
  const [api, contextHolder] = notification.useNotification()
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [disabledElements, setDisabledElements] = useState<any>([])
  const [customValues, setCustomValues] = useState<any[]>([])
  const [collapsedState, setCollapsedState] = useState<Record<string, any>>({})
  const [switchStatus, setSwitchStatus] = useState({})
  const [isSaveWithErrors, setIsSaveWithErrors] = useState(false)
  const [isCreateProfile, setIsCreateProfile] = useState(false)
  const [isDeleteProfile, setIsDeleteProfile] = useState(false)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [showErrors, setShowErrors] = useState(false)
  const [profileIDNo, setProfileIDNo] = useState(1)
  const [profileDataFields, setProfileDataFields] = useState({})
  const [isProfilesLengthChanged, setIsProfilesLengthChanged] = useState(false)
  const [toModifyProfileNo, setToModifyProfileNo] = useState(1)
  const [isProfileConfigured, setIsProfileConfigured] = useState(false)
  const [isErrorFree, setIsErrorFree] = useState(true)
  const [defaultChecked, setDefaultChecked] = useState<any>({})
  const [testCaseDto, setTestCaseDto] = useState<any>(useSelector(
    (state: any) => state.lithosApiReducer.testCaseDto
  ))
  const [testCaseName, setTestCaseName] = useState(testCaseDto?.name)
  const [isDNR, setIsDNR] = useState(false)
  const testCaseSUT = localStorage.getItem('TestCaseSUT')
  const segmentOptions = testCaseSUT === SystemUnderTest.Acquirer ? 
                [{label: SegmentValue.Disable, value: SegmentValue.Disable}, {label: SegmentValue.Default, value: SegmentValue.Default}, {label: SegmentValue.Overwrite, value: SegmentValue.Overwrite}] : 
                [{label: SegmentValue.Disable, value: SegmentValue.Disable}, {label: SegmentValue.Enable, value: SegmentValue.Enable}]

  useEffect(() => {
    setLoading(true);
    if(!testCaseDto){
      loadTestCase()
    }
  }, []);

  const loadTestCase = () => {
    const profilesLength = testCaseDto && Object.keys(testCaseDto?.profileID)?.length
    let updatedProfilesLength;
    if (schemeSpecific === 'true'){
      dispatch(getTestPlanTestCaseById({ testCaseId: testCaseId, scheme: scheme })).then((res) => {
        updatedProfilesLength = Object.keys(res?.payload?.testCase?.profileID)?.length
        if(profilesLength != updatedProfilesLength){
          if(profilesLength > updatedProfilesLength && profileIDNo !== 1){
            setProfileIDNo(profileIDNo - 1)
          }
          setIsProfilesLengthChanged(!isProfilesLengthChanged)
        }
        setTestCaseDto(res?.payload?.testCase)
        setTestCaseName(res?.payload?.testCase?.name)
      })
    }
    else {
      dispatch(getTestCaseById(testCaseId)).then((res) => {
        updatedProfilesLength = Object.keys(res?.payload?.testCase?.profileID)?.length
        if(profilesLength != updatedProfilesLength){
          if(profilesLength > updatedProfilesLength && profileIDNo !== 1){
            setProfileIDNo(profileIDNo - 1)
          }
          setIsProfilesLengthChanged(!isProfilesLengthChanged)
        }
        setTestCaseDto(res?.payload?.testCase)
        setTestCaseName(res?.payload?.testCase?.name)
      })
    }
  }

  useEffect(() => {
    dispatch(HeaderTitle(`${testCaseName} Configuration`));
    dispatch(
      BreadCrumbConfig({
        title: "Test case configuration",
        href: `/testcases/configuration?scheme=${scheme}&testCaseId=${testCaseId}&isSchemeSpecific=${schemeSpecific}`,
        preventPush: false,
      } as any)
    )
  }, [testCaseName])

  useEffect(() =>  {
    form.resetFields()
    setIsDNR(false)
    dispatch(getDataFields({ scheme: scheme, testCaseId: testCaseId, schemeSpecific: schemeSpecific, profileIDNo: profileIDNo }))
    .then((data :any)=>{
      const newData = renameSubElements(data.payload?.dataFields)
      setIsProfileConfigured(data.payload?.isConfigured)
      if(data?.payload?.delayBy !== 0){
        form.setFieldValue('DelayBy', data?.payload?.delayBy)
      }
      if(data?.payload?.dnr){
        form.setFieldValue('DNR', data?.payload?.dnr)
        setIsDNR(data?.payload?.dnr)
      }
      updateDisabledChildrenIds(newData)
      setDataFieldList(newData)
      form.setFieldsValue(newData)
      const updatedCollapsedState: Record<string, any> = {};
      // when the page loads capturing all the sub element data and storing it in a state ,
      // so that this data can be added at the time of making save configuration call. 
      const initializeChildren = (element) => {
        setSwitchStatus((prevStatus) => ({
          ...prevStatus,
          [element.id]: element.segment,
        })) 
        if(element?.isComputeEnabled){
          if(element?.defaultValue === element?.userGivenValue){
            setDefaultChecked((prevStatus) => ({
              ...prevStatus,
              [element.id]: true,
            })) 
          } else {
            setCustomValues((prevCustomValues) => [...prevCustomValues, element.id]);      
          }          
        }
        if (element.errorMessage && element.isSelected) {
          setIsErrorFree(false);
          addErrorUsingKey(element.id, element.errorMessage);
        }
        if (element?.children?.length > 0) {
          element.children.forEach((se) => {
            if (se.isSelected && se.errorMessage && !expandedRowKeys.includes(element.id)) {
              setIsErrorFree(false); 
              const Element = element.id.split('-')
              const mainElementKey = Element.slice(0, 2).join('-');
              if(Element.length > 2){
                const subElementKey = Element.slice(0, 3).join('-');
                setExpandedRowKeys((prevKeys) => [...prevKeys, subElementKey]);
              }
              setExpandedRowKeys((prevKeys) => [...prevKeys, mainElementKey]);
              addErrorUsingKey(se.id, se.errorMessage)
            }
            else {
              updatedCollapsedState[se.id] = {
                isSelected: se.segment === SegmentValue.Enable || se.segment === SegmentValue.Overwrite,
                dataElement: se.dataElement,
                applicability: se.applicability,
                name: se.name,
                userGivenValue:
                se.userGivenValue !== null ? se.userGivenValue : se.defaultValue,
                errorMessage: se.errorMessage,
                isComputeEnabled: se.isComputeEnabled,
                arguments: (se?.isArg && se.args !== null) ? se.arguments : undefined,
                segment: se.segment,
                defaultValue: se.defaultValue
              }};
            initializeChildren(se)
          });
        }
        else if(element?.isArg && element.args !== null){
          form.setFieldValue(['dataFields', element.id, 'arguments'], element.arguments)
        }
      }
      newData?.forEach((item) => {
        initializeChildren(item)
      })
      setProfileDataFields((prevProfileDataFields) => ({
        ...prevProfileDataFields,
        [profileIDNo]: newData,
      }))
      setCollapsedState(updatedCollapsedState)
      setLoading(false)
    })
  }, [profileIDNo, isProfilesLengthChanged]) 

  useEffect(() => {
    form.validateFields().catch(() => {
      scrollToError(0)
    })
  }, [dataFieldList])

  // function to handle the disable functionality of the Data element
  const updateDisabledChildrenIds = (data: any) => {
    const ids = data?.flatMap(mainElement => {
      if (mainElement.children !== null && mainElement.lengthType === "Fixed") {
        return mainElement?.children?.map(child => child.id);
      }
      return [];
    });
    data?.forEach(item => {
      if(item.name === 'MTI'){
        ids.push(item.id);
      }
      if (item.name === "HeaderField") {
        ids.push(item.id);
        item?.children?.map((hf) => {
          if(hf.applicability === "M"){
            ids.push(hf.id);
          }
        })
      }
    });
    setDisabledElements(ids);
  }

  // function to handle the expand of the rows, here we are removing the sub element data from the collapsedState when it is expanded,
  // as it will be captured and no need to manually add and adding it in the state when it is row is collapsed
  const handleRowExpand = async (record: any, isExpanded: boolean) => {
    let updatedCollapsedState: Record<string, any> = { ...collapsedState };
    if (!isExpanded) {
      const processChildren = (children) => {
        children.forEach((se) => {
          updatedCollapsedState[se.id] = form.getFieldValue(["dataFields", se.id]);
          if (se.children) {
            processChildren(se.children);
          }
        });
      };
      if (record.children) {
        processChildren(record.children);
      }
    } else {
      const expandedRecordIds = [...record.children.map((child) => child.id)];
      expandedRecordIds.forEach((key) => {
        const record = updatedCollapsedState[key];
        if(record?.isComputeEnabled){
          if(record?.defaultValue === record?.userGivenValue){
            setDefaultChecked((prevStatus) => ({
              ...prevStatus,
              [key]: true,
            })) 
          } else {
            setCustomValues((prevCustomValues) => [...prevCustomValues, key]);      
          }          
        }
        delete updatedCollapsedState[key];
      });
    }
    setCollapsedState(updatedCollapsedState);
  }

  const handleSubmit = async (activeKey: number) => {
    await form.validateFields()
      .then(() => {
        onFinish(form.getFieldsValue(), activeKey).then(() => navigate(-1));
      })
      .catch(() => {
        setIsSaveWithErrors(true)
      })
  }

  const scrollToError = (errorPosition: number) => {
    const errorFields = form.getFieldsError().filter(({ errors }) => errors.length > 0);
    if (errorFields.length > 0) {
      const errorField = errorFields[errorPosition];
      form.scrollToField(errorField?.name, {behavior: 'smooth'});
    }
  }

  const oncloseModal = () => {
    scrollToError(0)
    setIsSaveWithErrors(false)
    setIsCreateProfile(false)
    setIsDeleteProfile(false)
  }

  const onConfirm = () => {
    onFinish(form.getFieldsValue(), profileIDNo).then(() => {navigate(-1)});
  }

  const onModalFinish = (values: any) => {
    if(isCreateProfile){
      dispatch(createProfile({ mti : values?.MTI, testCaseId: testCaseId, isSchemeSpecific: schemeSpecific, scheme: scheme})).then((res) => {
        ShowToaster(res?.payload, api)
        setIsCreateProfile(false)
        loadTestCase()
      })
    } else if(isDeleteProfile){
      dispatch(deleteProfile({ profileIDNo : toModifyProfileNo, testCaseId: testCaseId, isSchemeSpecific: schemeSpecific, scheme: scheme})).then((res) => {
        ShowToaster(res?.payload, api)
        setIsDeleteProfile(false)
        loadTestCase()
      })
    } 
  }

  const onFinish = async (values: any, key: number) => {
    // here we are manually constructing the datafields when save is clicked
    // adding the collapsedState to the form captured values and constructing datafields.
    const mergedValues = { ...collapsedState, ...values.dataFields }
    const DataFields = constructDataFields(mergedValues)
    dispatch(saveConfiguration({ DataFields, Scheme: scheme, IsSchemeSpecific: schemeSpecific === "true", TestCaseId: testCaseId, ProfileIDNo: profileIDNo, DNR: values.DNR, DelayBy: values.DelayBy })).then(() => {
      if(key !== profileIDNo){
        dispatch(getDataFields({ scheme: scheme, testCaseId: testCaseId, schemeSpecific: schemeSpecific, profileIDNo: profileIDNo })).then((data :any)=>{
          const newData = renameSubElements(data.payload?.dataFields)
          setProfileDataFields((prevProfileDataFields) => ({
            ...prevProfileDataFields,
            [profileIDNo]: newData,
          }))
        })
        setProfileIDNo(Number(key))
        oncloseModal()
        setLoading(false)
      }
    })
  };

  // this function is to construct datafields in the DTO format of the API
  // the captured datafields are not nested like SE inside DE.
  // hence initializing the sub elements and adding the corresponding SE values to the main DE. 
  const constructDataFields = (dataFields: any) => {
    const DataFields: any[] = [];
    const elements = Object.keys(dataFields).sort((a, b) => a.length - b.length);
  
    elements.forEach((key) => {
      const Element = key.split('-');
      if(Element.length === 2) {
        dataFields[key].subElements = [];
        DataFields.push(dataFields[key]);
      } else {
        DataFields.forEach(de => {
          if (de.dataElement == parseInt(Element[1])) {
            if(Element.length === 3) {
              de.subElements.push(dataFields[key]);
            } else {
              de.subElements.forEach(se => {
                if (se.dataElement == parseInt(Element[2])) {
                  se.subElements = se.subElements || [];
                  se.subElements.push(dataFields[key]);
                }
              });
            }
          }
        });
      }
    });

    return DataFields;
  }  

  const addCustomValue = (value: any, recordId: string) => {
    if (value === SelectConstants.CustomValue) {
      // Add the recordId to the customValues state if it's not already there
      if (!customValues.includes(recordId)) {
        setCustomValues((prevCustomValues) => [...prevCustomValues, recordId]);      
      }
      setDefaultChecked((prevStatus) => {
        const {[recordId]: _, ...rest} = prevStatus;
        return rest;
      });
    } else {
      // Remove the recordId from the customValues state if it exists
      setCustomValues((prevCustomValues) =>
        prevCustomValues.filter((id) => id !== recordId)
      );
    }
  };

  const removeErrorUsingKey = (key) => {
    form.setFieldValue(['dataFields', key, 'errorMessage'], '');
    form.setFields([
      {
        name: ['dataFields', key, 'userGivenValue'],
        errors: [],
      },
    ]);
    const keyToRemove = getParsedDEKey(key)
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[keyToRemove];
      return newErrors;
    });
  };

  const addErrorUsingKey = (key, errorMessage) => {
    const keyToAdd = getParsedDEKey(key)
    setErrors((prevErrors) => {
      return { ...prevErrors, [keyToAdd]: errorMessage };
    });
  };

  const validateInput = (record: any) => async (rule, value) => {
    const requiredMessage = `${record?.name} cannot be left blank`;
    
    if(isProfileConfigured){
      if ((!record?.isSelected && !record?.isArg) || value === SelectConstants.DEFAULT) {
        removeErrorUsingKey(record.id)
        return Promise.resolve();
      } else {
        if (record?.applicability === "M" && (!value || value === undefined)) {
          addErrorUsingKey(record.id, requiredMessage)
          form.setFieldValue(['dataFields', record.id, 'errorMessage'], requiredMessage);
          return Promise.reject(new Error(requiredMessage));
        }
        return validateFormat(record, value);
      }
    } else if (isErrorFree) {
      if(record?.applicability === "M" || record?.isSelected){
        if((!value || value === undefined)){
          addErrorUsingKey(record.id, requiredMessage)
          form.setFieldValue(['dataFields', record.id, 'errorMessage'], requiredMessage);
          return Promise.reject(new Error(requiredMessage));
        }
        if (value === SelectConstants.DEFAULT) {
          removeErrorUsingKey(record.id)
          return Promise.resolve();
        }
        return validateFormat(record, value);
      }
    } else if(record?.isSelected) {
        if((!value || value === undefined)){
          addErrorUsingKey(record.id, requiredMessage)
          form.setFieldValue(['dataFields', record.id, 'errorMessage'], requiredMessage);
          return Promise.reject(new Error(requiredMessage));
        }
        if (value === SelectConstants.DEFAULT) {
          removeErrorUsingKey(record.id)
          return Promise.resolve();
        }
        return validateFormat(record, value);
      }
      removeErrorUsingKey(record.id)
      return Promise.resolve();
  };

  const validateFormat = (record: any, value: string) => {
    const formatMessage = `${record?.name} should be in ${record?.format} format`;
    const maxLengthMessage = `Maximum ${record?.maxLength} characters allowed`;
    const minLengthMessage = `Minimum ${record?.minLength} characters required`;
    
    if (!getPatternByFormat(record.format).test(value)) {
      addErrorUsingKey(record.id, formatMessage)
      form.setFieldValue(['dataFields', record.id, 'errorMessage'], formatMessage);
      return Promise.reject(new Error(formatMessage));
    }
    if (record?.maxLength && value?.length > record?.maxLength) {
      addErrorUsingKey(record.id, maxLengthMessage)
      form.setFieldValue(['dataFields', record.id, 'errorMessage'], maxLengthMessage);
      return Promise.reject(new Error(maxLengthMessage));
    } else if (record?.minLength && value?.length < record?.minLength) {
      addErrorUsingKey(record.id, minLengthMessage)
      form.setFieldValue(['dataFields', record.id, 'errorMessage'], minLengthMessage);
      return Promise.reject(new Error(minLengthMessage));
    }
    removeErrorUsingKey(record.id)
    return Promise.resolve();
  }

  const handleSegmentChange = (value: SegmentedValue, key: string, record: any) => {
    let checked = (value === SegmentValue.Enable) || (value === SegmentValue.Overwrite)
    let isDisabled = (value === SegmentValue.Disable && testCaseSUT === SystemUnderTest.Acquirer)
    const updateRecordAndForm = (id: string) => {
      setSwitchStatus((prevStatus) => ({
        ...prevStatus,
        [id]: value,
      })) 
      form.setFieldValue(['dataFields', id, 'isSelected'], checked);
      form.setFieldValue(['dataFields', id, 'segment'], value);
      if(!checked){
        removeErrorUsingKey(id)
      }
    };
    // Function to recursively update nested elements
    const updateNestedElements = (element: any) => {
      if (element.children) {
        element.children.forEach((child) => {
          if(checked){
            if((value === SegmentValue.Enable && child.applicability === 'M') || value === SegmentValue.Overwrite){
              updateRecordAndForm(child.id);
              updateNestedElements(child);
            }
          }
          else{
            updateRecordAndForm(child.id);
            updateNestedElements(child);
          }
        });
      }
      if(element.args){
        element.args.forEach((child) => {
          if(checked){
            if((value === SegmentValue.Enable && child.applicability === 'M') || value === SegmentValue.Overwrite){
              updateRecordAndForm(child.id);
              updateNestedElements(child);
            }
          }
          else{
            updateRecordAndForm(child.id);
            updateNestedElements(child);
          }
        });
      }
    };
    // Update the clicked element and its ancestors
    record.isSelected = checked;
    updateRecordAndForm(key);
    // If a sub-row element is checked, update the main element and all its children
    const keyElements = key.split('-');
    if (checked && keyElements.length > 2) {
      const mainElementKey = keyElements.slice(0, 2).join('-');
      updateRecordAndForm(mainElementKey);
      const mainElement = dataFieldList.find((element) => element.id === mainElementKey);
      if(mainElement){
        const subField = mainElement?.children?.find((element) => element.id === key);
          if(subField){
            updateRecordAndForm(subField.id);
            updateNestedElements(subField);
          }
      }
      if (keyElements.length > 3) {
        const subElementKey = keyElements.slice(0, 3).join('-');
        updateRecordAndForm(subElementKey);
        const subElement = dataFieldList.find((element) => element.id === subElementKey);
        if(subElement){
          const subField = subElement?.children?.find((element) => element.id === key);
            if(subField){
              updateRecordAndForm(subField.id);
              updateNestedElements(subField);
            }
        }
      }
    }
    else{
      // Update all the sub-row content enabled/disabled in the UI and form isSelected
      record.children?.forEach((se: any) => {
        se.isSelected = checked;
        if(checked){
          if((value === SegmentValue.Enable && se.applicability === 'M') || value === SegmentValue.Overwrite){
            updateRecordAndForm(se.id);
            updateNestedElements(se);
          }
        }
        else{
          updateRecordAndForm(se.id);
          updateNestedElements(se);
        }
      });
    }
    // condition to overwrite the main de when se or sf is disable for SUT acquirer 
    if(isDisabled && keyElements.length > 2){
      checked = true;
      value = SegmentValue.Overwrite;

      const mainElementKey = keyElements.slice(0, 2).join('-');
      updateRecordAndForm(mainElementKey);

      if (keyElements.length > 3) {
        const subElementKey = keyElements.slice(0, 3).join('-');
        updateRecordAndForm(subElementKey);
        const subElement = dataFieldList.find((element) => element.id === subElementKey);
        if(subElement){
          const subField = subElement?.children?.find((element) => element.id === key);
            if(subField){
              updateRecordAndForm(subField.id);
            }
        }
      }
    }
  };

  const handleDefaultSelect = (record: any) => {
    setDefaultChecked((prevStatus) => ({...prevStatus, [record.id]: true}))
    form.setFieldValue(['dataFields', record.id, 'userGivenValue'], record.defaultValue);
    form.setFieldValue(['dataFields', record.id, 'defaultValue'], record.defaultValue);
    form.setFieldValue(['dataFields', record.id, 'isComputeEnabled'], true);
    form.setFields([
      {
        name: ['dataFields', record.id, 'userGivenValue'],
        errors: [],
      },
    ]);
  }

  const getInitialValue = (record: any) => {
    if(record.name === 'MTI' && record.userGivenValue === null || undefined){
      return profileDataFields[profileIDNo][0]?.userGivenValue;
    }
    if (!isStringNullOrEmpty(record.userGivenValue)) {
      return record.userGivenValue;
    } else if (record.isComputeEnabled) {
      return record.defaultValue;
    } else {
      return record.userGivenValue;
    }
  }; 

  const getInitialValueForDefault = (record: any) => {
    if(!isStringNullOrEmpty(record.userGivenValue) && record.userGivenValue !== SelectConstants.DEFAULT && record.userGivenValue !== record.defaultValue){
      return SelectConstants.CustomValue
    }else if (record.isComputeEnabled && !isStringNullOrEmpty(record.defaultValue)) {
      return SelectConstants.SystemComputedValue;
    } 
  }

  const columns: ColumnsType = [
    {
      title: "Data Element",
      dataIndex: "dataElement",
      key: "dataElement",
      className: 'DE-col-title',
      width: showErrors ? 350 : 450,
      render: (text: any, record: any, index: any) => (
        <Form.Item
          name={["dataFields", record.id, "dataElement"]}
          initialValue={record.dataElement}
          key={"dataElement" + record?.dataElement + index + record.id}
        >
          <Space>
            <span className="DE-content">{text}</span>
            <Divider type="vertical"/> 
            <Tooltip title={record.name}>
              <Typography.Text ellipsis={true} style={{ width: showErrors ? 200 : 330 }}>
                <span className="name-content"> {record?.name}</span>
              </Typography.Text>
            </Tooltip>
          </Space>
        </Form.Item>
      ),
    },
    {
      title: "Selection",
      dataIndex: "segment",
      key: "segment",
      className: 'segment-col-title',
      width: 350,
      render: (text: any, record: any, index: any) => (
        <Tooltip title={`${testCaseDto.isCertification ? ('The config is read-only') : ('')}`} placement="topLeft">
          <Form.Item
            name={["dataFields", record.id, "segment"]}
            initialValue={switchStatus[record.id]}
            key={"segment" + record?.dataElement + index + record.id}
          >
            <Segmented
              options={segmentOptions}
              value={switchStatus[record.id]}
              onChange={(value) => handleSegmentChange(value, record.id, record)}
              disabled={testCaseDto.isCertification || disabledElements?.includes(record.id) || isDNR}
            /> 
          </Form.Item>
          <Form.Item hidden name={["dataFields", record.id, "errorMessage"]}>
              <Input  />
          </Form.Item>    
          <Form.Item hidden name={["dataFields", record.id, "isSelected"]}>
              <Input  />
          </Form.Item>    
        </Tooltip>
      )
    },
    {
      title: "Applicability",
      dataIndex: "applicability",
      key: "applicability",
      width: showErrors ? 100 : 200,
      render: (text: any, record: any, index: any) => (
        <Form.Item
          name={["dataFields", record.id, "applicability"]}
          initialValue={record.applicability}
          key={"applicability" + record?.dataElement + index + record.id}
        >
          {ApplicabilityText[text]}
        </Form.Item>
      ),
    },
    {
      title: "",
      dataIndex: "isComputeEnabled",
      key: "isComputeEnabled",
      render: (text: any, record: any, index: any) => (
        <Form.Item
          name={["dataFields", record.id, "isComputeEnabled"]}
          initialValue={record.isComputeEnabled}
          key={"isComputeEnabled" + record?.dataElement + index + record.id}
        >
          <div className="project-content">{text}</div>
        </Form.Item>
      ),
    },
    {
      title: "Value",
      dataIndex: "userGivenValue",
      width: showErrors ? 300 : 550,
      key: "userGivenValue",
      render: (text: any, record: any, index: any) => {
        let renderedValue: any = null;
        if ((record?.children !== null && !record?.isComputeEnabled)|| (switchStatus[record.id] === SegmentValue.Disable || switchStatus[record.id] === SegmentValue.Default) || (testCaseDto?.isCertification && text === null)) {
          renderedValue = "";
        } else if (record?.isComputeEnabled) {
          renderedValue = (
            <Row>
              <Tooltip title={`${testCaseDto.isCertification ? ('The config is read-only') : ('')}`} placement="topLeft">
                <Col span={8}>
                  <Form.Item name={["dataFields", record.id, "validValues"]} initialValue={getInitialValueForDefault(record)}>
                    <Select
                      key={"select" + record?.dataElement + index + record.id}
                      onChange={(e: any) => {
                        if(e === SelectConstants.CustomValue){
                          form.setFieldValue(["dataFields", record.id, "userGivenValue"], '');
                          addCustomValue(e, record.id);
                        } else if(e === SelectConstants.SystemComputedValue){
                          handleDefaultSelect(record)
                        }
                      }}
                      className="child-validValues"
                      disabled={testCaseDto.isCertification || isDNR}
                    >
                      {record?.validValues.map((C: any, index: any) => (
                        <Select.Option value={C} key={C}>
                          {C}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  </Col>
              </Tooltip>
              <Tooltip title={`${testCaseDto.isCertification ? ('The config is read-only') : (defaultChecked[record.id] && record.defaultValue === SelectConstants.DEFAULT) ? ('The value will be computed by the system during runtime') : ('')}`} placement="topLeft">
                <Col span={15} offset={1}>
                  <Form.Item
                      name={["dataFields", record.id, "userGivenValue"]}
                      initialValue={getInitialValue(record)}
                      key={"Value" + record?.dataElement + index + record.id}
                      rules={[
                        {
                          validator: validateInput(record),
                        },
                      ]}
                    >
                      <Input
                          key={index}
                          className="data-field-placeholder"
                          placeholder={record.helpText}
                          disabled={isDNR || !customValues.includes(record.id) || defaultChecked[record?.id]}
                          readOnly={testCaseDto.isCertification}
                          type={(defaultChecked[record.id] && record.defaultValue === SelectConstants.DEFAULT) ? "password" : "text"}
                      />
                    </Form.Item>
                    {(record?.isArg && record?.args !== null) &&
                      Object.entries(record?.args).map(([_, arg]: [string, any]) => {
                        return (
                          <Form.Item
                            name={["dataFields", record.id, "arguments", arg.name.replace(/\s/g, '')]}                    
                            initialValue={getInitialValue(arg)}
                            key={"Value" + arg?.dataElement + index + arg.id}
                            rules={[
                              {
                                validator: validateInput(arg),
                              },
                            ]}
                          >
                            <FloatingLabelInput 
                              key={index}
                              className="data-field-placeholder"
                              label={arg.name}
                              placeholder={arg.helpText}
                              disabled={isDNR || !defaultChecked[record?.id] || testCaseDto.isCertification}
                              readOnly={testCaseDto.isCertification}    
                            />
                          </Form.Item>
                        )
                    })}
                </Col>
              </Tooltip>
            </Row>
          );
        } else if (record?.validValues.length === 0) {
          renderedValue = (
            <Tooltip title={`${testCaseDto.isCertification ? ('The config is read-only') : ('')}`} placement="topLeft">
              <Form.Item
                name={["dataFields", record.id, "userGivenValue"]}
                initialValue={getInitialValue(record)}
                key={"Value" + record?.dataElement + index + record.id}
                rules={[
                  {
                    validator: validateInput(record),
                  },
                ]}
              >
                <Input
                    key={index}
                    className="data-field-placeholder"
                    placeholder={record.helpText}
                    disabled={record.name === 'MTI' || isDNR}
                    readOnly={testCaseDto.isCertification}
                  />
              </Form.Item>
            </Tooltip>
          );
        } else if (!record?.isComputeEnabled && record?.validValues.length > 0) {
          renderedValue = (
            <Row>
              <Tooltip title={`${testCaseDto.isCertification ? ('The config is read-only') : ('')}`} placement="topLeft">
                <Col span={8}>
                  <Form.Item name={["dataFields", record.id, "validValues"]}
                    initialValue={
                      record.userGivenValue !== null
                        ? record.userGivenValue
                        : record.defaultValue
                    }>
                    <Select
                      key={"select" + record?.dataElement + index + record.id}
                      onChange={(e: any) => {
                        if(e === SelectConstants.CustomValue){
                          form.setFieldValue(["dataFields", record.id, "userGivenValue"], '');
                        }
                        else{
                          form.setFieldValue(["dataFields", record.id, "userGivenValue"], e);  
                        }
                        addCustomValue(e, record.id);
                      }}
                      className="child-validValues"
                      disabled={testCaseDto.isCertification || isDNR}
                    >
                      {record?.validValues.map((C: any, index: any) => (
                        <Select.Option value={C} key={C}>
                          {C}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Tooltip>
              <Tooltip title={`${testCaseDto.isCertification ? ('The config is read-only') : ('')}`} placement="topLeft">
                <Col span={15} offset={1}>
                  <Form.Item
                    className="data-field-input"
                    name={["dataFields", record.id, "userGivenValue"]}
                    initialValue={
                      record.userGivenValue !== null
                        ? record.userGivenValue
                        : record.defaultValue
                    }
                    key={"select" + record?.dataElement + index + record.id}
                    rules={[
                      {
                        validator: validateInput(record),
                      },
                    ]}
                  >
                    <Input
                      key={index}
                      disabled={!customValues.includes(record.id) || isDNR}
                      className="data-field-placeholder"
                      placeholder={record.helpText}
                      readOnly={testCaseDto.isCertification}
                    />
                  </Form.Item>
                </Col>
              </Tooltip> 
            </Row>
          );
        }   
        return renderedValue;
      },
    }        
  ];

  const OnClickEvent = (value: any) => {
    if(value === 'passCriteria'){
      navigate(`/testcases/passCriteria?scheme=${scheme}&testCaseId=${testCaseId}&isSchemeSpecific=${schemeSpecific}`)
    } 
  }

  const rowClassName = (record) => {
    const subRowKeys = getSubRowKeys(dataFieldList);
    return subRowKeys?.some(subKey => record.id !== subKey && record.id.startsWith(subKey)) ? 'sub-elements-row' : '';
  };
  
  const tableStyle = {
    width: showErrors ? '75%' : '100%',
  };

  const gridStyle: React.CSSProperties = {
    width: '100%',
    textAlign: 'start',
    padding: '14px',
    cursor: 'pointer'
  };

  const operations = 
  <Row style={{marginBottom: '-20px'}} gutter={[0, 8]}>
    <Col span={10} offset={2} style={{marginTop: '6px'}}>
      <Form.Item
        className="modal-form"
        name='DNR'
        valuePropName="checked"
      >
          <Checkbox checked={isDNR} onChange={(e) => setIsDNR(e.target.checked)}><span className="name-content">Do not respond</span></Checkbox> 
      </Form.Item>
    </Col>
    <Col span={10} offset={2}>  
      <Form.Item
          name="DelayBy"
      >
          <FloatingLabelInput label='Delay in seconds' placeholder="Delay in seconds" type='number' min='1' disabled={isDNR}/>
      </Form.Item>
    </Col>

  </Row>;
  
  const items = testCaseDto && new Array(Object.keys(testCaseDto?.profileID).length).fill(null).map((_, i) => {
    const id = String(i + 1);
    if (profileIDNo === Number(i) + 1) {
      return {
        label: `${id}`,
        key: id,
        closable: Object.keys(profileDataFields).length !== 1,
        children: (
          <div style={{display:'flex'}}>
            <TableContainer
              tableData={profileDataFields[id] || null}
              column={columns}
              className={"data-field-table"}
              pagination={"false"}
              loading={profileDataFields[id] === undefined ? true : loading}
              expandHandler={(expanded: boolean, record: any) => {
                const key = record?.id;
                handleRowExpand(record, expanded)
                setExpandedRowKeys((prevExpandedRowKeys) =>
                  expanded
                    ? [...prevExpandedRowKeys, key]
                    : prevExpandedRowKeys.filter((k) => k !== key)
                );
              }}
              expandedRowKeys={expandedRowKeys}
              scrollY='52vh'
              rowClassName={rowClassName}
              style={tableStyle}
            /> 
            {showErrors && 
            <Card title="Error(s)" className="show-error-card" 
              key={id} extra={<CloseOutlined onClick={() => setShowErrors(false)}/>}
              headStyle={{ backgroundColor: '#FAFAFA !important', color: '#8e8e8e', fontSize: '13px'}}>
              <div style={{height:'50vh', overflow:'auto'}}>
                {Object.entries(errors).map(([field, errorMessage], index) => (
                  <Card.Grid key={field} style={gridStyle} onClick={() => scrollToError(index)}>
                    <strong style={{color:'#D13F3F'}}>{field}:</strong> {errorMessage}
                  </Card.Grid>  
                ))}
              </div>
            </Card>
          }  
          </div>
        )
      }
    } else {
      return {
        label: `${id}`,
        key: id,
        children: null,
      };
    }
  });

  const addTab = () => {
    setIsCreateProfile(true)
  };

  const removeTab = (targetKey) => {
    setIsDeleteProfile(true)
    setToModifyProfileNo(targetKey)
  };

  const handleTabChange = async (activeKey) => {
    setErrors({})
    setShowErrors(false)
    setExpandedRowKeys([])
    setCollapsedState({})
    if(!testCaseDto.isCertification){
      await onFinish(form.getFieldsValue(), activeKey);
    }  
    else{
      setProfileIDNo(Number(activeKey))
    }
  };

  const onEdit = ( targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove' ) => {
    if (action === 'add') {
      addTab();
    } else {
      removeTab(targetKey);
    }
  };

return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Form form={form} name="basic" autoComplete="off" onFinish={() => onFinish(form.getFieldsValue(), profileIDNo)} scrollToFirstError={true}>
        {testCaseDto &&
        <>
          <Tabs
            tabBarExtraContent={!testCaseDto.isCertification && operations} 
            hideAdd={Object.keys(testCaseDto.profileID).length == process.env.REACT_APP_MAX_PROFILE_COUNT}
            defaultActiveKey="1" 
            type={testCaseDto?.isCertification ? "card" : "editable-card"}
            onChange={handleTabChange} 
            className='dataFields-tab' 
            size="large" 
            items={items} 
            onEdit={onEdit}
          />
              <div className="datafields-btn">
                {!testCaseDto.isCertification ? (
                <>
                  {errors &&  Object.keys(errors)?.length > 0 && 
                  <div className='tertiary-btn cancel-df-btn show-error-btn' style={{float:'left', marginTop:'18px'}} onClick={()=>setShowErrors(true)}>
                    Show all errors
                  </div>}
                  <div className="float-right datafields-btn">
                  <div key="cancel" className='tertiary-btn cancel-df-btn' onClick={()=>{navigate(-1)}}>
                    Cancel
                  </div>
                  <Form.Item>
                    <Button className="submit-configuration" type="primary" onClick={() => handleSubmit(profileIDNo)}>
                      Save
                    </Button>
                  </Form.Item>
                  </div>
                </>
                ) : (
                  <div key="cancel" className='tertiary-btn cancel-df-btn float-right datafields-btn' onClick={()=>{navigate(-1)}}>
                    Close
                  </div>
                )}
              </div>
        </>
        }
      </Form>
      {isSaveWithErrors && <SaveWithErrorsModal
        isOpen={isSaveWithErrors}
        onClose={oncloseModal}
        onFinish={onConfirm}
      />}
      {isCreateProfile && <CreateProfileModal
        isOpen={isCreateProfile}
        onClose={oncloseModal}
        onFinish={onModalFinish}
        value={testCaseDto}
      />}
      {isDeleteProfile && <DeleteModal
        isOpen={isDeleteProfile}
        onClose={oncloseModal}
        onFinish={onModalFinish}
        title="Profile"
        values={undefined}
      />}
    </>
  );
}
