import { Input, Space, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react'
import Delete from "../../Assets/Delete.svg";
import { useDispatch } from 'react-redux';
import { CreateUser, UpdateUser, getUsersByType, deleteUser } from '../../Redux/Actions/Actions';
import { BreadCrumbConfig, HeaderTitle } from '../../Redux/Reducers/UtilReducer';
import SubHeader from '../../Components/Header/SubHeader';
import TableContainer from '../../Components/Table/TableContainer';
import { SearchOutlined } from '@ant-design/icons';
import { JsonParse, md5HashHandler } from '../../Utils/Utils';
import DeleteModal from '../../Components/DeleteModal/DeleteModal';
import CreateEditUserModal from './CreateEditUserModal';
import { ShowToaster } from '../../Components/Notifications/Notifications';
import Edit from "../../Assets/Edit.svg"

const Users = () => {
    const [api, contextHolder] = notification.useNotification()
    const dispatch = useDispatch();
    const [searchedText, setSearchedText] = useState("");
    const userDetails = JsonParse(localStorage.getItem("UserDetails"));
    const [modalState, setModalState] = useState({
        isCreate: false,
        isDelete: false,
        isUpdate: false,
        userId: "",
      });
    const [userData, setUserData] = useState<any>([]);
    const [user, setUser] = useState({})

      useEffect(() => {
        dispatch(HeaderTitle("Users"));
        dispatch(
          BreadCrumbConfig({
            title: "Users",
            href: "/users",
            preventPush: true,
          } as any)
        );
        loadData();
      }, [])

    const loadData = async () => {
        dispatch(getUsersByType(userDetails?.Id)).then((data: any) => {
            setUserData(data?.payload?.userList || []);
        });
    };

    const onUserDeleteClick = (userId) => {
      setModalState({ ...modalState, isDelete: true, userId });
    };

    const oncloseModal = () => setModalState((prevState) => ({ ...prevState, isCreate: false, isDelete: false, isUpdate: false }));

    const onFinish = async (values) => {
      const { isCreate, isDelete, userId, isUpdate } = modalState;
      if (isCreate) {
        values.Password = md5HashHandler(values.Password);
        await dispatch(CreateUser(values)).then((res: any) => {
          ShowToaster(res.payload, api);
          setUserData([res?.payload?.user, ...userData])
        });
      } else if(isUpdate){
        await dispatch(UpdateUser(values)).then((res) => {
          ShowToaster(res.payload, api);
          const updatedUsers = userData.map(user => {
            if (user.id === res?.payload?.user?.id) {
              return res?.payload?.user;
            } else {
              return user;
            }
          });
          setUserData(updatedUsers);
          setUser(res?.payload?.user)
        });
      } else if (isDelete) {
        await dispatch(deleteUser(userId)).then((res) => {
          ShowToaster(res.payload, api);
          setUserData(data =>
            data.filter(user => {
              return user.id !== userId;
            }),
          );
        });
      }
      oncloseModal();
    };

    const columns: ColumnsType = [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          filteredValue: [searchedText],
          onFilter: (value: any, record: any) => {
            return String(record?.name).toLowerCase().includes(value.toLowerCase())
          },
          sorter: (a: any, b: any) => {
            return a?.name.localeCompare(b?.name); 
          }, 
          render: (text) => <div className="session-content">{text}</div>,
        },
        {
          title: "Email id",
          dataIndex: "email",
          key: "email",
          render: (text) => <div className="session-content">{text}</div>,
        },
        {
          title: "Organization",
          dataIndex: "organization",
          key: "organization",
          render: (text) => <div className="session-content">{text}</div>,
        },
        {
          title: "User type",
          dataIndex: ['licenseDetails', 'userType'],
          key: "userType",
          render: (text) => <div className="session-content">{text}</div>,
        },
        {
          title: "Created By",
          key: "owner",
          dataIndex:  ["owner", "email"],
          render: (text) => <div className="session-content">{text}</div>,
          sorter: (a: any, b: any) => a.owner - b.owner,
        },
        {
          title: "Actions",
          key: "actions",
          dataIndex: "actions",
          render: (text: any, record: any) => (
            <div className="combined-icon"  onClick={() => setUser(record)}>
              <Space size={"large"}>
                <div
                  onClick={() => {
                      setUser(record)
                      setModalState({ ...modalState, isUpdate: true })
                  }}
                  className="project-content"
                >
                  <Tooltip title="Edit">
                    <img className="icons" src={Edit} />
                  </Tooltip>
                </div>
                <div
                  onClick={() => onUserDeleteClick(record?.id)}
                  className="project-content"
                >
                  <Tooltip title="Delete">
                    <img className="icons" src={Delete}/>
                  </Tooltip>
                </div>
              </Space>
            </div>
          ),
        },
      ];

      return (
        <>
          {contextHolder}
          <SubHeader OnClickEvent={() => setModalState({ ...modalState, isCreate: true })} IsSelectAll={false} SelectedIds={null}/>
          <Input size="large" className="search-bar" placeholder="Search by name" suffix={<SearchOutlined />} onChange={(e) => setSearchedText(e.target.value)} />
          <TableContainer tableData={userData} column={columns} className="session-table" scrollY="65vh" />
          {modalState.isCreate && (
            <CreateEditUserModal isOpen onClose={oncloseModal} onFinish={onFinish} isUpdate={false}/>
          )}
          {modalState.isUpdate && (
            <CreateEditUserModal isOpen onClose={oncloseModal} onFinish={onFinish} isUpdate={true} value={user}/>
          )}
          {modalState.isDelete && (
            <DeleteModal            
                title="User"
                values={undefined}
                isOpen={modalState.isDelete}
                onClose={oncloseModal}
                onFinish={onFinish}
            />
          )}
        </>
      );
}

export default Users;