import React, { useEffect, useState } from 'react'
import { IModalList } from '../../Interface/Interfaces';
import { Modal, Button } from 'antd';

const DeleteModal : React.FC<IModalList> = ({isOpen, onClose, onFinish, title, values}) => {
  const [resultLink, setResultLink] = useState<any>([])

  let footerBtn: any = []
  let warningMsg = ''

  useEffect(() => {
    let combinedLinks: any = []
    values?.map((mainElement: any, index: Number) => {
      const elements = mainElement?.split('>')
      let resultElement: any = []
      elements?.length > 1 ? elements?.map((element: any, index: Number) => {
        if(element === "PR")
          resultElement?.push("Project")
        if(element === "TP")
          resultElement?.push("Test plan")
        if(index === 1){
          if(resultElement[0] === "Project"){
            let projectLinkElements = elements[1]?.split(':')
            resultElement.push(projectLinkElements[1])
          } else if(resultElement[0] === "Test plan"){
            let testPlanLinkElements = elements[1]?.split(':')
            resultElement.push(testPlanLinkElements[1])
          }
        }
        if(element === "TC")
          resultElement.push("Test case")
        if(index === 3 && resultElement[0] === "Project"){
          let testPlanLinkElement = elements[3]?.split(':')
          resultElement.push(testPlanLinkElement[1])
        }
        else if(index === 3 && resultElement[0] === "Test plan"){
          let testCaseLinkElement = element?.split(':')
          resultElement.push(testCaseLinkElement[1])
        }
        if(index === 5){
          let testCaseLinkElement = element?.split(':')
          resultElement.push(testCaseLinkElement[1])
        }
      }) : elements?.map((element: any, index: Number) => {
        resultElement.push(element)
      })
      combinedLinks.push(resultElement);
    })
    setResultLink(combinedLinks)
  }, [values])

  if(values?.length > 0){
    warningMsg = `The ${title} has the following references, please remove them to delete.`     
  }
  else{
      footerBtn = [
        <Button key="ok" type="primary" className='delete-btn' onClick={onFinish}>
            Delete
        </Button>,
        <div key="cancel" className='tertiary-btn cancel-btn' onClick={()=>{onClose()}}>
            Cancel
        </div>,
      ]
      warningMsg = `Are you sure you want to delete this ${title}?`
  } 

  return (
    <Modal
      title={`Delete ${title}`}
      okText="Delete"
      centered
      cancelText="Discard"
      className={values?.length > 0 ? "delete-warning-modal" : "centered-modal"}
      open={isOpen}
      onOk={onFinish}
      onCancel={()=>{onClose()}}
      width={values?.length > 0 ? 750 : 300}
      footer={footerBtn}
    >
        <div className={values?.length > 0 ? "" : "delete-modal-content"}>
          <span className={values?.length > 0 ? "delete-warning-msg" : ""}>
            {warningMsg}
          </span>
          <br/>
          {values && <span className='referred-names'>
            {resultLink?.map((text: any, index: Number) => ( 
            <>
              <div className={`delete-warning-links`}>
              {text?.map((val, index) => (
                <span className={`${(val !== "Project" && val !== "Test plan" && val !== "Test case" ? 'bold-font-500' : '') }`} key={index}>
                  {val + (text.length - 1 !== index ? " > " : "")}
                </span>
              ))}
              </div>
              <br />
            </>        
            ))}
          </span>}
        </div>
    </Modal>
  )
}

export default DeleteModal;