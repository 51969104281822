import { Form, Input, Modal } from "antd";
import { IModal } from "../../Interface/Interfaces";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { useSelector } from "react-redux";

const MemberKey: React.FC<IModal> = ({ isOpen,  onClose, onFinish,  }) => {
    const [form] = Form.useForm()
    const pinKeyDto = useSelector((state:any) => state.lithosApiReducer.keySet);

    const initialValues = {
      ...pinKeyDto?.properties?.reduce((acc, property) => {
        acc[property.type] = property.value;
        return acc;
      }, {}),
      Keytype: pinKeyDto?.keyType,
    };

    return(
        <Modal
            className="create-chip-key-modal"
            title="Update Pin key"
            centered
            open={isOpen}
            onOk={()=>form.submit()}
            okText="Update"
            cancelText="Discard"
            onCancel={()=>{onClose();form.resetFields()}}
            >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.Item
            className="modal-form"
            name="Keytype"
          >
            <FloatingLabelInput label='Keytype' placeholder="Keytype" disabled='true'/>
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="COMPONENT1"
            rules={[
              { required: true, message: "Please enter the Component1!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "Component1 should be in hexadecimal format!" },
              { len: 32, message: "Component1 should be 32 characters" }
              ]}
          >
            <FloatingLabelInput label='Component1' placeholder="Component1" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="COMPONENT2"
            rules={[
              { required: true, message: "Please enter the Component2!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "Component2 should be in hexadecimal format!" },
              { len: 32, message: "Component2 should be 32 characters" }
              ]}
          >
          <FloatingLabelInput label='Component2' placeholder="Component2" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="COMPONENT3"
            rules={[
              { required: true, message: "Please enter the Component3!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "Component3 should be in hexadecimal format!" },
              { len: 32, message: "Component3 should be 32 characters" }
              ]}
          >
            <FloatingLabelInput label='Component3' placeholder="Component3" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="ENCRYPTED_ZPK"
            rules={[
              { required: true, message: "Please enter the EncryptedZPK!" },
              { pattern: /^[0-9A-Fa-f]+$/, message: "EncryptedZPK should be in hexadecimal format!" },
              { len: 32, message: "EncryptedZPK should be 32 characters" }
              ]}
          >
            <FloatingLabelInput label='EncryptedZPK' placeholder="EncryptedZPK" />
          </Form.Item>
          <Form.Item hidden name="ProjectId" >
            <Input  />
          </Form.Item>
        </Form>
        </Modal>
    )
};

export default MemberKey;
