import { useState } from 'react';
import { Input } from 'antd';

const FloatingLabelInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, disabled, pattern, isTextArea, readonly } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const handleKeyDown = (event) => {
    if (pattern) {
      if (
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.key === 'Enter' ||
        event.key === 'Tab' ||
        (event.ctrlKey && ['ArrowLeft', 'ArrowRight'].includes(event.key))
      ) {
        return;
      }
      const validCharacters = new RegExp(pattern);
      if (!validCharacters.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {isTextArea ? (
        <Input.TextArea
          onChange={props.onChange}
          onKeyDown={handleKeyDown}
          className='modal-input textarea-input'
          disabled={disabled} 
          value={value}
          autoSize={{ minRows: 3, maxRows: 5 }}
          style={{paddingTop: "12px"}}
        />
      ) : (
        <Input
          onChange={props.onChange}
          onKeyDown={handleKeyDown}
          type={type}
          value={value}
          className='modal-input'
          disabled={disabled}
          readOnly={readonly}
        />
      )}
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatingLabelInput;