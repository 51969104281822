import { useEffect, useState } from "react";
import LithoLog from "../../Assets/lithoslogo.png";
import UserIcon from "../../Assets/userIcon.svg";
import {  PoweroffOutlined, UserOutlined, WarningTwoTone } from "@ant-design/icons";
import { Layout, Dropdown, Space, MenuProps, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { JsonParse, md5HashHandler } from "../../Utils/Utils";
import { ChangePassword, Logout, fetchMasterData, getCardProfilesByUserId, getChipKeyByUserId, getMerchantProfilesByUserId } from "../../Redux/Actions/Actions";
import ChangePasswordModal from "../../Pages/Users/ChangePasswordModal";
import { ShowToaster } from "../Notifications/Notifications";
import { setForbiddenError } from "../../Redux/Reducers/LithosApiReducers";
const { Header } = Layout;

export default function HeaderContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification()
  const userDetails = JsonParse(localStorage.getItem('UserDetails'));
  const isLoggedIn = localStorage.getItem("IsAuth")
  const [modalState, setModalState] = useState({
    isUpdate: false,
  });
  const isForbidden = useSelector(
    (state: any) => state.lithosApiReducer.forbiddenError
  );
  
  useEffect(() => {
    if(isLoggedIn && isLoggedIn == 'true' && userDetails !== undefined){
      dispatch(fetchMasterData())
      dispatch(getCardProfilesByUserId(userDetails?.Id))
      dispatch(getMerchantProfilesByUserId(userDetails?.Id))
      dispatch(getChipKeyByUserId(userDetails?.Id))
    }
  }, [])

  useEffect(() => {
    if(isForbidden === true){
      dispatch(setForbiddenError(false))
      api.error({
        message: `The user is not not authorized to access the data`,
        placement: 'top',
        style: {
          width: 500,
        },
        icon: <WarningTwoTone twoToneColor={'#D13F3F'}/>,
      })
    }
  }, [isForbidden])

  const onUserUpdateClick = async () => {
    setModalState({ ...modalState, isUpdate: true });
  };

  const oncloseModal = () => setModalState((prevState) => ({ ...prevState, isUpdate: false}));

  const onFinish = async (values) => {
    if(!values.Name){
      values.OldPassword = md5HashHandler(values.OldPassword);
      values.NewPassword = md5HashHandler(values.NewPassword);
      values.Re_EnteredPassword = md5HashHandler(values.Re_EnteredPassword);

      await dispatch(ChangePassword(values)).then((res) => {
        ShowToaster(res.payload, api)
      })
    }
    oncloseModal();
  };
  
  const items: MenuProps["items"] = [
    {
      label: <span className="login-user-name">{userDetails?.Name}</span>,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      icon:<UserOutlined />,
      label: <div onClick={()=> onUserUpdateClick()}>My Account</div>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      icon:<PoweroffOutlined />,
      label: <div onClick={()=> {dispatch(Logout(userDetails.Email)).then(() => {
        navigate('/login')
      })}}>Logout</div>,
      key: "3",
    },
  ];

  return (
    <>
      {contextHolder}
      <Header className="header-container">
        <div className="header-wrapper">
          <div className="header-logo">
            <img className="header-logo-item" src={LithoLog} alt="" />
          </div>
          <div className="header-icon">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <img className="header-icon-content" src={UserIcon} alt="" />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </Header>
      {modalState.isUpdate && (
        <ChangePasswordModal isOpen onClose={oncloseModal} onFinish={onFinish} value={userDetails}/>
      )}
    </>
  );
}
