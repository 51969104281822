import Icon, { ProfileFilled } from "@ant-design/icons";
import DashboardIcon from "../Assets/dashboard.svg";
import ProjectsIcon from "../Assets/projects.svg";
import SubMenuIcon from "../Assets/submenuitem.svg";
import LogIcon from "../Assets/systemlogs.svg";
import { Tag } from "antd";

export const SideBarConfig: any = [
  {
    label: "Dashboard",
    key: 0,
    icon: <Icon component={() => <img src={DashboardIcon} />} />,
    URL: "/dashboard",
    title: "Dashboard",
  },
  {
    label: "Master data",
    key: 2,
    icon: <ProfileFilled style={{fontSize: '18px'}} />,
    URL: "/masterdata",
    title: "All Projects",
    children: [
      {
        label: "Merchant profiles",
        key: 21,
        icon: <Icon component={() => <img src={SubMenuIcon} />} />,
        URL: "merchantprofiles",
        title: "Settings",
      },
      {
        label: "Card profiles",
        key: 22,
        icon: <Icon component={() => <img src={SubMenuIcon} />} />,
        URL: "cardprofiles",
        title: "Settings",
      },
      {
        label: "Chip keys",
        key: 23,
        icon: <Icon component={() => <img src={SubMenuIcon} />} />,
        URL: "/chipkeys",
        title: "Settings",
      },
    ],
  }
];

export const PrivateRouteUrl: any = [
  { URL: "/" },
  { URL: "/dashboard" },
  { URL: "/masterdata" },
  { URL: "/cardprofiles" },
  { URL: "/merchantprofiles" },
  { URL: "/chipkeys" },
  { URL: "/projects" },
  { URL: "/testcases" },
  { URL: "/systemlogs" },
  { URL: "/testplans" },
  { URL: "/testcases/configuration" },
  { URL: "/testcases/passCriteria" },
  { URL: "/organization"},
  { URL: "/users"},
  { URL: "/testplan/testcases/configuration"},
  { URL: "/testplan/testcases/passCriteria"},
  { URL: "/testcases/analyzelogs"},
  { URL: "/projectlogs"},
  { URL: "/projectdetails"},
  { URL: "/testplan/testcases"},
  { URL: "/copyFromOther"},
];

SideBarConfig.push(
  {
    label: "Projects",
    key: 4,
    icon: <Icon component={() => <img src={ProjectsIcon} />} />,
    URL: "/projects",
    title: "Help",
  },
  {
    label: "System logs",
    key: 5,
    icon: <img src={LogIcon} />,
    URL: "/systemlogs",
    title: "Help",
  }
)

export const SchemeTag: any =  {
  'DN': <Tag color="#E5621F" className="scheme-tag">DN</Tag>,
  'DCI': <Tag color="#19316C" className="scheme-tag">DCI</Tag>,
  'VISA': <Tag color="#1434CB" className="scheme-tag">VISA</Tag>,
  'MASTERCARD': <Tag color="#EB001B" className="scheme-tag">MC</Tag>
};

export const ApplicabilityText: any = {
  'M' : <span style={{color: "#128E84"}}>Mandatory</span>,
  'O' : <span style={{color: "#1665D8"}}>Optional</span>,
  'C' : <span style={{color: "#E5891C"}}>Conditional</span>,
  null : <span style={{color: "#8A98Ac"}}>Not applicable</span>
}

export const PublicRouterUrl: any = [{ URL: "/" }, { URL: "/login" }];

export const enum SystemUnderTest {
  Acquirer = 'acquirer',
  Issuer = 'issuer',
}

export const enum TestMode {
  Client = 'client',
  Server = 'server',
}

export const enum SegmentValue {
  Disable = 'Disable',
  Default = 'Default',
  Enable = 'Enable',
  Overwrite = 'Overwrite'
}

export const enum SelectConstants {
  CustomValue = 'Custom value',
  SystemComputedValue = 'System computed value',
  DEFAULT = 'DEFAULT'
}