import { Form, Input, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IModal } from "../../Interface/Interfaces";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { checkUniqueAcqIdTerId } from "../../Redux/Actions/Actions";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import { capitalizeFirstLetter, generateSimulationName } from "../../Utils/Utils";
import { SystemUnderTest, TestMode } from "../../Constants/Constants";

const CreateEditProjectModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value, isUpdate  }) => {
  const dispatch = useDispatch()
  const [, contextHolder] = notification.useNotification();
  const [scheme, setScheme] = useState<any>({})
  const [testMode, setTestMode] = useState<any>({})
  const [systemUnderTest, setSystemUnderTest] = useState<any>({})
  const [versionOptions, setVersionOptions] = useState<any>({})
  const [schemeOptions, setSchemeOptions] = useState<any>([])
  const [testModeOptions, setTestModeOptions] = useState<any>([])
  const [systemUnderTestOptions, setSystemUnderTestOptions] = useState<any>([])
  const [form] = Form.useForm()
  const [projectNames, setProjectNames] = useState([''])
  const [hostIPLabel, setHostIPLabel] = useState('')
  const [uniqueCheckTriggered, setUniqueCheckTriggered] = useState(0)

  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  )

  const projectDtoList = useSelector(
    (state: any) => state.lithosApiReducer.projectDtoList
  )

  useEffect(() => {
    if(isUpdate){
      if(value?.systemUnderTest?.toLowerCase() === SystemUnderTest.Issuer){
        setHostIPLabel('Issuer IP')
      } else if(value?.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer){
        setHostIPLabel('Acquirer IP')
      }
    }
    const schemes = masterData?.map((S: any) => ({
      value: S.schemeName,
      label: S.schemeName.toUpperCase(),
    }));
    setSchemeOptions(schemes);
    if(schemes?.length === 1){
      setScheme(schemes[0]?.value)
      form.setFieldsValue({
        ProjectScheme: schemes[0]?.value
      });
    }
  }, []);

  useEffect(() => {
    projectDtoList?.map((project: any) => (
      project?.name !== "" && !projectNames.includes(project.name) && setProjectNames((projectNames) => [...projectNames, project.name.toLowerCase()])
    ))
  }, [])

  const handleFinish = async () => {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue()
      if(isUpdate){
        await form.validateFields()
        if (formValues.AcquirerID !== initialValues.AcquirerID || formValues.TerminalID !== initialValues.TerminalID) {
          setUniqueCheckTriggered(uniqueCheckTriggered + 1); 
          return;
        }
        onFinish(formValues);
      }
      else if(formValues?.SystemUnderTest === SystemUnderTest.Acquirer){
        const dto = {
          acquirerId: formValues.AcquirerID,
          terminalId: formValues.TerminalID,
          testMode: formValues.TestMode,
          systemUnderTest: formValues.SystemUnderTest,
        };
        await dispatch(checkUniqueAcqIdTerId(dto)).then((res: any) => {
          if (!res.payload) {
            form.setFields([
              {
                name: 'AcquirerID',
                errors: ['Acquirer & Terminal ID combination should be unique!'],
              },
              {
                name: 'TerminalID',
                errors: ['Acquirer & Terminal ID combination should be unique!'],
              },
            ]);
            return; 
          }
          onFinish(formValues);
        });  
      }
      else{
        onFinish(formValues);
      }
    });   
  }
  
  useEffect(() => {
    const selectedSchemeData = masterData?.find((data) => data.schemeName === scheme);
    if (selectedSchemeData) {
      const systemUnderTestList = selectedSchemeData?.systemUnderTestList?.map((system) => ({
        value: system.testEntity,
        label: capitalizeFirstLetter(system.testEntity),
      }));
      const versionList = selectedSchemeData?.libraries?.map((system) => ({
        value: system.version,
        label: system.version,
      }));
      setVersionOptions(versionList)
      setSystemUnderTestOptions(systemUnderTestList);
      if (systemUnderTestList?.length === 1) {
        setSystemUnderTest(systemUnderTestList[0]?.value);
        form.setFieldsValue({
          SystemUnderTest: systemUnderTestList[0]?.value
        });
      }
      if (versionList?.length === 1) {
        form.setFieldsValue({
          SpecificationVersion: versionList[0]?.value,
        });
      }
    }
  }, [scheme])

  useEffect(() => {
    const selectedSchemeData = masterData?.find((data) => data.schemeName === scheme);
    if (selectedSchemeData) {
      const testModeData = selectedSchemeData?.systemUnderTestList?.find((data) => data.testEntity.toLowerCase() === systemUnderTest.toLowerCase())
      const testModeList = testModeData?.testMode?.map((system) => ({
        value: system,
        label: generateSimulationName(system, systemUnderTest),
      }));
      setTestModeOptions(testModeList);
      if(testModeList?.length === 1){
        setTestMode(testModeList[0]?.value)
        form.setFieldsValue({
          TestMode: testModeList[0]?.value
        });
      }
    }
    if(systemUnderTest === SystemUnderTest.Issuer){
      setHostIPLabel('Issuer IP')
    } else if(systemUnderTest === SystemUnderTest.Acquirer){
      setHostIPLabel('Acquirer IP')
    }
  }, [systemUnderTest])

  useEffect(() => {
    if(uniqueCheckTriggered !== 0){
      const formValues = form.getFieldsValue()
      if(value.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer && formValues){
        const dto = {
          acquirerId: formValues?.AcquirerID,
          terminalId: formValues?.TerminalID,
          testMode: value.testMode,
          systemUnderTest: value.systemUnderTest,
        };
        dispatch(checkUniqueAcqIdTerId(dto)).then((res: any) => {
          if (!res.payload) {
            form.setFields([
              {
                name: 'AcquirerID',
                errors: ['Acquirer & Terminal ID combination should be unique!'],
              },
              {
                name: 'TerminalID',
                errors: ['Acquirer & Terminal ID combination should be unique!'],
              },
            ]);
            return; 
          }
          onFinish(formValues);
        });  
      }
      else{
        onFinish(formValues);
      }
    }
}, [uniqueCheckTriggered, form]);

  const initialValues = {
    Name: value?.name,
    Id: value?.id,
    IP: value?.ip,
    Port: value?.port,
    ProjectScheme: value?.projectScheme,
    SpecificationVersion: value?.specificationVersion,
    SystemUnderTest: value?.systemUnderTest,
    AcquirerID: value?.acquirerID,
    TerminalID: value?.terminalID,
    SourceIP: value?.sourceIP
  };
  
  const createFields = () => (
    <>
      <Form.Item
        className="modal-form"
        name="Name"
        rules={[
          { required: true, message: "Name cannot be empty" },
          {
            message: 'Project name already exists!',
            validator: (_, value) => {
              if (value !== "" && projectNames.includes(value.toLowerCase().trim())) {
                return Promise.reject(new Error('Project name already exists!'));
              } else {
                return Promise.resolve()
              }
            }
          }
        ]}
      >
        <FloatingLabelInput label='Name' placeholder="Name" />
      </Form.Item>
      <Form.Item
        className="modal-form"
        name="ProjectScheme"
        rules={[{ required: true, message: "Please select the scheme!" }]}
      >
        <FloatingLabelSelect
          label="Scheme"
          showSearch
          onChange={(e) => {          
            setScheme(e.toLowerCase());
          }}
          className="modal-input"
          optionFilterProp="children"
          options={schemeOptions}
        />
      </Form.Item>
      <Form.Item
        className="modal-form"
        name="SystemUnderTest"
        rules={[{ required: true, message: "Please select the test!" }]}
      >
        <FloatingLabelSelect
          label="System under test"
          onChange={(e) => {
            setSystemUnderTest(e.toLowerCase())
          }}
          className="modal-input"
          optionFilterProp="children"
          options={systemUnderTestOptions} 
        />
      </Form.Item>
      <Form.Item
        className="modal-form"
        name="SpecificationVersion"
        rules={[{ required: true, message: "Please select the version!" }]}
      >
        <FloatingLabelSelect
          label="Library version"
          className="modal-input"
          optionFilterProp="children"
          options={versionOptions?.length > 0 ? versionOptions : []}
        />
      </Form.Item>
      {testModeOptions?.length === 1 ? (
      <Form.Item
        className="modal-form"
        name="TestMode"
        hidden
      />
      ) : ( 
      <Form.Item
        className="modal-form"
        name="TestMode"
        rules={[{ required: true, message: "Please select a test mode!" }]}
      >
        <FloatingLabelSelect
          label="Simulation"
          onChange={(e: any) => {
            setTestMode(e.toLowerCase())
          }}
          className="modal-input"
          optionFilterProp="children"
          options={testModeOptions} 
        />
      </Form.Item>
      )}
      {testMode === TestMode.Client && (
        <>
          <Form.Item
            className="modal-form"
            name="IP"
            rules={[
              { required: true, message: "IP Address cannot be empty" },
              { pattern: /^(\d{1,3}\.){3}\d{1,3}$/, message: 'IP address format shall be 255.255.255.255' }    
            ]}
          >
            <FloatingLabelInput label='IP' placeholder="IP" />
          </Form.Item> 
          <Form.Item
            className="modal-form"
            name="Port"
            rules={[
              { required: true, message: "Port cannot be empty" },
              {
                message: 'Invalid port number',
                validator: (_, value) => {
                  if (value == 0 || (value > 1 && value <= 65535)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Invalid port'));
                  }
                }
              }
            ]}
          >
            <FloatingLabelInput label='Port' placeholder="Port" />
          </Form.Item>
        </>
      )}
      {testMode === 'server' && (
        <Form.Item
          className="modal-form"
          name="SourceIP"
          rules={[
            { required: true, message: "IP Address cannot be empty" },
            { pattern: /^(\d{1,3}\.){3}\d{1,3}$/, message: 'IP address format shall be 255.255.255.255' }    
          ]}
        >
          <FloatingLabelInput label={hostIPLabel} placeholder={hostIPLabel} />
        </Form.Item> 
      )}
      {systemUnderTest === SystemUnderTest.Acquirer && (
        <>
          <Form.Item
            className="modal-form"
            name="AcquirerID"
            rules={[
              { required: true, message: "Acquirer ID cannot be empty" },
              {
                message: 'Acquirer ID length shall be 6 to 11 digits!',
                validator: (_, value) => {
                  if (value !== "" && (value.length < 6 || value.length > 11)) {
                    return Promise.reject(new Error('Acquirer ID length shall be 6 to 11 digits!'));
                  } else {
                    return Promise.resolve();
                  }
                }
              },
              { pattern: /^[0-9A]+$/, message: "Acquirer ID should be in numeric format!" },
            ]}
          >
            <FloatingLabelInput label='Acquirer ID' placeholder="Acquirer ID" />
          </Form.Item>          
          <Form.Item
            className="modal-form"
            name="TerminalID"
            rules={[
              { required: true, message: "Terminal ID cannot be empty" },
              { len: 8, message: "Terminal ID should be 8 characters" },
              { pattern: /^[a-zA-Z0-9 _-]+$/, message: "Terminal ID should be Alphanumeric with special characters!" },
            ]}
          >
            <FloatingLabelInput label='Terminal ID' placeholder="Terminal ID" />
          </Form.Item>
        </>
      )}
    </>
  );
  
  const updateFields = () => (
    <>
      {value.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer && 
        <>
          <Form.Item
            className="modal-form"
            name="AcquirerID"
            rules={[
              { required: true, message: "Acquirer ID cannot be empty" },
              {
                message: 'Acquirer ID length shall be 6 to 11 digits!',
                validator: (_, value) => {
                  if (value !== "" && (value.length < 6 || value.length > 11)) {
                    return Promise.reject(new Error('Acquirer ID length shall be 6 to 11 digits!'));
                  } else {
                    return Promise.resolve();
                  }
                }
              },
              { pattern: /^[0-9A]+$/, message: "Acquirer ID should be in numeric format!" },
            ]}
          >
          <FloatingLabelInput label='Acquirer ID' placeholder="Acquirer ID" />
          </Form.Item>          
          <Form.Item
            className="modal-form"
            name="TerminalID"
            rules={[
              { required: true, message: "Terminal ID cannot be empty" },
              { len: 8, message: "Terminal ID should be 8 characters" },
              { pattern: /^[a-zA-Z0-9 _-]+$/, message: "Terminal ID should be Alphanumeric with special characters!" },
            ]}
          >
          <FloatingLabelInput label='Terminal ID' placeholder="Terminal ID" />
          </Form.Item>
    </>}
    {value.testMode?.toLowerCase() === 'server' && (
    <Form.Item
      className="modal-form"
      name="SourceIP"
      rules={[
        { required: true, message: "IP Address cannot be empty" },
        { pattern: /^(\d{1,3}\.){3}\d{1,3}$/, message: 'IP address format shall be 255.255.255.255' }        
      ]}
    >
      <FloatingLabelInput label={hostIPLabel} placeholder={hostIPLabel} />
    </Form.Item> 
    )}
    {value.systemUnderTest?.toLowerCase() === SystemUnderTest.Issuer && value.testMode?.toLowerCase() === TestMode.Client && (
    <>
      <Form.Item
        className="modal-form"
        name="IP"
        rules={[
          { required: true, message: "IP Address cannot be empty" },
          { pattern: /^(\d{1,3}\.){3}\d{1,3}$/, message: 'IP address format shall be 255.255.255.255' }
        ]}
      >
        <FloatingLabelInput label='IP' placeholder="IP" />
      </Form.Item> 
      <Form.Item
        className="modal-form"
        name="Port"
        rules={[
          { required: true, message: "Port cannot be empty" },
          {
            message: 'Invalid port number',
            validator: (_, value) => {
              if (value == 0 || (value > 1 && value <= 65535)) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Invalid port'));
              }
            }
          }
        ]}
      >
        <FloatingLabelInput label='Port' placeholder="Port" />
      </Form.Item>
    </>
    )}
  </>
)

  const renderFields = () => {
    if(isUpdate){
      return updateFields()
    }else{
      return createFields()
    }
  }

  return (
    <>
      {contextHolder}
      <Modal
        className="create-project-modal"
        centered
        title={isUpdate ? "Update Project" : "Create Project"}
        okText={isUpdate ? "Update" : "Create"}
        cancelText="Discard"
        open={isOpen}
        onOk={handleFinish}
        onCancel={()=>{onClose();form.resetFields()}}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container create-project-form"
          onFinish={handleFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          {renderFields()}
          <Form.Item hidden name="Id" >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateEditProjectModal;
