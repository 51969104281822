import { useEffect, useRef, useState } from "react";
import SubHeader from "../../Components/Header/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import GreenStatus from "../../Assets/GreenStatus.svg";
import RedStatus from "../../Assets/RedStatus.svg";
import UnknownStatus from "../../Assets/UnknownStatus.svg"
import PendingStatus from "../../Assets/PendingStatus.svg"
import HostLog from "../../Assets/HostLog.svg"
import ExecuteIcon from "../../Assets/Execute.svg"
import Configure from "../../Assets/Configure.svg"
import More from "../../Assets/More.svg"
import { BreadCrumbConfig, HeaderTitle } from "../../Redux/Reducers/UtilReducer";
import {
  CreateTestCase,
  SearchLogs,
  SelectCategoryTestCases,
  UpdateProjectTestCaseStatus,
  bulkExecuteTestCases,
  copyProjectTestcase,
  downloadLogs,
  executeTestCase,
  getTestCaseById,
  getTestCaseGroups,
  getTestPlanTestCasesByProjectId,
  importTestPlan,
  projectTestcaseDeleteById,
  updateTestCase,
} from "../../Redux/Actions/Actions";
import { ColumnsType } from "antd/es/table";
import MultiSelectionTableContainer from "../../Components/Table/MultiSelectionTableContainer";
import { CheckCircleTwoTone, FileSearchOutlined, FilterOutlined, LeftOutlined, RightOutlined, SearchOutlined, WarningTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Button, Col, Dropdown, Input, MenuProps, Pagination, PaginationProps, Row, Space, Tooltip, notification } from "antd";
import DeleteModal from "../../Components/DeleteModal/DeleteModal"
import Delete from "../../Assets/Delete.svg"
import Info from "../../Assets/TestCaseInfo.svg"
import Edit from "../../Assets/Edit.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import ImportExcel from "./ImportExcel";
import utcPlugin from 'dayjs/plugin/utc';
import TestCaseInfoModal from "./TestCaseInfoModal";
import TestCaseFilterModal from "./TestCaseFilterModal";
import { SchemeTag, SystemUnderTest } from "../../Constants/Constants";
import SearchLogsModal from "./SearchLogsModal";
import ProgressModal from "./ProgressModal";
import { ShowToaster } from "../../Components/Notifications/Notifications";
import CreateEditTestCaseModal from "./CreateEditTestCaseModal";
import { JsonParse } from "../../Utils/Utils";
import UseMyTestPlanModal from "./UseMyTestPlanModal";
import DuplicateTestCaseWarningModal from "./DuplicateTestCaseWarningModal";

dayjs.extend(utcPlugin);

export default function ProjectTestCase() {
  const [isCreate, setIsCreate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableLoading, setTableLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [isSearchLogs, setIsSearchLogs] = useState(false);
  const [isFilterColumns,setIsFilterColumns] = useState(false);
  const [testCaseGroups, setTestCaseGroups] = useState<any>();
  const [isCopy, setIsCopy] = useState(false)
  const [isExcelImport, setIsExcelImport] = useState(false)
  const [api, contextHolder] = notification.useNotification();
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  let projectId = localStorage.getItem("ProjectId");
  let testPlanId = localStorage.getItem("TestPlanId");
  let pageSize: number = process.env.REACT_APP_PAGE_SIZE;
  const [isDelete, setIsDelete] = useState(false)
  const [isDuplicateWarning, setIsDuplicateWarning] = useState(false)
  const [testCaseId, setTestCaseId] = useState('')
  const [testCaseNames, setTestCaseNames] = useState([''])
  const [testCaseCount, setTestCaseCount] = useState(0)
  const [searchedValue, setSearchedValue] = useState('')
  const [dynamicColumns,setDynamicColumns] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [testCaseData, setTestCaseData] = useState<any>()
  const [testCase, setTestCase] = useState<any>()
  const initialSelectionSet = useRef(false);
  const scrollContainerRef: any = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const localPageNo = localStorage.getItem('ProjectTestCasePageNo') ?? 1;
  const [pageNo, setPageNo] = useState(Number(localPageNo));
  const [totalPageNo, setTotalPageNo] = useState(0)
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const testCaseSUT = localStorage.getItem('TestCaseSUT')
  const [isUseMyTestCase, setIsUseMyTestCase] = useState(false)
  const [duplicateIds, setDuplicateIds] = useState<any>()
  const [scheme, setScheme] = useState()
  const [renderData, setRenderData] = useState(false)
  const [containDuplicate, setContainDuplicate] = useState(false)

  const userType = useSelector((state: any) => state.lithosApiReducer.userType);

  const notify = useSelector((state: any) => state.lithosApiReducer.notification);

  const handleSelectedRows = (rowKeys: React.Key[]) => {
    setSelectedRowKeys(rowKeys);
  };

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;

    if (!container) return;

    const scrollDistance = 200;

    if (direction === "prev") {
      container.scrollBy({
        left: -scrollDistance,
        behavior: "smooth",
      });
    } else if (direction === "next") {
      container.scrollBy({
        left: scrollDistance,
        behavior: "smooth",
      });
    }
  };

  const isScrollable =
  scrollContainerRef.current &&
  scrollContainerRef.current.scrollWidth >
    scrollContainerRef.current.clientWidth;

  useEffect(() => {
    dispatch(
      BreadCrumbConfig({
        title: "Test cases",
        href: "/testcases",
        preventPush: false,
      } as any)
    );
    RenderHandler()
  }, []);

  useEffect(() => {
    dispatch(HeaderTitle(`Test cases : ${testCaseCount}`));
  }, [testCaseCount, dispatch])

  useEffect(() => {
    const category = localStorage.getItem('SelectedProjectCard')
    category && SelectCategory(category)
  }, [pageNo, searchedValue, isExecuting, isUpdate, isCopy, isSearchLogs, renderData])

  useEffect(() => {
    if(notify != null && notify?.identifier !== null){
      let pairs = notify?.identifier.split(';');
      let notifyIdentifiers: {[key: string]: string} = {};

      pairs.forEach(pair => {
          let [key, value] = pair.split(':');
          notifyIdentifiers[key] = value;
      });

      if(projectId === notifyIdentifiers?.ProjectId && testPlanId === notifyIdentifiers?.TestPlanId){
        localStorage.setItem('SelectedProjectCard', notifyIdentifiers?.Category)
        if(pageNo === Number.parseInt(notifyIdentifiers?.PageNo)){
          setRenderData(!renderData)
        }
        else{
          setPageNo(Number.parseInt(notifyIdentifiers?.PageNo))
        }
      }
    }
  }, [notify])

  const OnClickEvent = (value: any) => {
    if (value === "Import") {
      setIsExcelImport(true)
    } else if(value === "New"){
      setIsCreate(true)
    } else if(value === 'BulkExecute'){
      BulkExecute()
    } else if(value === 'DownloadLogs'){
      DownloadLogs()
    } else if(value === 'Use-my-test-case'){
      const dto = {
        userId: userDetails?.Id,
        testPlanId: testPlanId,
        projectId: projectId
      }
      dispatch(getTestPlanTestCasesByProjectId(dto)).then((res: any) => {        
        setScheme(Object.values(res.payload)?.[0]?.[0]?.scheme);
        setIsUseMyTestCase(true);
      })
    } 
  }

  const oncloseModal = () => {
    setIsCreate(false)
    setIsUpdate(false)
    setIsExecuting(false)
    setIsDelete(false)
    setIsCopy(false)
    setIsExcelImport(false)
    setIsInfo(false)
    setIsFilterColumns(false)
    setIsSearchLogs(false)
    setIsUseMyTestCase(false)
    setIsDuplicateWarning(false)
  };

  const onChangeSearch = (e) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        setPageNo(1)
        setSearchedValue(e.target.value);
      }, 1200)
    );
  };

  const SelectCategory = async (groupName: any) => {
    setTableLoading(true)
    await dispatch(SelectCategoryTestCases({
      category: groupName,
      testPlanId: testPlanId,
      projectId: projectId,
      pageNo: pageNo,
      searchedValue: searchedValue,
      pageSize: pageSize
    })).then((data: any) => {
      setTestCaseData(data?.payload?.testCaseList)
      let containDuplicate = false;
      for (let record of data?.payload?.testCaseList) {
        if (record.isDuplicate) {
          containDuplicate = true;
          break;
        }
      }
      setContainDuplicate(containDuplicate)
      setTotalPageNo(data?.payload?.totalPage)
      data?.payload?.testCaseList?.map((testCase: any) => (
        testCase?.name !== "" && !testCaseNames?.includes(testCase.name) && setTestCaseNames((testCaseNames: any) => [...testCaseNames, testCase.name.toLowerCase()])
      ))
      setTableLoading(false)
    })
    localStorage.setItem('SelectedProjectCard', groupName);
    localStorage.setItem('ProjectTestCasePageNo', pageNo.toString());
  }

  const RenderHandler = () => {
    let selectedCard = localStorage.getItem('SelectedProjectCard')
    dispatch(getTestCaseGroups({
      projectId : projectId,
      testPlanName : localStorage.getItem("TestPlanName"),
    })).then((D: any) => {
      if(D?.payload?.length === 0){
        navigate(-1)
      }
      else{
        setTestCaseGroups(D?.payload);
        setTestCaseCount(D?.payload?.reduce((total, group) => total + group.total, 0) ?? 0)
        setTableLoading(false); 
      if(selectedCard === '' || !D?.payload?.find((item) => item.name === selectedCard)){
        setPageNo(1)
        SelectCategory(D?.payload[0]?.name)
      }
      else{   
        SelectCategory(selectedCard)
      }
      }
    });
  };

  const onFinish = (values: any) => {
    setTableLoading(true);
    if(isUseMyTestCase){
      const request = {
        testCaseIdList : values,
        scheme : scheme,
        projectId : projectId,
        isOverwrite : false
      }
      dispatch(importTestPlan(request)).then((res)=>{
        if(res?.payload?.responseCode === 'OK'){
          ShowToaster(res.payload, api)
          setIsUseMyTestCase(false)
          setTableLoading(false)
        }
        else if(res?.payload?.responseCode === 'Processing'){
          setIsUseMyTestCase(false)
          setDuplicateIds(res?.payload?.responseMessage)
          setIsDuplicateWarning(true)
        }
        setRenderData(!renderData)
      })
    }
    if(isDuplicateWarning){
      const request = {
        testCaseIdList : values,
        scheme : scheme,
        projectId : projectId,
        isOverwrite : true
      }
      dispatch(importTestPlan(request)).then((res)=>{
        ShowToaster(res.payload, api)
        setIsDuplicateWarning(false)
        setTableLoading(false)
        setRenderData(!renderData)
      })
    }
    if (isCreate === true) {
      values.TestPlanId = testPlanId;
      values.ProjectID = projectId;
      dispatch(CreateTestCase(values)).then((res :any) => {
        RenderHandler()
        setIsCreate(false)
        ShowToaster(res.payload, api)
        setTableLoading(false)
      });
    } else if(isUpdate === true) {
      values.TestPlanId = testPlanId;
      values.ProjectID = projectId;
      dispatch(updateTestCase(values)).then((res :any) => {
        setIsUpdate(false)
        ShowToaster(res.payload, api)
        setTableLoading(false)
      });
    } else if(isDelete === true){
      dispatch(projectTestcaseDeleteById(testCaseId)).then((res : any) => {
        RenderHandler()
        setIsDelete(false)
        ShowToaster(res.payload, api)
        setTableLoading(false)
      });
    } else if(isCopy === true){
      dispatch(copyProjectTestcase({testCaseId, values, projectId})).then((res : any) => {
        setIsCopy(false)
        ShowToaster(res.payload, api)
        setTableLoading(false)
      });
    } else if(isSearchLogs === true){
      dispatch(SearchLogs({testCaseId, values})).then((res : any) => {
        setIsSearchLogs(false)
        ShowToaster(res.payload, api)
        setTableLoading(false)
      });
    }
  };

  const getHostLogOnClick = (testCaseId, testcaseName, testCaseResult) => {
    dispatch(getTestCaseById(testCaseId)).then((res: any) => {
      navigate({
      pathname: "analyzelogs",
      search: `?testcaseName=${testcaseName}&testCaseResult=${testCaseResult}&testCaseId=${testCaseId}`,
    })})
  }

  const Execute = async (testCaseId) => {
    setIsExecuting(true);
    await dispatch(executeTestCase(testCaseId)).then((res: any) => {
      ShowToaster(res.payload, api)
    }).catch(() => {
      SelectCategory(localStorage.getItem('SelectedProjectCard'))
      api.error({
        message: `Something went wrong, please try again`,
        placement: 'top',
        style: {
          width: 500,
        },
        icon: <WarningTwoTone twoToneColor={'#D13F3F'}/>,
      })  
      setIsExecuting(false)
    });
    setPageNo(Number(localStorage.getItem('ProjectTestCasePageNo')))
    dispatch(getTestCaseGroups({
      projectId : projectId,
      testPlanName : localStorage.getItem("TestPlanName"),
    })).then((D: any) => {
      setTestCaseGroups(D?.payload);      
    })    
    setIsExecuting(false)
  }

  const BulkExecute = async() => {
    setIsExecuting(true);
    await dispatch(bulkExecuteTestCases(selectedRowKeys)).then((res: any) => {
      ShowToaster(res.payload, api)
    }).catch(() => {
      SelectCategory(localStorage.getItem('SelectedProjectCard'))
      api.error({
        message: `Something went wrong, please try again`,
        placement: 'top',
        style: {
          width: 500,
        },
        icon: <WarningTwoTone twoToneColor={'#D13F3F'}/>,
      })
      setIsExecuting(false)
    });
    setPageNo(Number(localStorage.getItem('ProjectTestCasePageNo')))
    setSelectedRowKeys([]);
    dispatch(getTestCaseGroups({
      projectId : projectId,
      testPlanName : localStorage.getItem("TestPlanName"),
    })).then((D: any) => {
      setTestCaseGroups(D?.payload);      
    }) 
    setIsExecuting(false)
  }

  const SetActiveTestCase = async (testCaseId: string) => {
    await dispatch(UpdateProjectTestCaseStatus({
      projectId: projectId,
      testCaseId: testCaseId,
    })).then((res) => {
      ShowToaster(res.payload, api)
      setRenderData(!renderData)
    })
  }

  const DownloadLogs = async () => {
    const responseArrayBuffer = await dispatch(downloadLogs(selectedRowKeys));
    if(responseArrayBuffer?.payload?.error){
      api.error({
        message: `Something went wrong, please try again later`,
        placement: 'top',
        style: {
          width: 500,
        },
        icon: <WarningTwoTone twoToneColor={'#D13F3F'}/>,
      })
    } else {
      const blob = new Blob([responseArrayBuffer?.payload], { type: 'application/x-zip-compressed' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'TransactionLogs.zip';
      a.click();
      window.URL.revokeObjectURL(url);
      api.success({
        message: `Logs downloaded successfully`,
        placement: "top",
        style: {
          width: 500,
        },
        icon: <CheckCircleTwoTone twoToneColor={'#8BAB58'}/>
      })
    }
  }

  const items: MenuProps["items"] = [
    {
      key: '2',
      label:  (
        <button className="menu-content no-border-bg-transparent" onClick={() => setIsDelete(true)}>
          <img className="icons" src={Delete} alt="delete icon"/> <span className="info-content">Delete</span>
      </button>
      ),
    }
  ];

  const getStatusImage = (text: string) => {
    let statusImage: JSX.Element;
    if (text === "PASS") {
      statusImage = <img className="test-case-status" src={GreenStatus} alt="Pass" />;
    } else if (text === "UNKNOWN") {
      statusImage = <img className="test-case-status" src={UnknownStatus} alt="Unknown" />;
    } else if (text === "PENDING") {
      statusImage = <img className="test-case-status" src={PendingStatus} alt="Pending" />;
    } else {
      statusImage = <img className="test-case-status" src={RedStatus} alt="Fail" />;
    }
    return statusImage;
  };

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: 'left',
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name); 
      }, 
      render: (text, record : any) => (
        <div className="project-content" key={record.id + record.name} style={{color : record.isDuplicate && '#f25e5e'}}>
          {text}
        </div>
      ),
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {},
        };
      },
    },
    {
      title: "Library",
      dataIndex: "version",
      width: 100,
      key: "version",
      render: (text, record : any) => <div className="project-content" key={record.id + record.version}>{text}</div>,
    },
    {
      title: "Scheme",
      dataIndex: "scheme",
      width: 100,
      key: "scheme",
      render: (text, record: any) => {
        const tagInfo = SchemeTag[text]; 
        if (tagInfo) {
          return (
            <div className="project-content" key={record.id + record.scheme}>
              {tagInfo}
            </div>
          );
        }
        return (
          <div className="project-content" key={record.id + record.scheme}>
            {text}
          </div>
        );
      },
    },
    {
      title: "MTI",
      dataIndex: "mti",
      width: 100,
      key: "mti",
      render: (text, record : any) => <div className="project-content" key={record.id + record.mti}>{text}</div>,
    },
    {
      title: "Executed at (UTC)",
      dataIndex: "lastExecutionTime",
      width: 200,
      key: "lastExecutionTime",
      render: (text, record : any) => (
        <div className="project-content" key={record.id + record.lastExecutionTime}>
          {text && <><span style={{marginRight:'6px'}}>{dayjs.utc(text).format("MMM DD,YYYY")}</span> 
          <span style={{fontSize:'12px', color:'#727272', margin:'0 !important'}}>{dayjs.utc(text).format("h:mm:ss A")}</span></>}
        </div>
      ),
    },
    {
      title: "Field 2",
      dataIndex:  ["fields", "002"],
      key: "field2",
      width: 160,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field2}>{text}</div>,
    },
    {
      title: "Field 3",
      dataIndex:  ["fields", "003"],
      key: "field3",
      width: 100,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field3}>{text}</div>,
    },
    {
      title: "Field 4",
      dataIndex:  ["fields", "004"],
      key: "field4",
      width: 140,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field4}>{text}</div>,
    },
    {
      title: "Field 11",
      dataIndex:  ["fields", "011"],
      key: "field11",
      width: 100,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field11}>{text}</div>,
    },
    {
      title: "Field 14",
      dataIndex:  ["fields", "014"],
      key: "field14",
      width: 100,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field14}>{text}</div>,
    },
    {
      title: "Field 18",
      dataIndex:  ["fields", "018"],
      key: "field18",
      width: 100,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field18}>{text}</div>,
    },
    {
      title: "Field 19",
      dataIndex:  ["fields", "019"],
      key: "field19",
      width: 100,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field19}>{text}</div>,
    },
    {
      title: "Field 22",
      dataIndex:  ["fields", "022"],
      key: "field22",
      width: 150,
      render: (text, record : any) => <div className="project-content" key={record.id + record.field22}>{text}</div>,
    },
    {
      title: "Field 24",
      dataIndex:  ["fields", "024"],
      key: "field24",
      width: 100,
      render: (text) => <div className="project-content">{text}</div>,
    },
    {
      title: "Field 39",
      dataIndex:  ["fields", "039"],
      key: "field39",
      width: 100,
      render: (text) => <div className="project-content">{text}</div>,
    },
    {
      title: "Field 48",
      dataIndex:  ["fields", "048"],
      key: "field48",
      width: 100,
      render: (text) => <div className="project-content">{text}</div>,
    },
    {
      title: "Field 61",
      dataIndex:  ["fields", "061"],
      key: "field61",
      width: 100,
      render: (text) => <div className="project-content">{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "result",
      key: "status",
      width: 80,
      fixed: 'right',
      render: (text, record: any) => (
        <Tooltip title={text}>
          <div className="project-content">
            {getStatusImage(text)}
          </div>
      </Tooltip>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      fixed: 'right',
      dataIndex: "actions",
      render: (text: any, record: any) => (
        <div className="icon-container" onClick={(e) => e.stopPropagation()}>
          <Tooltip title='Configure'>
            <button className="project-content no-border-bg-transparent" onClick={() => {
              dispatch(getTestCaseById(record.id)).then(() => {
                navigate({
                  pathname: "configuration",
                  search: `?scheme=${record.scheme}&testCaseId=${record.id}&isSchemeSpecific=${false}`,
                  })
                })
              }}>
                <img className="icons" src={Configure} alt="Configure" />
            </button>
          </Tooltip>
        {record?.result !== 'UNKNOWN' ? (
          <Tooltip title='Analyze logs'><button className="project-content no-border-bg-transparent" onClick={() => {getHostLogOnClick(record?.id, record?.name, record?.result)}}>
            <img className="icons" src={HostLog} alt="Host Log" />
          </button></Tooltip>
        ) : <div className="project-content" style={{marginLeft: '18px'}}></div> }
        {!record?.isExecutable  ? (
          <Tooltip title='Search logs'><button className="project-content icons no-border-bg-transparent" onClick={() => {
            setTestCaseId(record?.id);
            setIsSearchLogs(true);
          }}>
            <FileSearchOutlined style={{ fontSize: '20px', color: '#036DAD', fontWeight: '600' }} />
          </button></Tooltip>
        ) : record?.isConfigured ? (
          <Tooltip title='Execute'><button className="project-content no-border-bg-transparent" onClick={() => Execute(record?.id)}>
            <img className="icons" src={ExecuteIcon} alt="Execute" />
          </button></Tooltip>
        ) : (
          <div className="project-content" style={{ marginLeft: '18px' }}></div>
        )}
        <button className="project-content no-border-bg-transparent"
            onClick={() => {
              setTestCase(record)
              setIsInfo(true)
            }}
        >
          <Tooltip title='Info'><img className="icons" src={Info} alt="Info" style={{marginTop: '-2.4px'}}/></Tooltip>
        </button>
        <Tooltip title='More'>
          <button className="project-content no-border-bg-transparent"
            onClick={() => {
                setTestCaseId(record.id)
                setTestCase(record)
                const index = items?.findIndex(item => item?.key === '1');
                // adding the edit option for acquirer testcases to change the card and merchant profile
                if(testCaseSUT === 'issuer'){
                  if(index === -1) {
                      items.splice(2 , 0, {
                          key: '1',
                          label: (
                              <button className="menu-content no-border-bg-transparent" onClick={() => setIsUpdate(true)}>
                                  <img className="icons" src={Edit} alt="edit icon"/> <span className="info-content">Edit</span>
                              </button>
                          ),
                      });
                  }
                }
                else{
                    if(index !== -1) {
                        items.splice(index, 1);
                    }
                }
              }}>
            <Dropdown
              trigger={['click']}
              menu={{
                items,
              }}
            >
              <Space>
                <img className="icons" src={More} alt="More"/>
              </Space>
            </Dropdown>
          </button>
        </Tooltip>
      </div>
      )},
  ];

  useEffect(() => {
    if(userType?.toLowerCase() === 'admin' || userType?.toLowerCase() === 'manager'){
      const actionRemoved = columns.filter((col: any) => {
        return col.key !== 'actions'
      })
      setDynamicColumns(actionRemoved)
    }
  }, [userType, columns])

  const onChangeFilter = (val:any) => {
    if (selectedKeys.includes(val)) {
      setSelectedKeys((prevKeys:any) => prevKeys.filter((k:any) => k !== val));
    } else {
      setSelectedKeys([...selectedKeys, val]);
    }
  } 

  useEffect(() => {
    if (!initialSelectionSet.current) {
      setDynamicColumns(columns);
      columns?.map((C: any) => setSelectedKeys((pre: any) => [...pre, C.key]));
    }
    initialSelectionSet.current = true;
    const filteredColumns = columns?.filter((column:any) => {
      return selectedKeys.includes(column.key);
    });
    setDynamicColumns(filteredColumns);
  }, [selectedKeys]);

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPageNo(page)
    setSelectedRowKeys([]);
  };

  const rowClassName = (record: any) => {
    if (record?.isActive) {
      return 'active-testcase-row';
    } 
    return '';
  };

  const onRowClick = (record: any, rowIndex) => {
    if(testCaseSUT === SystemUnderTest.Acquirer && record.isDuplicate){
      return {
        onClick: event => { 
          SetActiveTestCase(record?.id)
        }, 
      };
    } 
  }

  const testCaseValue: any = testCaseData;

  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={selectedRowKeys.length > 0} SelectedIds={selectedRowKeys} />
      <div className="test-case-card-container">
      {isScrollable && (<div onClick={() => handleScroll("prev")} className="scroll-button left">
      <LeftOutlined />
        </div>)}
        <div className="test-case-card-container" ref={scrollContainerRef}>
          {testCaseGroups?.map((T: any, index: any) => {
            return (
              <div
                onClick={() => {
                    setPageNo(1)
                    SelectCategory(T.name)
                }}
                className={
                  T.name === localStorage.getItem('SelectedProjectCard') 
                    ? "test-case-card content active"
                    : "test-case-card content"
                }
                key={T.name}
              >
                <div className="mti-value">{T.name}</div>
                <div className="total-value">Total : {T.total}</div>
                <div className="result-count">
                  <div className="executed-count hw-20">{T.pass}</div>
                  <div className="failed-count hw-20">{T.fail}</div>
                  <div className="not-executed hw-20">{T.unknown}</div>
                </div>
              </div>
            );
          })}
        </div>
        {isScrollable && (<div onClick={() => handleScroll("next")} className="scroll-button right">
          <RightOutlined />
        </div>)}
      </div>
      <div className="searchbar-container">
      <Input size="large" className='search-bar' placeholder="Search" suffix ={<SearchOutlined />} onChange={onChangeSearch}/>
      <Tooltip title='Filter columns'><Button onClick={()=> setIsFilterColumns(true)} className="column-btn"><FilterOutlined /></Button></Tooltip>
      </div>
      <MultiSelectionTableContainer
        tableData={testCaseValue}
        column={dynamicColumns}
        className={"test-case-table"}
        loading={testCaseValue === undefined ? true : tableLoading}
        pagination={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowsChange={handleSelectedRows}
        scrollY='65vh'
        scrollX={1200}
        hideCheckbox={userType?.toLowerCase() === 'admin'}
        rowClassName={rowClassName}
        onRow={onRowClick}
      />
      <Row gutter={[0, 8]}>
        <Col span={12}>
          {(testCaseSUT === SystemUnderTest.Acquirer && containDuplicate) && <span style={{color: "#838383"}}>
            The test case(s) displayed in <span style={{color: '#f25e5e', fontWeight: 600}}>RED</span> contains duplicate PAN and Amount, Please click on any one of the test case to set as active.
          </span>}
        </Col>
        <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Pagination 
            current={pageNo}
            onChange={onPageChange} 
            total={totalPageNo * pageSize}
            pageSize={pageSize}
            showSizeChanger={false}/>
        </Col>
      </Row> 
      {isCreate && <CreateEditTestCaseModal
          isOpen={isCreate}
          onClose={oncloseModal}
          onFinish={onFinish}
          isUpdate={false}
        />}
      {isUpdate && <CreateEditTestCaseModal
          isOpen={isUpdate}
          onClose={oncloseModal}
          onFinish={onFinish}
          value={testCase}
          isUpdate={true}
        />
      }
      {isExecuting &&<ProgressModal
        isOpen={isExecuting}
        onClose={oncloseModal}
        onFinish={onFinish}
      />}
      {isDelete &&<DeleteModal
        isOpen={isDelete}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Testcase" 
        values={undefined}
      />}
      {isCopy && <CopyModal
        isOpen={isCopy}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Testcase"
        values={testCaseNames}
      />}
      {isExcelImport &&<ImportExcel
        isOpen={isExcelImport}
        onClose={oncloseModal}
        onFinish={onFinish}
      />}
      {isInfo && <TestCaseInfoModal
        isOpen={isInfo}
        onClose={oncloseModal}
        onFinish={onFinish}
        value={testCase}
      />}
      {isSearchLogs && <SearchLogsModal
        isOpen={isSearchLogs}
        onClose={oncloseModal}
        onFinish={onFinish}
      />}
      {isFilterColumns && <TestCaseFilterModal
        isOpen={isFilterColumns}
        onClose={oncloseModal}
        onChange={onChangeFilter}
        columns={columns}
        selectedColumns={selectedKeys}
      />}
      {isUseMyTestCase && <UseMyTestPlanModal
        isOpen={isUseMyTestCase}
        onClose={oncloseModal}
        onFinish={onFinish}
      />}
      {isDuplicateWarning && <DuplicateTestCaseWarningModal
        isOpen={isDuplicateWarning}
        onClose={oncloseModal}
        onFinish={onFinish}
        value={duplicateIds}
      />}
    </>
  );
}
