import { Col, Row, Typography } from 'antd';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PieChart from '../../Components/Charts/PieChart';
import SubHeader from '../../Components/Header/SubHeader'
import { BreadCrumbConfig, HeaderTitle } from '../../Redux/Reducers/UtilReducer';
import { JsonParse } from '../../Utils/Utils';
import { getDashboardData } from '../../Redux/Actions/Actions';
const {Text} = Typography;

export default function Dashboard() {
  const dispatch = useDispatch();
  const userDetails = JsonParse(localStorage.getItem('UserDetails'));
  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  const [dashboardData, setDashboardData] = useState({
    projects: 0,
    schemes: 0,
    manager: 0,
    tester: 0,
  });

  useEffect(() => {
    dispatch(HeaderTitle('Dashboard'));
    dispatch(
      BreadCrumbConfig({
        title: "Dashboard",
        href: '/dashboard',
        preventPush: true,
      } as any)
    );
    dispatch(getDashboardData(userDetails?.Id))
      .then((res) => {
        setDashboardData({
          projects: res?.payload?.noOfProjects || 0,
          schemes: res?.payload?.noOfSchemes || 0,
          manager: res?.payload?.managerCount || 0,
          tester: res?.payload?.testerCount || 0,
        });
      })
  }, [userDetails?.Id]);

  const { projects, schemes, manager, tester } = dashboardData;

  const ProjectsChartData = [
    {
      status: "Projects",
      value: projects,
    },
    {
      status: "Schemes",
      value: schemes,
    }
  ];

  const AdminChartData = [
    {
      status: "Manager",
      value: manager,
    },
    {
      status: "Tester",
      value: tester,
    }
  ];

  const ManagerChartData = [
    {
      status: "Tester",
      value: tester,
    }
  ]

  return (
    <>
      <SubHeader OnClickEvent={() => {}} IsSelectAll={false} SelectedIds={null}  />
      <div className="dashboard-container">
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="project-piechart-container">
              <div className="piechart-wrapper">
                <Text className="chart-title">Projects</Text>
              </div>
              <div className="chart-wrapper">
                <Text className="total-count">
                  Total Projects<span className="content-count">{projects}</span>
                </Text>
                <PieChart chartData={ProjectsChartData} />
              </div>
            </div>
          </Col>
          {userType === 'ADMIN' && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="project-piechart-container">
                <div className="piechart-wrapper">
                  <Text className="chart-title">User details</Text>
                </div>
                <div className="chart-wrapper">
                <Text className="total-count">
                  Total managers <span className="content-count">{manager}</span>{' '}
                  Total testers <span className="content-count">{tester}</span>
                </Text>
                  <PieChart chartData={AdminChartData} />
                </div>
              </div>
            </Col>
          )}
          {userType === 'MANAGER' && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="project-piechart-container">
                <div className="piechart-wrapper">
                  <Text className="chart-title">User details</Text>
                </div>
                <div className="chart-wrapper">
                  <Text className="total-count">
                    Total testers<span className="content-count">{tester}</span>
                  </Text>
                  <PieChart chartData={ManagerChartData} />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  )
}
