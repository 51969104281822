import { useNavigate } from "react-router-dom";

const SessionTimeOut = () => {
const navigate = useNavigate();
  return (
    <div className="timeout-container">
        <div className="timeout">
          <h2>Your session has timed out</h2>
          <h4><a onClick={() => navigate("/login")} className="cursor-pointer"><b><u>Click here</u></b></a> to login again.</h4>
        </div>
    </div>
  );
};

export default SessionTimeOut;
