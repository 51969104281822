import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import TableContainer from "../../Components/Table/TableContainer";
import SubHeader from "../../Components/Header/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbConfig, HeaderTitle } from "../../Redux/Reducers/UtilReducer";
import CreateEditProjectModal from "./CreateEditProjectModal";
import {
  CopyProject,
  CreateProject,
  getProjectsByUserId,
  deleteProject,
  projectUpdate,
} from "../../Redux/Actions/Actions";
import { JsonParse } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { Input, Space, Tooltip, notification } from "antd";
import dayjs from "dayjs";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import Edit from "../../Assets/Edit.svg"
import Delete from "../../Assets/Delete.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import { SearchOutlined } from "@ant-design/icons";
import { SchemeTag } from "../../Constants/Constants";
import { ShowToaster } from "../../Components/Notifications/Notifications";

export default function Projects() {
  const [isCreate, setIsCreate] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState(useSelector(
    (state: any) => state.lithosApiReducer.projectDtoList
  ));
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isDelete, setIsDelete] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  const [projectId, setProjectId] = useState('')
  const [project, setProject] = useState<any>()
  const [projectNames, setProjectNames] = useState([''])
  const [searchedText, setSearchedText] = useState('')

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  useEffect(() => {
    localStorage.setItem("ProjectId", '');
    dispatch(HeaderTitle("Projects"));
    dispatch(
      BreadCrumbConfig({
        title: "Projects",
        href: "/projects",
        preventPush: true,
      } as any)
    );
    RenderHandler()
  }, [projectId]);

  const RenderHandler = () => {
    setLoading(true);
    dispatch(getProjectsByUserId(userDetails?.Id)).then((data: any) => {
      setLoading(false);
      setProjectData(data.payload?.projectList)
      data.payload?.projectList.map((project: any) => (
        project?.name !== "" && !projectNames.includes(project.name) && setProjectNames((projectNames) => [...projectNames, project.name.toLowerCase()])
      ))
    });
  };

  const oncloseModal = () => {
    setIsCreate(false);
    setIsUpdate(false);
    setIsDelete(false);
    setIsCopy(false)
  };

const onFinish = async (values: any) => {
  setLoading(true);
  if (isCreate === true) {
    try {
      const createResult = await dispatch(CreateProject(values));
      ShowToaster(createResult.payload, api)
      setIsCreate(false);
      setProjectData([createResult?.payload?.project, ...projectData])
    } catch (error) {
      console.error("Error during create:", error);
    }
  } else if (isUpdate === true) {
    try {
      const updateResult = await dispatch(projectUpdate(values));
      ShowToaster(updateResult.payload, api)
      setIsUpdate(false);
      const updatedProjects = projectData.map(prj => {
        if (prj.id === updateResult?.payload?.project?.id) {
          return updateResult?.payload?.project;
        } else {
          return prj;
        }
      });
      setProjectData(updatedProjects);
      setProject(updateResult?.payload?.project)
    } catch (error) {
      console.error("Error during update:", error);
    }
  } else if (isDelete === true) {
    try {
      const deleteResult = await dispatch(deleteProject(projectId));
      ShowToaster(deleteResult.payload, api)
      setIsDelete(false);
      setProjectData(project =>
        project.filter(prj => {
          return prj.id !== projectId;
        }),
      );
      setProjectNames(names => 
        names.filter(name => {
          return name != project?.name;
      }))
    } catch (error) {
      console.error("Error during delete:", error);
    }
  } else if (isCopy === true) {
    try {
      const copyResult = await dispatch(CopyProject({ values, projectId }));
      ShowToaster(copyResult.payload, api)
      setIsCopy(false);
      setProjectData([copyResult?.payload?.project, ...projectData])
    } catch (error) {
      console.error("Error during copy:", error);
    }
  }
  setLoading(false);
};

  const OnClickEvent = (value: any) => {
    setIsCreate(true);
  }

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record?.name).toLowerCase().includes(value.toLowerCase())
      },
      sorter: (a: any, b: any) => {
        return a?.name.localeCompare(b?.name);
      },
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer", color:"#036DAD" }}>
          {text}
        </div>
      ),
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {
            localStorage.setItem("ProjectId", record?.id);
            navigate({
              pathname: "/projectdetails",
              search: `?projectId=${record?.id}`,
            });
          },
        };
      },
    },
    {
      title: "Last action at",
      dataIndex: "lastAccessedAt",
      key: "lastAccessedAt",
      render: (text, record : any) => (
        <div className="project-content" key={record.id + record.lastExecutionTime}>
          {text && <><span style={{marginRight:'6px'}}>{dayjs.utc(text).format("MMM DD,YYYY")}</span> 
          <span style={{fontSize:'12px', color:'#727272', margin:'0 !important'}}>{dayjs.utc(text).format("h:mm:ss A")}</span></>}
        </div>
      ),
      sorter:  (a :any, b :any) => {
        const dateA = new Date(a.lastAccessedAt);
        const dateB = new Date(b.lastAccessedAt);
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      title: "Scheme",
      dataIndex: "projectScheme",
      key: "projectScheme",
      render: (text, record: any) => {
        const tagInfo = SchemeTag[text]; 
        if (tagInfo) {
          return (
            <div className="project-content" key={record.id + record.scheme}>
              {tagInfo}
            </div>
          );
        }
        return (
          <div className="project-content" key={record.id + record.scheme}>
            {text}
          </div>
        );
      },
      sorter: (a:any, b:any) => {
        return a.projectScheme.localeCompare(b.projectScheme);
      },
    },
    {
      title: "Scheme specification",
      dataIndex: "specificationVersion",
      key: "specificationVersion",
      render: (text) => <div className="project-content">{text}</div>,
      sorter: (a: any, b: any) => a.specificationVersion - b.specificationVersion,
    },
    {
      ...(userType !== 'TESTER'
          ? {
              title: "Owner",
              key: "owner",
              dataIndex:  ["owner", "email"],
              render: (text) => <div className="project-content">{text}</div>,
              sorter: (a: any, b: any) => a.owner - b.owner,
          }
          : null),
    },
    {...(userType === 'TESTER' 
    ? {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text: any, record: any) => (
          <>
          {userType === "TESTER" &&
          <div className="combined-icon" onClick={() => setProject(record)}>
          <Space size={"large"}>
            <button
              onClick={() => {
                  setProject(record)
                  setIsUpdate(true)
              }}
              className="project-content no-border-bg-transparent"
            >
              <Tooltip title="Edit">
                <img className="icons" src={Edit} alt="edit icon"/>
              </Tooltip>
            </button>
            <button
              onClick={() => {
                setIsDelete(true);
                setProjectId(record.id)
              }}
              className="project-content no-border-bg-transparent"
            >
              <Tooltip title="Delete">
                <img className="icons" src={Delete} alt="delete icon" />
              </Tooltip>
            </button>
          </Space>
        </div>}
        </>
        ),
      } : null),
    },
  ];
  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {
              const currValue = e.target.value;
              setSearchedText(currValue)
            }}/>
      <TableContainer
        tableData={projectData}
        column={columns}
        className={"project-table"}
        loading={loading}
        scrollY='65vh'
      />
      {isCreate && <CreateEditProjectModal
        isOpen={isCreate}
        onClose={oncloseModal}
        onFinish={onFinish}
        isUpdate={false}
      />}
      {isUpdate && <CreateEditProjectModal
        isOpen={isUpdate}
        onClose={oncloseModal}
        onFinish={onFinish}
        value={project}
        isUpdate={true}
      />}
      {isDelete && <DeleteModal
        isOpen={isDelete}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Project"
        values={undefined}
      />}
      {isCopy && <CopyModal
        isOpen={isCopy}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Project"
        values={projectNames}
      />}
    </>
  );
}
