import {
  Form,
  Input,
  Modal} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IModal } from "../../Interface/Interfaces";
import { getChipKeyByUserId, getMerchantProfileByScheme } from "../../Redux/Actions/Actions";
import { JsonParse, getPatternByFormat } from "../../Utils/Utils";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";

const CreateEditMerchantProfileModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value, isUpdate  }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  const [merchantProfileNames, setMerchantProfileNames] = useState<any>([''])
  const [schemeOptions, setSchemeOptions] = useState<any>([])
  const [scheme, setScheme] = useState('')
  const [merchantProfile, setMerchantProfile] = useState<any>(value)

  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  )
  const merchantProfileList = useSelector(
    (state: any) => state.lithosApiReducer.merchantProfileList
  )

  useEffect(() => {
    dispatch(getChipKeyByUserId(userDetails?.Id))
  }, [])

  useEffect(() => {
    merchantProfileList?.map((merchantProfile: any) => (
      merchantProfile?.name !== "" && !merchantProfileNames.includes(merchantProfile.name) && setMerchantProfileNames((merchantProfileNames) => [...merchantProfileNames, merchantProfile.name.toLowerCase()])
    ))
    const schemes = masterData?.map((S: any) => ({
      value: S.schemeName,
      label: S.schemeName.toUpperCase(),
    }));
    setSchemeOptions(schemes)
    if (schemes.length === 1) {
      form.setFieldsValue({
        scheme: schemes[0]?.value
      });
      setScheme(schemes[0]?.value?.toUpperCase())
    }
    if(isUpdate){
      setScheme(merchantProfile?.scheme?.toUpperCase())
    }
  }, []);

  useEffect(() => {
    if(scheme !== "" && scheme !== undefined){
      dispatch(getMerchantProfileByScheme(scheme)).then((res: any) => {
        setMerchantProfile(res?.payload)
      });
    }
  }, [scheme])

  const initialValues = {
    scheme: merchantProfile?.scheme,
    merchantCategoryCode: merchantProfile?.merchantCategoryCode.value,
    acquirerCountryCode: merchantProfile?.acquirerCountryCode.value,
    acquirerID: merchantProfile?.acquirerID.value,
    terminalID: merchantProfile?.terminalID.value,
    cardAcceptorID: merchantProfile?.cardAcceptorID.value,
    cardAcceptorName_Location: merchantProfile?.cardAcceptorName_Location.value,
    transactionCurrencyCode: merchantProfile?.transactionCurrencyCode.value,
    id: merchantProfile?.id,
  };
  
  return (
    <Modal
        className="create-card-profile-modal"
        title={isUpdate ? 'Update Merchant profile' : 'Create Merchant profile'}
        okText={isUpdate ? 'Update' : 'Create'}
        centered
        cancelText="Discard"
        open={isOpen}
        onOk={()=>form.submit()}
        onCancel={()=>{onClose();form.resetFields()}}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          {!isUpdate && <>
            <Form.Item
              className="modal-form"
              name="name"
              rules={[
                { required: true, message: "Name cannot be empty!" },
                {
                  message: 'Merchant profile name already exists!',
                  validator: (_, val) => {
                    if (val !== "" && merchantProfileNames.includes(val.toLowerCase().trim())) {
                      return Promise.reject(new Error('Merchant profile name already exists!'));
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            > 
              <FloatingLabelInput label='Name' placeholder="Name" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="scheme"
              rules={[{ required: true, message: "Scheme cannot be empty!" }]}
            >
            <FloatingLabelSelect
                label="Scheme"
                showSearch
                className="modal-input"
                optionFilterProp="children"
                options={schemeOptions}
              />
            </Form.Item>
          </>}
          <Form.Item
            className="modal-form"
            name="merchantCategoryCode"
            rules={[
              { required: true, message: "Merchant category code cannot be empty!" },
              { pattern: getPatternByFormat(merchantProfile?.merchantCategoryCode?.format), message: `Merchant category code should be ${merchantProfile?.merchantCategoryCode?.maxLength} digits in ${merchantProfile?.merchantCategoryCode?.format} format` },
              { max: merchantProfile?.merchantCategoryCode?.maxLength, message: `Merchant category code should be maximum of ${merchantProfile?.merchantCategoryCode?.maxLength} digit`},
              { min: merchantProfile?.merchantCategoryCode?.minLength, message: `Merchant category code should be minimum of ${merchantProfile?.merchantCategoryCode?.minLength} digit`}
            ]}
          >
            <FloatingLabelInput label='Merchant category code' placeholder="Merchant category code" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="acquirerCountryCode"
            rules={[
              { required: true, message: "Acquirer country code cannot be empty!" },
              { pattern: getPatternByFormat(merchantProfile?.acquirerCountryCode?.format), message: `Acquirer country code should be ${merchantProfile?.acquirerCountryCode?.maxLength} digits in ${merchantProfile?.acquirerCountryCode?.format} format` },
              { max: merchantProfile?.acquirerCountryCode?.maxLength, message: `Acquirer country code should be maximum of ${merchantProfile?.acquirerCountryCode?.maxLength} digit`},
              { min: merchantProfile?.acquirerCountryCode?.minLength, message: `Acquirer country code should be minimum of ${merchantProfile?.acquirerCountryCode?.minLength} digit`}
            ]}
          >
            <FloatingLabelInput label='Acquirer country code' placeholder="Acquirer country code" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="acquirerID"
            rules={[
              { required: true, message: "Acquirer ID cannot be empty" },
              {
                message: `Acquirer ID length shall be ${merchantProfile?.acquirerID?.minLength} to ${merchantProfile?.acquirerID?.maxLength} digits!`,
                validator: (_, val) => {
                  if (val !== "" && (val?.length < merchantProfile?.acquirerID?.minLength || val?.length > merchantProfile?.acquirerID?.maxLength)) {
                    return Promise.reject(new Error(`Acquirer ID length shall be ${merchantProfile?.acquirerID?.minLength} to ${merchantProfile?.acquirerID?.maxLength} digits!`));
                  } else {
                    return Promise.resolve();
                  }
                }
              },
              { pattern: getPatternByFormat(merchantProfile?.acquirerID?.format), message: `Acquirer ID should be in ${merchantProfile?.acquirerID?.format} format!`} ,
            ]}
          >
          <FloatingLabelInput label='Acquirer ID' placeholder="Acquirer ID" />
          </Form.Item>          
          <Form.Item
            className="modal-form"
            name="terminalID"
            rules={[
              { required: true, message: "Terminal ID cannot be empty" },
              { len: merchantProfile?.terminalID?.maxLength, message: `Terminal ID should be ${merchantProfile?.terminalID?.maxLength} characters` },
              { pattern: getPatternByFormat(merchantProfile?.terminalID?.format), message: `Terminal ID should be ${merchantProfile?.terminalID?.format} ` },
            ]}            
          >
          <FloatingLabelInput label='Terminal ID' placeholder="Terminal ID" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="cardAcceptorID"
            rules={[
              { required: true, message: "Card acceptor ID cannot be empty!" },
              { len: merchantProfile?.cardAcceptorID?.maxLength, message: `Card acceptor ID should be ${merchantProfile?.cardAcceptorID?.maxLength} characters` },
              { pattern: getPatternByFormat(merchantProfile?.cardAcceptorID?.format), message: `Card acceptor ID should be ${merchantProfile?.cardAcceptorID?.format}` },
            ]}            
          >
            <FloatingLabelInput label='Card acceptor ID' placeholder="Card acceptor ID" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="cardAcceptorName_Location"
            rules={[
              { required: true, message: "Card acceptor name/location cannot be empty!" },
              { pattern: getPatternByFormat(merchantProfile?.cardAcceptorName_Location?.format), message: `Card acceptor name/location length shall be ${merchantProfile?.cardAcceptorName_Location?.minLength} to ${merchantProfile?.cardAcceptorName_Location?.maxLength} digits in ${merchantProfile?.cardAcceptorName_Location?.format} format` },
            ]}            
          >
            <FloatingLabelInput label='Card acceptor name/location' placeholder="Card acceptor name/location" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="transactionCurrencyCode"
            rules={[
              { required: true, message: "Transaction currency code cannot be empty!" },
              { pattern: getPatternByFormat(merchantProfile?.transactionCurrencyCode?.format), message: `Transaction currency code should be ${merchantProfile?.transactionCurrencyCode?.maxLength} digits in ${merchantProfile?.transactionCurrencyCode?.format} format` },
              { max: merchantProfile?.transactionCurrencyCode?.maxLength, message: `Transaction currency code should be maximum of ${merchantProfile?.transactionCurrencyCode?.maxLength} digit`},
              { min: merchantProfile?.transactionCurrencyCode?.minLength, message: `Transaction currency code should be minimum of ${merchantProfile?.transactionCurrencyCode?.minLength} digit`}
            ]}
          >
            <FloatingLabelInput label='Transaction currency code' placeholder="Transaction currency code" />
          </Form.Item>
          <Form.Item hidden name="id">
            <Input  />
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default CreateEditMerchantProfileModal;
