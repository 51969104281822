import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Form, Input, Modal, Space, notification } from "antd";
import { FormatSelectOption, getPatternByFormat } from "../../Utils/Utils";
import { PlusOutlined } from "@ant-design/icons";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import { IModal } from "../../Interface/Interfaces";
import { checkTestPlanUniquePANAndAmount } from "../../Redux/Actions/Actions";
import { SystemUnderTest } from "../../Constants/Constants";

let index = 0;

const CreateEditTestCaseModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, isUpdate, value }) => {
  const testPlan = useSelector(
    (state: any) => state.lithosApiReducer.testPlanDto
  )
  const dispatch = useDispatch();
  const [, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [dropDownData, setDropDownData] = useState<any>();
  const [inputType, setInputType] = useState<any>();
  const [groupName, setGroupName] = useState('');
  const [testCaseNames, setTestCaseNames] = useState(['']);
  const [mti, setMTI] = useState<any>([]);
  const inputRef = useRef(null);
  const [cardProfileOptions, setCardProfileOptions] = useState<any>([])
  const isSUTIssuer = React.useMemo(() => testPlan?.systemUnderTest?.toLowerCase() === SystemUnderTest.Issuer, [])
  const [isMatchPANAndAmountApplicable, setIsMatchPANAndAmountApplicable] = useState(!isSUTIssuer)
  const [merchantProfileOptions, setMerchantProfileOptions] = useState<any>([])
  const noValidateMTIs = React.useMemo(() => testPlan?.skipPANAndAMountMTIs, [])

  const onGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const cardProfileList = useSelector(
    (state: any) => state.lithosApiReducer.cardProfileList
  )
  const merchantProfileList = useSelector(
    (state: any) => state.lithosApiReducer.merchantProfileList
  )
  const testCaseDtoList = useSelector(
    (state: any) => state.lithosApiReducer.categoryTestCasesByTestPlan
  )
  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  )
  const testCaseGroups = useSelector(
    (state: any) => state.lithosApiReducer.testCaseGroups
  )

  const initialValues = {
    Name: value?.name,
    Id: value?.id,
    CardID: value?.cardID,
    MerchantProfileID: value?.merchantProfileID,
    Description: value?.description,
    ProjectID: value?.projectID,
    TestPlanID: value?.testPlanID,
    Scheme: testPlan?.scheme,
    Objective: value?.objective,
    Version: testPlan?.version,
    MatchPan: value?.matchPan,
    MatchAmount: value?.matchAmount
};

  useEffect(() => {
    testCaseDtoList?.map((testCase: any) => (
      testCase?.name !== "" && !testCaseNames?.includes(testCase.name) && setTestCaseNames((testCaseNames: any) => [...testCaseNames, testCase.name.toLowerCase()])
    ))
    setDropDownData(testCaseGroups?.map((testCaseGroup) => testCaseGroup.name))
    const selectedSchemeData = masterData?.find((data) => data.schemeName?.toLowerCase() === testPlan?.scheme?.toLowerCase());
    if (selectedSchemeData) {
      const libraryData = selectedSchemeData?.libraries?.find((data) => data.version === testPlan?.version)
      setMTI(libraryData?.supportedMessages)
    }
    if(isUpdate){
      setIsMatchPANAndAmountApplicable(isMatchPANAndAmountApplicable && !noValidateMTIs?.includes(value?.mti))
    }
  }, []);

  useEffect(() => {
    const cardProfiles = FormatSelectOption(cardProfileList);
    const merchantProfiles = FormatSelectOption(merchantProfileList)
    setCardProfileOptions(cardProfiles)
    setMerchantProfileOptions(merchantProfiles)
    if (cardProfiles?.length === 1) {
      const cp = cardProfileList?.find((card: any) => card.id === cardProfiles[0]?.value);
      if(cp?.pan?.value !== undefined && isMatchPANAndAmountApplicable){
          form.setFieldsValue({ "MatchPan":  cp?.pan?.value })
      }
      form.setFieldsValue({
        CardID: cardProfiles[0]?.value,
      });
    }
    if (merchantProfiles?.length === 1) {
      form.setFieldsValue({
        MerchantProfileID: merchantProfiles[0]?.value,
      });
    }
  }, [])

  const addItem = (e) => {
    e.preventDefault();
    setDropDownData([...dropDownData, groupName || `New item ${index++}`]);
    setGroupName('');
  };

  const handleFinish = async () => {
    const formValues = form.getFieldsValue()
    if(isMatchPANAndAmountApplicable){
        const dto = {
            testPlanId: testPlan.id,
            pan: formValues.MatchPan,
            amount: formValues.MatchAmount,
        };
        if(isUpdate){
            await form.validateFields()
            if (formValues.MatchPan !== initialValues.MatchPan || formValues.MatchAmount !== initialValues.MatchAmount) {
                await dispatch(checkTestPlanUniquePANAndAmount(dto)).then((res: any) => {
                    if (!res.payload) {
                        form.setFields([
                            {
                                name: 'MatchAmount',
                                errors: ['Match PAN & amount combination should be unique!'],
                            },
                        ]);
                        return; 
                    }
                    else{
                        onFinish(formValues);
                    }
                });  
            }
            else{
                onFinish(formValues);
            }
        }
        else{
            await dispatch(checkTestPlanUniquePANAndAmount(dto)).then((res: any) => {
                if (!res.payload) {
                  form.setFields([
                      {
                          name: 'MatchAmount',
                          errors: ['Match PAN & amount combination should be unique!'],
                      },
                  ]);
                  return; 
                }
                else{
                  onFinish(formValues);
                }
            });  
        }
    }
    else{
        onFinish(formValues);
    }
}

const commonFields = () => {
  return (
      <>
          {isSUTIssuer &&
            <Form.Item
              className="modal-form"
              name="CardID"
              rules={[
                  {
                  required: true,
                  message: "Please select the card profile!",
                  },
              ]}
          >
              <FloatingLabelSelect
                label="Card profile"
                showSearch
                className="modal-input"
                optionFilterProp="children"
                options={cardProfileOptions}
                onChange={(e: any) => {          
                    const cp = cardProfileList?.find((card: any) => card.id === e);
                    if(cp?.pan?.value !== undefined && isMatchPANAndAmountApplicable){
                        form.setFieldsValue({ "MatchPan":  cp?.pan?.value })
                    }
                }}               
              />
          </Form.Item>}
          {isMatchPANAndAmountApplicable &&
            <Form.Item
              className="modal-form"
              name="CardID"
              rules={[
                  {
                  required: true,
                  message: "Please select the card profile!",
                  },
              ]}
          >
              <FloatingLabelSelect
                label="Card profile"
                showSearch
                className="modal-input"
                optionFilterProp="children"
                options={cardProfileOptions}
                onChange={(e: any) => {          
                    const cp = cardProfileList?.find((card: any) => card.id === e);
                    if(cp?.pan?.value !== undefined && isMatchPANAndAmountApplicable){
                        form.setFieldsValue({ "MatchPan":  cp?.pan?.value })
                    }
                }}               
              />
          </Form.Item>}
          {isSUTIssuer && 
            <Form.Item
                className="modal-form"
                name="MerchantProfileID"
                rules={[
                    { required: true, message: "Please select the merchant profile!" } 
                ]}
            >
                <FloatingLabelSelect
                    label="Merchant profile"
                    showSearch
                    className="modal-input"
                    optionFilterProp="children"
                    options={merchantProfileOptions}
                />
            </Form.Item>
          }
          {isMatchPANAndAmountApplicable &&
          <>
              <Form.Item
                  className="modal-form"
                  name="MatchPan"
                  rules={[
                      { required: true, message: "PAN cannot be empty!" },
                      {
                        message: `PAN should be in ${cardProfileList?.[0]?.pan?.format} format and length shall be ${cardProfileList?.[0]?.pan?.minLength} to ${cardProfileList?.[0]?.pan?.maxLength} digits!`,
                        validator: (_, val) => {
                          if (!getPatternByFormat(cardProfileList?.[0]?.pan?.format).test(val)) {
                            return Promise.reject(new Error('Invalid PAN format!'));
                          } else if (val !== "" && (val?.length < cardProfileList?.[0].pan?.minLength || val?.length > cardProfileList?.[0].pan?.maxLength)) {
                            return Promise.reject(new Error(`PAN length shall be ${cardProfileList?.[0]?.pan?.minLength} to ${cardProfileList?.[0]?.pan?.maxLength} digits!`));
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                  ]}
              >
                  <FloatingLabelInput label='Match PAN' placeholder="Match PAN" />
              </Form.Item>
              <Form.Item
                  className="modal-form"
                  name="MatchAmount"
                  rules={[
                      { required: true, message: "Amount cannot be empty!" },
                      { len: 12, message: "Amount shall be 12 digit in numeric format"}
                  ]}
              >
                  <FloatingLabelInput label='Match amount' placeholder="Match amount" />
              </Form.Item>
          </>}
      </>
  )
}

  const updateFieldsForNonCertification = () => {
    return (
      <>
        <Form.Item
          className="modal-form"
          name="Name"
          rules={[{ required: true, message: "Please enter the name!" },
          {
              validator: (_, name) => {
              if(name.toLowerCase() === value.name?.toLowerCase().trim()){
                  return Promise.resolve()
              }
              if (name !== "" && testCaseNames.includes(name.toLowerCase().trim())) {
                  return Promise.reject(new Error('Testcase name already exists!'));
              } else {
                  return Promise.resolve()
              }
              }
          }
          ]}
          >
            <FloatingLabelInput label='Name' placeholder="Name" />
          </Form.Item>
          {commonFields()}
          <Form.Item className="modal-form" name="Description">
              <FloatingLabelInput label='Description (optional)' placeholder="Description (optional)" autoSize={{ minRows: 2, maxRows: 5 }} isTextArea="true"/>
          </Form.Item>
          <Form.Item className="modal-form" name="Objective">
              <FloatingLabelInput label='Test Information (optional)' placeholder="Test Information (optional)" autoSize={{ minRows: 2, maxRows: 5 }} isTextArea="true" />
          </Form.Item>
      </>
    )
  }

  const updateFieldsForCertification = () => {
    return (
      <>
        {commonFields()}
        <Form.Item hidden name="Name">
            <Input />
        </Form.Item>
        <Form.Item hidden name="Description">
            <Input />
        </Form.Item>
        <Form.Item hidden name="Objective">
            <Input />
        </Form.Item>
      </>
    )
  }

  const createFields = () => {
    return (
      <>
        <Form.Item
          className="modal-form"
          name="Name"
          rules={[{ required: true, message: "Please enter the name!" },
            {
              validator: (_, value) => {
                if (value !== "" && testCaseNames.includes(value.toLowerCase())) {
                  return Promise.reject(new Error('Testcase name already exists!'));
                } else {
                  return Promise.resolve();
                }
              }
            }
          ]}
        >
          <FloatingLabelInput label='Name' placeholder="Name" />
        </Form.Item>
        <Form.Item
          className="modal-form"
          name="groupName"
          rules={[
            { required: true, message: "Please enter the group name!" },
          ]}
        >
          {dropDownData?.length > 0 && inputType !== "Add new group" ? (
            <FloatingLabelSelect
              onChange={setInputType}
              label="Group name"
              showSearch
              className="modal-input"
              optionFilterProp="children"
              options={FormatSelectOption(dropDownData)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      value={groupName}
                      onChange={onGroupNameChange}
                    />
                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                      Add new Groupname
                    </Button>
                  </Space>
                </>
              )}
            />
          ) : (
            <FloatingLabelInput label='Group name' placeholder="Group name" />
          )}
        </Form.Item>
        <Form.Item
          className="modal-form"
          name="MTI"
          rules={[{ required: true, message: "Please select the MTI!" }]}
        >
          <FloatingLabelSelect
            label="MTI"
            showSearch
            className="modal-input"
            optionFilterProp="children"
            options={FormatSelectOption(mti)}
            onChange={(e: any) => {    
              setIsMatchPANAndAmountApplicable(testPlan?.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer && !noValidateMTIs?.includes(e))
            }}      
          />
        </Form.Item>
        {commonFields()}
        <Form.Item className="modal-form" name="Description">
          <FloatingLabelInput label='Description (optional)' placeholder="Description (optional)" autoSize={{ minRows: 2, maxRows: 5 }} isTextArea="true"/>
        </Form.Item>
        <Form.Item className="modal-form" name="Objective">
          <FloatingLabelInput label='Test Information (optional)' placeholder="Test Information (optional)" autoSize={{ minRows: 2, maxRows: 5 }} isTextArea="true" />
        </Form.Item>
      </>
    )
  }

  const renderFields = () => {
      if(isUpdate){
        if(!value.isCertification){
          return updateFieldsForNonCertification()
        }
        else{
          return updateFieldsForCertification()
        }
      }
      return createFields()
  }

  return (
    <>
      {contextHolder}
      <Modal
        className="create-card-profile-modal"
        title={isUpdate ? "Update Test case" : "Create Test case"}
        open={isOpen}
        onOk={handleFinish}
        okText={isUpdate ? "Update" : "Create"}
        cancelText="Discard"
        onCancel={() => {
          onClose();
          form.resetFields();
          setInputType('');
        }}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          autoComplete="off"
          onFinish={handleFinish}
          initialValues={initialValues}
        >
          {renderFields()}
          <Form.Item hidden name="Id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="Scheme">
            <Input />
          </Form.Item>
          <Form.Item hidden name="Version">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateEditTestCaseModal;
