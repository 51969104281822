import { useEffect } from "react";
import { Layout, theme } from "antd";
import SidebarContainer from "../../Components/SideBar/SidebarContainer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderContainer from "../../Components/Header/HeaderContainer";
import { PrivateRouteUrl, PublicRouterUrl } from "../../Constants/Constants";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
const { Content } = Layout;

export default function LayoutContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("IsAuth");
  const loading = useSelector((state: any) => state.lithosApiReducer.loading);
  
  useEffect(() => {
    const PrivateRoutes = PrivateRouteUrl.filter(
      (R: any) => R.URL === location.pathname
    );
    const PublicRoutes = PublicRouterUrl.filter(
      (R) => R.URL === location.pathname
    );
    if (isAuth === "false" || isAuth === null) {
      PrivateRoutes.length > 0 && navigate("/login");
    } else {
      PublicRoutes.length > 0 && navigate("/dashboard");
    }
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <ErrorBoundary>
      <Loader loading={loading}>
        <Layout>
          <HeaderContainer />
          <Layout>
            <SidebarContainer />
            <Content
                style={{
                  margin: 0,
                  minHeight: 280,
                  background: colorBgContainer,
                  padding: "0 24px 24px",
                }}
              >
                <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Loader> 
    </ErrorBoundary>
  );
}
