import { Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IModal } from "../../Interface/Interfaces";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { useSelector } from "react-redux";

const CreateEditOrganizationModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value, isUpdate  }) => {
const [form] = Form.useForm();
const [orgNames, setOrgNames] = useState<any>([''])

const orgList = useSelector(
    (state: any) => state.lithosApiReducer.orgList
)

const initialValues = {
    Name: value?.name,
    ContactNo: value?.contactNo,
    ContactEmail: value?.contactEmail,
    Id: value?.id,
};

useEffect(() => {
    orgList?.map((key) => (
        key?.name !== "" && !orgNames.includes(key.name) && setOrgNames((keyNames) => [...keyNames, key.name.toLowerCase()])
    ))  
},[])

return (
<Modal
    className="create-chip-key-modal"
    title={isUpdate ? 'Update Organization' : 'Create Organization'}
    okText={isUpdate ? 'Update' : 'Create'}
    centered
    open={isOpen}
    onOk={()=>form.submit()}
    cancelText="Discard"
    onCancel={()=>{onClose();form.resetFields()}}
    >
    <Form
        form={form}
        name="basic"
        className="modal-form-container"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
    >
        {!isUpdate && 
        <Form.Item
            className="modal-form"
            name="Name"
            rules={[
            { required: true, message: "Please enter the name!" },
            {
                message: 'Chipkeys name already exists!',
                validator: (_, value) => {
                if (value !== "" && orgNames.includes(value.toLowerCase().trim())) {
                    return Promise.reject(new Error('Chipkeys name already exists!'));
                } else {
                    return Promise.resolve()
                }
                }
            }
            ]}
        >
            <FloatingLabelInput label='Name' placeholder="Name" />
        </Form.Item>
        }
        <Form.Item
            className="modal-form"
            name="ContactNo"
            rules={[
            { required: true, message: "Please enter the Contact number!" },
            { pattern: /^\d{10}$/, message: "Please enter a valid 10-digit Contact number!" }
            ]}
        >
            <FloatingLabelInput label='Contact number' placeholder="Contact number" />
        </Form.Item>
        <Form.Item
            className="modal-form"
            name="ContactEmail"
            rules={[
            { required: true, message: "Please enter the Contact email!" },
            { type: 'email', message: "Invalid email format" }
            ]}
        >
            <FloatingLabelInput label='Contact email' placeholder="Contact email" />
        </Form.Item>
        <Form.Item hidden name="Id" >
        <Input  />
        </Form.Item>
    </Form>
</Modal>

);
};

export default CreateEditOrganizationModal;
