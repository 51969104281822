import { Col, Form, Input, Row, Space, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import TableContainer from "../../Components/Table/TableContainer";
import SelectionIcon from "../../Assets/selectionIcon.svg";
import {
  CreateCardProFile,
  deleteCardProfile,
  cardProfileUpdate,
  copyCardProfile,
  getCardProfilesByUserId,
} from "../../Redux/Actions/Actions";
import {
  BreadCrumbConfig,
  HeaderTitle,
} from "../../Redux/Reducers/UtilReducer";
import { JsonParse, getPatternByFormat } from "../../Utils/Utils";
import CreateEditCardProfileModal from "./CreateEditCardProfileModal";
import {
  SearchOutlined,
} from "@ant-design/icons";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import Edit from "../../Assets/Edit.svg"
import Copy from "../../Assets/Copy.svg"
import Delete from "../../Assets/Delete.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import { SchemeTag } from "../../Constants/Constants";
import EditableFormItem from "../../Components/InlineEdit/EditableFormItem";
import { ShowToaster } from "../../Components/Notifications/Notifications";

export default function CardProfile() {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [cardProfile, setCardProfile] = useState<any>({})
  const [api, contextHolder] = notification.useNotification()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [loading, setLoading] = useState(false);
  const [cardProfileList, setCardProfileList] = useState<any>(useSelector(
    (state: any) => state.lithosApiReducer.cardProfileList
  ))
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [cardProfileId, setCardProfileId] = useState('')
  const [searchedText, setSearchedText] = useState('')
  const [cardNames, setCardNames] = useState<any>([''])
  const [referredList, setReferredList] = useState<any>([])

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  useEffect(() => {
    dispatch(HeaderTitle("Card profiles"));
    dispatch(
      BreadCrumbConfig({
        title: "Card profiles",
        href: "/cardprofiles",
        preventPush: true,
      } as any)
    );
    RenderLoading()
  }, []);

  const RenderLoading = () => {
    setLoading(true);
    dispatch(getCardProfilesByUserId(userDetails?.Id)).then((data: any) => {
      setCardProfileList(data?.payload?.cardProfileList);
      data?.payload?.cardProfileList?.map((card: any) => (
        card?.name !== "" && !cardNames.includes(card.name) && setCardNames((cardNames: any) => [...cardNames, card.name.toLowerCase()])
      ))
      setLoading(false);
    });
  };

  const oncloseModal = () => {
    setIsModalOpen(false)
    setIsUpdate(false)
    setIsDelete(false)
    setIsCopy(false)
  };

  const OnClickEvent = () => {
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    setLoading(true)
    if (isModalOpen === true) {
      values.expiryDate = values?.expiryDate.format('YYMM');
      dispatch(CreateCardProFile(values)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsModalOpen(false)
        setCardProfileList([res?.payload?.cardProfile, ...cardProfileList])
      });
    } else if(isUpdate === true) {
      values.expiryDate = values?.expiryDate?.format('YYMM');
      dispatch(cardProfileUpdate(values)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsUpdate(false)
        updateRecord(res)
      });
    } else if(isDelete === true){
      dispatch(deleteCardProfile(cardProfileId)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsDelete(false)
        setCardProfileList(cardProfile =>
          cardProfile.filter(mp => {
            return mp.id !== cardProfileId;
          }),
        );
        setCardNames(names => 
          names.filter(name => {
            return name != cardProfile?.name;
        }))
      });
    } else if(isCopy === true){
      dispatch(copyCardProfile({values, cardProfileId})).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsCopy(false)
        setCardProfileList([res?.payload?.cardProfile, ...cardProfileList])
      });
    }
  };
  
  const columns: ColumnsType = [
    {
      title: "Card profile",
      dataIndex: "name",
      key: "name",
      width: 330,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record?.name).toLowerCase().includes(value.toLowerCase())
      },
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {
            setCardProfile(record);
            setCardProfileId(record?.id)
          },
        };
      },
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer" }}>
          {text}
        </div>
      ),
    },
    {...(userType === 'TESTER')
    ? {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text: any, record: any) => (
        <>
        {userType === "TESTER" && 
        <div className="combined-icon" onClick={() => setCardProfile(record)}>
        <Space size={'large'}>
          <button
            onClick={() => {
              setIsCopy(true);
              setCardProfileId(record.id)
            }}
            className="project-content no-border-bg-transparent"
          >
            <Tooltip title="Copy">
              <img className="icons" src={Copy} alt="copy icon"/>
            </Tooltip>
          </button>
          {!record?.isCertification && <><button
            onClick={() => {setCardProfile(record);setIsUpdate(true)}}
            className="project-content no-border-bg-transparent"
          >
            <Tooltip title="Edit">
              <img className="icons" src={Edit} alt="edit icon"/>
            </Tooltip>
          </button>
          <button
            onClick={() => {
              setIsDelete(true);
              setReferredList(record.referredIn)
              setCardProfileId(record.id)
            }}
            className="project-content no-border-bg-transparent"
          >
            <Tooltip title="Delete">
              <img className="icons" src={Delete} alt="delete icon"/>
            </Tooltip>
          </button></>}
        </Space>
      </div>}
      </>
      ),
    } : null},
  ];
  const CardProfileData: any = cardProfileList;

  const handleUpdate = (changedValues) => {
    const simplifiedValues = Object.fromEntries(
      Object.entries(cardProfile).map(([key, value]: [string, any]) => {
        if (value && typeof value === 'object' && 'value' in value) {
          return [key, value?.value];
        } else {
          return [key, value];
        }
      })
    );
    const updatedCardProfile = { ...simplifiedValues, ...changedValues };
    dispatch(cardProfileUpdate(updatedCardProfile)).then((res) => {
        updateRecord(res)
    });
  };

  const updateRecord = (res: any) => {
    const updatedCardProfiles = cardProfileList.map(mp => {
      if (mp.id === res?.payload?.cardProfile?.id) {
        return res?.payload?.cardProfile;
      } else {
        return mp;
      }
    });
    setCardProfileList(updatedCardProfiles);
    setCardProfile(res?.payload?.cardProfile)
  }

  const rowClassName = (record) => {
    if (cardProfile && cardProfile?.id === record.id) {
      return 'selected-row';
    }
    return '';
  };

  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Row className="card-profile-row">
        <Col className="card-profile-col" xs={2} sm={4} md={8} lg={8} xl={8}>
          <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {
              const currValue = e.target.value;
              setSearchedText(currValue)
            }}/>
          <TableContainer
            tableData={CardProfileData}
            column={columns}
            className={"card-profile-table"}
            loading={loading}
            scrollY='65vh'
            rowClassName={rowClassName}
          />
        </Col>
        <Col
          className="card-profile-col"
          span={1}
          xs={2}
          sm={4}
          md={15}
          lg={15}
          xl={15}
          style={{marginTop: '0.6%'}}
        >
          {cardProfile?.name === undefined ? (
            <div className="card-profile-container">
              <img className="card-profile-img" src={SelectionIcon} alt="" />
              <div className="card-profile-title">Please select a card profile</div>
            </div>
          ) : (
            <div className="card-profile-content-container">
              <div className="card-profile-title-name">{cardProfile?.name}</div>
              <div className="card-data-container">
                <div className="card-content-container">
                  <div className="key-title">Scheme</div>
                  <div className="card-profile-value">{SchemeTag[cardProfile.scheme] || cardProfile.scheme}</div>
                </div>
                {userType !== 'TESTER' && <div className="card-content-container">
                  <div className="key-title">Owner</div>
                  <div className="card-profile-value">{cardProfile?.owner?.email}</div>
                </div>}
                <Form
                  form={form}
                  key={cardProfile.id}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="card-content-container">
                    <div className="key-title">Issuer name</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="issuerName"
                        rules={[
                          { required: true, message: "Issuer name cannot be empty!" },
                        ]}
                        value={cardProfile.issuerName}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form} 
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">PAN - (DE-{cardProfile?.pan?.de})</div>
                    <div className="card-profile-value">
                    <EditableFormItem
                        fieldKey="pan"
                        rules={[  
                          { required: true, message: "PAN cannot be empty!" },
                          {
                            message: `PAN should be in ${cardProfile?.pan?.format} format and length shall be ${cardProfile?.pan?.minLength} to ${cardProfile?.pan?.maxLength} digits!`,
                            validator: (_, val) => {
                              if (!getPatternByFormat(cardProfile?.pan?.format).test(val)) {
                                return Promise.reject();
                              } else if (val !== "" && (val?.length < cardProfile?.pan?.minLength || val?.length > cardProfile?.pan?.maxLength)) {
                                return Promise.reject();
                              } else {
                                return Promise.resolve();
                              }
                            }
                          }
                        ]}
                        value={cardProfile.pan?.value}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">PIN - (DE-{cardProfile.pin?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="pin"
                        rules={[
                            { required: true, message: "PIN cannot be empty!" },
                            {
                              message: `PIN length shall be ${cardProfile?.pin?.minLength} to ${cardProfile?.pin?.maxLength} digits!`,
                              validator: (_, val) => {
                                if (val !== "" && (val?.length < cardProfile.pin?.minLength || val?.length > cardProfile.pin?.maxLength)) {
                                  return Promise.reject(new Error(`PIN length shall be ${cardProfile?.pin?.minLength} to ${cardProfile?.pin?.maxLength} digits!`));
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                        ]}
                        value={cardProfile.pin?.value}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">CVV</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="cvv"
                        rules={[
                          { required: true, message: "CVV cannot be empty!" },
                          { pattern: /^\d{3}$/, message: "CVV should be 3-digit in numeric format" },
                        ]}
                        value={cardProfile.cvv}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">CVV2 - (DE-{cardProfile?.cvV2?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="cvV2"
                        rules={[
                          { required: true, message: "CVV2 cannot be empty!" },
                          { pattern: getPatternByFormat(cardProfile?.cvV2?.format), message: `CVV2 should be ${cardProfile?.cvV2?.maxLength} digit in ${cardProfile?.cvV2?.format} format` },
                          { max: cardProfile?.cvV2?.maxLength, message: `CVV2 should be maximum of ${cardProfile?.cvV2?.maxLength} digit`},
                          { min: cardProfile?.cvV2?.minLength, message: `CVV2 should be minimum of ${cardProfile?.cvV2?.maxLength} digit`}
                        ]}           
                        value={cardProfile?.cvV2?.value}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">ICVV</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="icvv"
                        rules={[
                          { required: true, message: "ICVV cannot be empty!" },
                          { pattern: /^\d{3}$/, message: "ICVV should be 3-digit in numeric format" },
                        ]}
                        value={cardProfile.icvv}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Expiry date YY-MM - (DE-{cardProfile.expiryDate?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="expiryDate"
                        rules={[
                          { required: true, message: "expiryDate cannot be empty!" },
                          { pattern: getPatternByFormat(cardProfile?.cvV2?.format), message: `Expiry date should be ${cardProfile?.expiryDate?.maxLength} digit in ${cardProfile?.expiryDate?.format} format` },
                          { max: cardProfile?.expiryDate?.maxLength, message: `Expiry date should be maximum of ${cardProfile?.expiryDate?.maxLength} digit`},
                          { min: cardProfile?.expiryDate?.minLength, message: `Expiry date should be minimum of ${cardProfile?.expiryDate?.maxLength} digit`}
                        ]} 
                        value={cardProfile.expiryDate?.value}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Track1 - (DE-{cardProfile.track1?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="track1"
                        rules={[{ required: true, message: "Please enter the track1!" }]}
                        value={cardProfile.track1?.value}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                  <div className="card-content-container">
                    <div className="key-title">Track2 - (DE-{cardProfile.track2?.de})</div>
                    <div className="card-profile-value">
                      <EditableFormItem
                        fieldKey="track2"
                        rules={[{ required: true, message: "Please enter the track2!" }]}
                        value={cardProfile.track2?.value}
                        onUpdate={handleUpdate}
                        isEditable={cardProfile.isCertification || userType !== 'TESTER'}
                        form={form}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {isModalOpen && <CreateEditCardProfileModal
          isOpen={isModalOpen}
          onClose={oncloseModal}
          onFinish={onFinish}
          isUpdate={false}
      />}
      {isUpdate && <CreateEditCardProfileModal
          isOpen={isUpdate}
          onClose={oncloseModal}
          onFinish={onFinish}
          value={cardProfile}
          isUpdate={true}
      />}
      {isDelete && <DeleteModal
          isOpen={isDelete}
          onClose={oncloseModal}
          onFinish={onFinish}
          title="Card profile"
          values={referredList}
      />}
      {isCopy && <CopyModal
          isOpen={isCopy}
          onClose={oncloseModal}
          onFinish={onFinish}
          title="Card profile"
          values={cardNames}
      />}
    </>
  );
}