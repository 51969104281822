import { Form, Input, Modal, Steps, Tree, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IModal } from "../../Interface/Interfaces";
import { FormatSelectOptionAsLabel, capitalizeFirstLetter, generateSimulationName } from "../../Utils/Utils";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import { getOrganizations } from "../../Redux/Actions/Actions";
import { DataNode, TreeProps } from "antd/es/tree";
import FloatingLabelDatePicker from "../../Components/FloatingLabel/DatePicker/FloatingLabelDatePicker";
import dayjs from "dayjs";
const { Step } = Steps;

const CreateEditUserModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, isUpdate, value  }) => {
  const dispatch = useDispatch();
  const [, contextHolder] = notification.useNotification();
  const [form] = Form.useForm()
  const [userNames, setUserNames] = useState([''])
  const [userMails, setUserMails] = useState([''])
  const [orgOptions, setOrgOptions] = useState<any>([])
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [selectedTreeValues, setSelectedTreeValues] = useState<any[]>([]);
  const [selectedUserType, setSelectedUserType] = useState('')
  const [formValues, setFormValues] = useState<any>({})
  const [currentName, setCurrentName] = useState('')
  const [currentMail, setCurrentMail] = useState('')
  const [orgData, setOrgData] = useState(useSelector(
    (state: any) => state.lithosApiReducer.orgList
  ))
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  );

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );

  let typeList: any = [];
  
  if (userType === 'ADMIN') {
    typeList.push({ value: 'ADMIN', label: 'ADMIN' });
    typeList.push({ value: 'MANAGER', label: 'MANAGER' });
  }
  
  typeList.push({ value: 'TESTER', label: 'TESTER' });
  
  const userDtoList = useSelector(
    (state: any) => state.lithosApiReducer.usersList
  )

  const initialValues = {
    Id: value?.id,
    Name: value?.name,
    Email: value?.email,
    Organization: value?.organization,
    LicenseDetails: { UserType: value?.licenseDetails?.userType }
};

  useEffect(() => {
    !orgData && dispatch(getOrganizations()).then((data: any) => {
      setOrgData(data?.payload?.organizationList || []);
    });
    userDtoList?.map((user: any) => (
      user?.name !== "" && !userNames.includes(user.name) && setUserNames((userNames) => [...userNames, user.name.toLowerCase()]) ||
      user?.email !== '' && !userMails.includes(user.email) && setUserMails((userMails) => [...userMails, user.email.toLowerCase()])
    ))
    if(isUpdate && value){
      setCurrentName(value?.name)
      setCurrentMail(value?.email)
      setSelectedUserType(value?.licenseDetails?.userType)
      if(value?.licenseDetails?.userType?.toLowerCase() !== 'admin'){
        const defaultCheckedKeys: any = []
        Object.entries(value?.licenseDetails?.masterData).map(([, masterData]: [string, any]) => {
          const { schemeName, libraries, systemUnderTestList, properties } = masterData;
          libraries?.forEach((library) => {
            defaultCheckedKeys.push(`${schemeName}|libraries-${library?.version}`);
          });
          systemUnderTestList?.forEach((sut) => {
            sut.testMode?.forEach((testMode) => {
              defaultCheckedKeys.push(`${schemeName}|SystemUnderTest-${sut.testEntity}|testMode-${testMode}`);
            });
          });
          ['CustomTestplan', 'CertificationTestplan'].forEach((testplanType) => {
            if (properties[testplanType]) {
              defaultCheckedKeys.push(`${schemeName}|TestPlan-${testplanType}`);
            }
          });
        });
        setCheckedKeys(defaultCheckedKeys);
        const selectedValues = extractSelectedValues(defaultCheckedKeys);
        setSelectedTreeValues(selectedValues);
      }
    } 
  }, []);

  useEffect(() => {
    setTreeData(convertToAntDTreeData(masterData))
  }, [masterData])

  useEffect(() => {
    const orgs = FormatSelectOptionAsLabel(orgData)
    setOrgOptions(orgs)
    if (orgs?.length === 1) {
      form.setFieldsValue({
        Organization: orgs[0]?.label
      });
    }
  }, [orgData])

  const convertToAntDTreeData = (masterData: any) => {
    const treeData: any[] = [];
    for (const data of masterData) {
      const node = {
        title: data?.schemeName?.toUpperCase(),
        key: `ignore-${data.schemeName}`,
        children: [] as any[],
      };
      if (data?.libraries) {
        const libChildren = data.libraries.map((library) => ({
          title: library?.version,
          key: `${data.schemeName}|libraries-${library?.version}`,
        }));
  
        node.children.push({
          title: 'Libraries',
          key: `ignore-${data.schemeName}-libraries`,
          children: libChildren,
        });
      }
      if (data?.systemUnderTestList) {
        const sutChildren = data.systemUnderTestList.map((sut) => {
          const testModeChildren = sut.testMode
            ? sut.testMode.map((testMode) => ({
                title: generateSimulationName(testMode, sut?.testEntity),
                key: `${data.schemeName}|SystemUnderTest-${sut?.testEntity}|testMode-${testMode}`,
              }))
            : [];
          return {
            title: capitalizeFirstLetter(sut?.testEntity),
            key: `ignore-${data.schemeName+'-'+sut?.testEntity}`,
            children: [
              {
                title: 'Simulation',
                key: `ignore-${data.schemeName +'-'+ sut?.testEntity}-Simulation`,
                children: testModeChildren,
              },
            ],
          };
        });
        node.children.push({
          title: 'System Under Test',
          key: `ignore-${data.schemeName}-systemUnderTest`,
          children: sutChildren,
        });
      }
      node.children.push({
        title: 'Custom Test plan',
        key: `${data.schemeName}|TestPlan-CustomTestplan`,
        children: [],
      });
      node.children.push({
        title: 'Certification Test plan',
        key: `${data.schemeName}|TestPlan-CertificationTestplan`,
        children: [],
      });
      treeData.push(node);
    }
    return treeData;
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    const checked = info.checkedNodes.map((node) => node.key)
    setCheckedKeys(checked);
    const toFormDto: any[] = [];
    for (const val of checked){
      const toCheck = val.toString().split('-')
      if(toCheck[0] !== 'ignore'){
        toFormDto.push(val)
      }
    }
    const selectedValues = toFormDto.length !== 0 ? extractSelectedValues(toFormDto) : [];
    setSelectedTreeValues(selectedValues);
  };

  const extractSelectedValues = (checkedKeysValue: any) => {
    let selectedValues: any[] = [];
    for (const key of checkedKeysValue) {
      const schemeValues = key.split("|");
      const schemeName = schemeValues[0];
      const existingSchemeIndex = selectedValues.findIndex(
        (scheme) => scheme.schemeName === schemeName
      );
      if (existingSchemeIndex === -1) {
        const newScheme = {
          schemeName: schemeName,
          libraries: [],
          systemUnderTestList: [],
          properties: {
            CertificationTestplan: false, 
            CustomTestplan: false, 
          },
        };
        selectedValues.push(newScheme);
      }
      const currentScheme = selectedValues.find(
        (scheme) => scheme.schemeName === schemeName
      );
      const property = schemeValues[1].split("-");
      if (property[0] === "TestPlan") {
        if (property[1] === "CertificationTestplan") {
          currentScheme.properties["CertificationTestplan"] = true;
        } else {
          currentScheme.properties["CustomTestplan"] = true;
        }
      } else if (property[0] === "libraries") {
        const version = property[1];
        currentScheme.libraries.push({ version });
      } else if (property[0] === "SystemUnderTest") {
        const testEntityValues = property[1];
        const testEntityEntry = currentScheme.systemUnderTestList.find(
          (sut) => sut.testEntity === testEntityValues
        );
        if (!testEntityEntry) {
          const sutEntry = {
            testEntity: testEntityValues,
            testMode: [] as string[],
          };
          const testModeProperty = schemeValues[2]?.split("-");
          if (testModeProperty) {
            sutEntry.testMode.push(testModeProperty[1]);
          }
          currentScheme.systemUnderTestList.push(sutEntry);
        } else {
          const testModeProperty = schemeValues[2]?.split("-");
          if (testModeProperty) {
            testEntityEntry.testMode.push(testModeProperty[1]);
          }
        }
      }
    }
    return selectedValues;
  };

  const handleFinish = async () => {
    if(selectedUserType.toLocaleLowerCase() === 'admin'){
      form.validateFields()
      const formValues = form.getFieldsValue()
      formValues.LicenseDetails.MasterData = selectedTreeValues
      onFinish(formValues)
    }
    else if (currentStep === 1) {
      const userValues = formValues
      userValues.LicenseDetails.MasterData = selectedTreeValues
      onFinish(userValues)
    } else {
      form.validateFields().then(() => {
        setFormValues(form.getFieldsValue())
        nextStep();
      })
    }
  };

  const disabledDate = (current) => {
    const endOfToday = dayjs().endOf('day');
    return current && current < endOfToday;
  };

  const okBtnText = () => {
    if(selectedUserType.toLocaleLowerCase() === 'admin'){
      if(isUpdate)
        return 'Update'
      else 
        return 'Create'
    }
    else if(currentStep === 0)
      return 'Next'
    else if(isUpdate)
      return 'Update'
    else 
      return 'Create'
  }

  return (
    <>
      {contextHolder}
      <Modal
        title={isUpdate ? "Update User" : "Create User"}
        className="create-project-modal"
        centered
        okText={okBtnText()}
        cancelText={currentStep === 0 ? 'Cancel' : 'Previous'}
        open={isOpen}
        onOk={handleFinish}
        onCancel={currentStep === 0 ? onClose : prevStep}
      >
        <Steps current={currentStep} size="small" style={{ marginBottom: "16px" }}>
          <Step title="User Details" />
          {selectedUserType.toLowerCase() !== 'admin' && <Step title="License Details" />}
        </Steps>
        <Form
          form={form}
          name="basic"
          className="modal-form-container user-modal"
          onFinish={handleFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          {currentStep === 0 && (
          <><Form.Item
            className="modal-form"
            name="Name"
            rules={[
              {
                required: true,
                message: "Name cannot be empty",
              },
              {
                max: 40,
                message: "User name cannot exceed 40 characters",
              },
              {
                validator: (_, value) => {
                  value = value?.trim();
                  if (value && value !== currentName && userNames?.includes(value?.toLowerCase().trim())) {
                    return Promise.reject(new Error('User name already exists!'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }
            ]}
          >
          <FloatingLabelInput label='Name' placeholder="Name" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="Email"
            rules={[
              { required: true, message: "Email cannot be empty" },
              { type: 'email', message: "Invalid email format" },
              {
                validator: (_, value) => {
                  value = value?.trim(); 
                  if (value && value !== currentMail && userMails?.includes(value?.toLowerCase())) {
                    return Promise.reject(new Error('User with email already exists!'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }
            ]}
          >
          <FloatingLabelInput label='Email' placeholder="Email" />
          </Form.Item>
          {!isUpdate && 
          <Form.Item
            className="modal-form"
            name="Password"
            rules={[
              { required: true, message: "Password cannot be empty" }
            ]}
          >
            <FloatingLabelInput label='Password' placeholder="Password" type='password' />
          </Form.Item>}
          <Form.Item
            className="modal-form"
            name="Organization"
            rules={[
              { required: true, message:  "Organization cannot be empty" }
            ]}
          >
            <FloatingLabelSelect
              label="Organization"
              showSearch
              className="modal-input"
              optionFilterProp="children"
              options={orgOptions}
            />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name={['LicenseDetails', 'UserType']}
            rules={[
              { required: true, message:  "User type cannot be empty" }
            ]}
          >
            <FloatingLabelSelect
              showSearch
              label="User type"
              optionFilterProp="children"
              className="modal-input"
              onChange={(e) => {          
                setSelectedUserType(e.toLowerCase());
              }}
              options={typeList}
            />
          </Form.Item>
          <FloatingLabelDatePicker
              label="Valid till"
              name="expiresAt"
              picker="date"
              format="DD/MMM/YYYY"
              initialValue={value ? dayjs.utc(value?.expiresAt) : dayjs(dayjs().add(1, 'day'), "DD/MMM/YYYY")}
              superPrevIcon={false}
              prevIcon={false}
              disabledDate={disabledDate}
            />
          </>
          )}
          {selectedUserType.toLowerCase() !== 'admin' && currentStep === 1 && 
          <Form.Item
            className="modal-form"
            name='LicenseDetails'
          >
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              treeData={treeData}
            />
          </Form.Item>
          }
          <Form.Item hidden name="Id" >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateEditUserModal;
