import { Button, Checkbox, Col, MenuProps, Radio, Row, Space, Tooltip, notification } from 'antd'
import { useEffect, useState } from 'react'
import SubHeader from '../../Components/Header/SubHeader'
import { useDispatch } from 'react-redux';
import { BreadCrumbConfig, HeaderTitle } from '../../Redux/Reducers/UtilReducer';
import { getKeySet, getProjectById, getTestPlanTestCasesByUserId, keySetUpdate, checkConnectionStatus, getTestPlanGroups, importTestPlan, UpdateProjectTestPlanStatus } from '../../Redux/Actions/Actions';
import { NavLink, useNavigate } from 'react-router-dom';
import { JsonParse } from '../../Utils/Utils';
import MemberKey from './MemberKey';
import GreenStatus from "../../Assets/GreenStatus.svg";
import UnknownStatus from "../../Assets/UnknownStatus.svg"
import ChipKey from './Chipkey';
import SelectionIcon from "../../Assets/selectionIcon.svg";
import UseMyTestPlanModal from './UseMyTestPlanModal';
import { ShowToaster } from '../../Components/Notifications/Notifications';
import type { RadioChangeEvent } from 'antd';
import DuplicateTestCaseWarningModal from './DuplicateTestCaseWarningModal';
import { SchemeTag, SystemUnderTest } from '../../Constants/Constants';

export default function ProjectDetails() {
  const dispatch = useDispatch()
  const [testPlanGroups, setTestPlanGroups] = useState([])
  const [isUseMyTestCase, setIsUseMyTestCase] = useState(false)
  const [isDuplicateWarning, setIsDuplicateWarning] = useState(false)
  const [isMemberKeyModal, setIsMemberKeyModal] = useState(false)
  const [isChipKeyModal, setIsChipKeyModal] = useState(false)
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  const [dateTime, setDateTime] = useState('')
  const storedProjectId = localStorage.getItem("ProjectId")
  const [project, setProject] = useState<any>({})
  const [api, contextHolder] = notification.useNotification()
  const [connectionStatus, setConnectionStatus] = useState<any>()
  const [hostIPLabel, setHostIPLabel] = useState('')
  const navigate = useNavigate()
  const [testPlanInfo, setTestPlanInfo] = useState<any>()
  const [duplicateIds, setDuplicateIds] = useState<any>()

  useEffect(() => {
    localStorage.removeItem("TestPlanName");
    localStorage.removeItem("TestPlanId");
    localStorage.removeItem('SelectedProjectCard')
    localStorage.removeItem('ProjectTestCasePageNo')
  }, [])

  useEffect(() =>{
    dispatch(HeaderTitle('Project details'));
    dispatch(
      BreadCrumbConfig({
        title: project.name,
        href: "/projectdetails",
        preventPush: false,
      } as any)
    );
  }, [project])

  useEffect(() => {
    dispatch(checkConnectionStatus(storedProjectId)).then((data) => {
      data?.payload && setConnectionStatus(data?.payload[0]?.connectionStatusDTOList);
    });
    dispatch(getProjectById(storedProjectId)).then((data) => {
      setProject(data.payload?.project);
      if(data?.payload?.project?.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer){
        localStorage.setItem('TestCaseSUT', SystemUnderTest.Acquirer)
      }
      else{
        localStorage.setItem('TestCaseSUT', SystemUnderTest.Issuer)
      }
      setHostIPLabel(getHostIPLabel(data.payload?.project.systemUnderTest));
      setDateTime(formatDateTime(data.payload?.project.lastAccessedAt));
    });

    loadTestPlanGroups();
  }, [storedProjectId]);

  const getHostIPLabel = (systemUnderTest) => {
    return systemUnderTest?.toLowerCase() === 'issuer' ? 'Issuer IP' : 'Acquirer IP';
  };

  const formatDateTime = (dateTime) => {
    return new Date(dateTime)?.toLocaleString('en-US', {
      timeZone: 'UTC',
      hour12: true,
    });
  };

  const loadTestPlanGroups = () => {
    dispatch(getTestPlanGroups(storedProjectId)).then((data: any) => {
      setTestPlanGroups(data.payload);
      const activeTestPlan = data.payload.find((tp) => tp.isActive)
      setTestPlanInfo(activeTestPlan)
    });
  }

  const onClickEvent = (value: any) => {
    const dto = {
      userId: userDetails?.Id,
      scheme: project.projectScheme,
      version: project.specificationVersion,
      systemUnderTest: project.systemUnderTest,
      testMode: project.testMode
    }
    if (value === "Use-my-test") {
      dispatch(getTestPlanTestCasesByUserId(dto)).then(() => {        
        setIsUseMyTestCase(true);
      })
    } 
  };

  const onCloseModal = () => {
    setIsUseMyTestCase(false)
    setIsMemberKeyModal(false)
    setIsChipKeyModal(false)
    setIsDuplicateWarning(false)
  };

  const onFinish = (values: any) => {
    if(isUseMyTestCase){
      const request = {
        testCaseIdList : values,
        scheme : project?.projectScheme,
        projectId : project?.id,
        isOverwrite : false
      }
      dispatch(importTestPlan(request)).then((res)=>{
        if(res?.payload?.responseCode === 'OK'){
          ShowToaster(res.payload, api)
          onCloseModal()
          loadTestPlanGroups()
        }
        else if(res?.payload?.responseCode === 'Processing'){
          setIsUseMyTestCase(false)
          setDuplicateIds(res?.payload?.responseMessage)
          setIsDuplicateWarning(true)
        }
      })
    }
    if(isDuplicateWarning){
      const request = {
        testCaseIdList : values,
        scheme : project?.projectScheme,
        projectId : project?.id,
        isOverwrite : true
      }
      dispatch(importTestPlan(request)).then((res)=>{
        ShowToaster(res.payload, api)
        onCloseModal()
        loadTestPlanGroups()
      })
    }

    if(isMemberKeyModal || isChipKeyModal){
      let Properties: any = [];
      let FinalResult: any = {};
      FinalResult.ProjectId = project?.id;
      FinalResult.Type = values.Keytype;
      delete values.ProjectId;
      delete values.Keytype;
      for (let key in values) {
        Properties.push({
          Type: key,
          Value: values[key],
          Kcv: null,
          PlainKey: false,
        });
      }
      FinalResult.Properties = Properties;
      dispatch(keySetUpdate(FinalResult)).then((res) => {
        ShowToaster(res.payload, api);
        onCloseModal();
      });
    } 
  };

  const viewEditPinkey = () => {
    const keyName = project?.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer ? 'IWK' : 'AWK'
    dispatch(getKeySet({storedProjectId, keyName})).then((res) => {
      if(res.payload?.responseCode === 'OK'){
        setIsMemberKeyModal(true)
      }
      else{
        ShowToaster(res.payload, api);
      }
    })
  }
  
  const viewEditChipkey = () => {
    const keyName = 'IMK'
    dispatch(getKeySet({storedProjectId, keyName})).then((res) => {
      if(res.payload?.responseCode === 'OK'){
        setIsChipKeyModal(true)
      }
      else{
        ShowToaster(res.payload, api);
      }
    })
  }

  const onChange = (e: RadioChangeEvent) => {
    dispatch(UpdateProjectTestPlanStatus({
      projectId: project?.id,
      testPlanId:  e.target.value
    })).then((res) => {
      ShowToaster(res.payload, api);
      loadTestPlanGroups();
    })
  };

  return (
    <>
    {contextHolder}
    <SubHeader OnClickEvent={onClickEvent} IsSelectAll={false} SelectedIds={null} />
    <Row className="card-profile-row" gutter={24}>
    <Col xs={2} sm={8} md={8} lg={8} xl={8}>
          <div className='card-border'>
              <div className='project-title'>
                <Row>
                  <Col span={18} className='project-name'>{project.name}</Col>
                  <Col span={6} offset={0}>
                    <Row gutter={64}>
                    {connectionStatus?.map((val: any) => (
                      <Tooltip key={val.stream} title={`${val.stream === "Network_Issuer" ? 'Issuer is ' : 'Acquirer is '} ${val.status ? 'connected' : 'not connected'}`}>
                        <Row>
                          <img className="test-case-status project-status" src={val.status ? GreenStatus : UnknownStatus} alt={val.stream}/>
                          <span className={val.status ? 'green' : 'gray'}>{val.status ? 'Connected' : 'Disconnected'}</span>
                        </Row>
                      </Tooltip>
                    ))}
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className='scrollable'>
                <div className='project-content-container'>
                  <div className="project-key-title">Pin key</div>
                  <Button className="project-value" size='small' type='default' onClick={viewEditPinkey}>View/Edit</Button>
                </div>
                {project.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer && <div className='project-content-container'>
                  <div className="project-key-title">Chip key</div>
                  <Button className="project-value" size='small' type='default' onClick={viewEditChipkey}>View/Edit</Button>
                </div>}
                <div className='project-content-container'>
                  <div className="project-key-title">Scheme</div>
                  <div className="project-value">{SchemeTag[project?.projectScheme] || project?.projectScheme}</div>
                </div>
                <div className='project-content-container'>
                  <div className="project-key-title">Version</div>
                  <div className="project-value">{project.specificationVersion}</div>
                </div>
                <div className='project-content-container'>
                  <div className="project-key-title">Network IP</div>
                  <div className="project-value">{project.ip}</div>
                </div>
                <div className='project-content-container'>
                  <div className="project-key-title">Port</div>
                  <div className="project-value">{project.port}</div>
                </div>
                <div className='project-content-container'>
                  <div className="project-key-title">Under test</div>
                  <div className="project-value">{project.systemUnderTest}</div>
                </div>
                {project.acquirerID && <div className='project-content-container'>
                  <div className="project-key-title">Acquirer ID</div>
                  <div className="project-value">{project.acquirerID}</div>
                </div>}
                {project.terminalID && <div className='project-content-container'>
                  <div className="project-key-title">Terminal ID</div>
                  <div className="project-value">{project.terminalID}</div>
                </div>}
                <div className='project-content-container'>
                  <div className="project-key-title">{hostIPLabel}</div>
                  <div className="project-value">{project.sourceIP}</div>
                </div>
              </div>
          </div>
        </Col>
        <Col span={1} xs={4} sm={5} md={16} lg={16} xl={16}>
          <div className='card-border'>
              <div className='project-category-title'>Project Test plan categories</div>
              <div className="category-container">
                  <div className='testplan-cards'>
                  {testPlanGroups?.length !== 0 ? (
                    testPlanGroups?.map((T: any, index: any) => {
                      return (
                        <div
                          onClick={() => {
                            dispatch(getProjectById(storedProjectId)).then(() => {
                              localStorage.setItem("TestPlanName", T?.name);
                              localStorage.setItem("TestPlanId", T?.id);
                              navigate('/testcases');
                            })
                          }}
                          className={`test-plan-card content ${T?.isActive && 'test-plan-active'}`}
                          key={T.name}
                        >
                          <Row justify="space-around" align="middle">
                            <Col span={21}><div className="testplan-name">{T.name}</div></Col>
                            {project?.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer &&
                              <Col span={3}>
                              <Tooltip title={`${T?.id === testPlanInfo?.id ? 'Active' : 'Set as active'}`}>
                                <div onClick={(e) => {e.stopPropagation()}}> 
                                  <Radio.Group onChange={onChange} value={testPlanInfo?.id}>
                                    <Radio value={T?.id} />
                                  </Radio.Group>
                                </div>
                              </Tooltip>   
                            </Col>}
                          </Row>
                          <div className="tesplan-count">
                            <div className='count-container'>
                              <div className="executed-count tp">
                                {T.pass}
                              </div><p className='count-text'>Pass</p>
                            </div>
                            <div className='count-container'>
                              <div className="failed-count tp">
                                {T.fail}
                              </div><p className='count-text'>Fail</p>
                            </div>
                            <div className='count-container'>
                              <div className="not-executed tp">
                                {T.unknown}
                              </div><p className='count-text'>Not Executed</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                        <div className="testplan-import-message">
                            <img className="testplan-import-img" src={SelectionIcon} alt="" />
                            <div className="card-profile-title">
                                Please import test case(s)
                        </div>
                  </div>
                  )}
                  {testPlanGroups?.length !== 0 && <div className='info-text'>
                      <p>Please open a test plan to begin the execution</p>
                  </div>}
                </div>
              </div>
              <div className='category-footer'>
                <Row>
                  <Col span={8}>
                    <Space className='lastActionAt'><p>Last accessed at  |</p><p>{dateTime}</p></Space>
                  </Col>
                  <Col span={8}/>
                  {project.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer && <Col span={8} className='footer-buttons' style={{justifyContent:"flex-end"}}> 
                    <NavLink to={"/projectlogs"}>
                      <Button >
                        Project logs
                      </Button>
                    </NavLink>
                  </Col>}
                </Row>
              </div>
          </div>
        </Col>
    </Row>
    {isMemberKeyModal && <MemberKey
        isOpen={isMemberKeyModal}
        onClose={onCloseModal}
        onFinish={onFinish}
      />}
    {isChipKeyModal && <ChipKey
        isOpen={isChipKeyModal}
        onClose={onCloseModal}
        onFinish={onFinish}
      />}
    {isUseMyTestCase && <UseMyTestPlanModal
        isOpen={isUseMyTestCase}
        onClose={onCloseModal}
        onFinish={onFinish}
      />}
    {isDuplicateWarning && <DuplicateTestCaseWarningModal
        isOpen={isDuplicateWarning}
        onClose={onCloseModal}
        onFinish={onFinish}
        value={duplicateIds}
      />}
    </>
  )
}

