import React, { useState } from "react";
import { IModal } from "../../Interface/Interfaces";
import { Collapse, Form, Input, Modal } from "antd";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import MultiSelectionTableContainer from "../../Components/Table/MultiSelectionTableContainer";
import SelectionIcon from "../../Assets/selectionIcon.svg";
const { Panel } = Collapse;

const UseMyTestPlanModal: React.FC<IModal> = ({isOpen, onClose, onFinish, value}) => {
  const [form] = Form.useForm();
  const [searchedText, setSearchedText] = useState<any>('')
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleSelectedRows = (rowKeys: React.Key[]) => {
    setSelectedRowKeys(rowKeys);
  };

  const testPlanTestCaseData = useSelector(
    (state: any) => state.lithosApiReducer.testCaseList
  );

  const keyValueArray = Object.entries(testPlanTestCaseData);

  const doesHaveTestPlan = keyValueArray?.length > 0;
  
  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 60,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase())
      },
      render: (text, record : any) => (
        <div className="project-content" key={record.id + record.name}>
          {text}
        </div>
      ),
    },
    {
      title: "MTI",
      dataIndex: "mti",
      width: 40,
      key: "mti",
      render: (text, record : any) => <div className="project-content" key={record.id + record.mti}>{text}</div>,
    },
    {
      title: "Group Name",
      dataIndex: "groupName",
      width: 100,
      key: "groupName",
      render: (text, record : any) => <div className="project-content" key={record.id + record.groupName}>{text}</div>,
    },
    {
      title: "Objective",
      dataIndex: "objective",
      width: 200,
      key: "objective",
      render: (text, record : any) => <div className="project-content" key={record.id + record.objective} dangerouslySetInnerHTML={{__html: text !== null ? text : `N/A`}} />,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 200,
      key: "description",
      render: (text, record : any) => <div className="project-content" key={record.id + record.description}>{text}</div>,
    },
  ];

  const handleImport = () => {
    const testCaseIdList = selectedRowKeys
    onFinish(testCaseIdList);
    onClose();
  };

  return (
    <Modal
      className={doesHaveTestPlan ? `import-my-test-case-modal` : ''}
      title="Import my Test case(s)"
      centered
      open={isOpen}
      onOk={handleImport}
      okText="Import"
      cancelText="Cancel"
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      okButtonProps={doesHaveTestPlan ? {} : {style: { display: 'none' }}}
      cancelButtonProps={doesHaveTestPlan ? {} : {style: { display: 'none' }}}
    >
      {doesHaveTestPlan ?
      <>
        <Input
          size="large"
          className='search-bar'
          placeholder="Search by name"
          suffix={<SearchOutlined />}
          onChange={(e) => setSearchedText(e.target.value)}
        />
        <Collapse accordion>
          {keyValueArray.map(([key, value]) => (
            <Panel header={key} key={key} className="import-table-title">
              <MultiSelectionTableContainer
                tableData={value}
                column={columns}
                className="test-case-table"
                loading={value === undefined && true}
                pagination={false}
                selectedRowKeys={selectedRowKeys}
                onSelectedRowsChange={handleSelectedRows}
                scrollY='40vh'
                scrollX='0'
                hideCheckbox={false}
                rowClassName={null}
                onRow={null}
              />
            </Panel>
          ))}
        </Collapse>
      </> : <div className="use-testplan-import-message">
                            <img className="use-testplan-import-img" src={SelectionIcon} alt="" />
                            <div className="card-profile-title">
                                Please create a testplan to import
                        </div>
                  </div>}
    </Modal>
  );
};

export default UseMyTestPlanModal;
