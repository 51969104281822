import { createBrowserRouter } from "react-router-dom";
import CardProfile from "./Pages/CardProfile/CardProfile";
import ChipKey from "./Pages/ChipKey/ChipKey";
import Dashboard from "./Pages/Dashboard/Dashboard";
import LayoutContainer from "./Pages/Layouts/LayoutContainer";
import Login from "./Pages/LoginPage/Login";
import SystemLogs from "./Pages/SystemLogs/SystemLogs";
import Projects from "./Pages/Projects/Projects";
import TestPlan from "./Pages/TestPlan/TestPlan";
import ProjectTestCase from "./Pages/Projects/ProjectTestCase";
import CopyFromOther from "./Pages/CopyFrom/CopyFromOther";
import TestPlanTestCase from "./Pages/TestPlan/TestPlanTestCase";
import AnalyzeLogs from "./Pages/Projects/AnalyzeLogs";
import ProjectDetails from "./Pages/Projects/ProjectDetails";
import TestCaseConfiguration from "./Pages/TestCaseConfiguration/TestCaseConfiguration";
import SessionTimeOut from "./Pages/SessionTimedOut/SessionTimeOut";
import ProjectLogs from "./Pages/Projects/ProjectLogs";
import MerchantProfile from "./Pages/MerchantProfile/MerchantProfile";
import Users from "./Pages/Users/Users";
import Organization from "./Pages/Organization/Organization";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutContainer />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },{
        path: "projects",
        element: <Projects />,
      },{
        path: "systemlogs",
        element: <SystemLogs />,
      },{
        path: "testplans",
        element: <TestPlan />,
      },{
        path: "cardprofiles",
        element: <CardProfile />,
      },{
        path: "merchantprofiles",
        element: <MerchantProfile />,
      },{
        path: "chipkeys",
        element: <ChipKey />,
      },{
        path: "testcases",
        element: <ProjectTestCase />,
      },{
        path: "testcases/configuration",
        element: <TestCaseConfiguration />,
      },{
        path: "copyFromOther",
        element: <CopyFromOther />,
      },{
        path: "testplan/testcases",
        element: <TestPlanTestCase />,
      },{
        path: "projectdetails",
        element: <ProjectDetails />,
      },{
        path: "projectlogs",
        element: <ProjectLogs />,
      },{
        path: "testcases/analyzelogs",
        element: <AnalyzeLogs/>
      },{
        path: "testplan/testcases/configuration",
        element: <TestCaseConfiguration/>
      },{
        path: "users",
        element: <Users />,
      },{
        path: "organization",
        element: <Organization />,
      }
    ],
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/sessiontimeout",
    element: <SessionTimeOut />
  }
]);

export default router;
