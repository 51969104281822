import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import TableContainer from "../../Components/Table/TableContainer";
import {
  BreadCrumbConfig,
  HeaderTitle,
} from "../../Redux/Reducers/UtilReducer";
import CreateTestPlanModal from "./CreateTestPlanModal";
import { JsonParse } from "../../Utils/Utils";
import {
  CreateTestPlan,
  copyTestPlan,
  getTestPlansById,
  getTestPlansByUserId,
  deleteTestPlan,
} from "../../Redux/Actions/Actions";
import {
  SearchOutlined,
} from "@ant-design/icons";
import { Input, Space, Tooltip, notification } from "antd";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import Copy from "../../Assets/Copy.svg"
import Delete from "../../Assets/Delete.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import { SchemeTag, SystemUnderTest } from "../../Constants/Constants";
import { ShowToaster } from "../../Components/Notifications/Notifications";

export default function TestPlan() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userDetails = JsonParse(localStorage.getItem("UserDetails"));
  const [loading, setLoading] = useState(false);
  const [testPlanData, setTestPlanData] = useState(useSelector(
    (state: any) => state.lithosApiReducer.testPlanDtoList
  ));
  const [isDelete, setIsDelete] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [testPlanId, setTestPlanId] = useState('')
  const [testPlanNames, setTestPlanNames] = useState<any>([''])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchedText, setSearchedText] = useState('')
  const [testPlan, setTestPlan] = useState<any>({})

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  useEffect(() => {
    localStorage.removeItem("TestPlanId");
    dispatch(HeaderTitle("Test plans"));
    dispatch(
      BreadCrumbConfig({
        title: "Test plans",
        href: "/testplans",
        preventPush: true,
      } as any)
    );
    RenderHandler()
  }, []);

  const RenderHandler = () => {
    setLoading(true);
    dispatch(getTestPlansByUserId(userDetails?.Id)).then((data: any) => {
      setTestPlanData(data?.payload?.testPlanList)
      data.payload?.testPlanList.map((testPlan: any) => (
        testPlan?.name !== "" && !testPlanNames.includes(testPlan.name) && setTestPlanNames((testPlanNames: any) => [...testPlanNames, testPlan.name.toLowerCase()])
      ))
      setLoading(false);
    });
  };

  const oncloseModal = () => {
    setIsModalOpen(false);
    setIsDelete(false);
    setIsCopy(false)
  };

  const OnClickEvent = () => {
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (isModalOpen === true) {
      dispatch(CreateTestPlan(values)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsModalOpen(false)
        setTestPlanData([res?.payload?.testPlan, ...testPlanData])
      })
    } else if(isDelete === true){
      dispatch(deleteTestPlan(testPlanId)).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsDelete(false)
        setTestPlanData(testPlan =>
          testPlan.filter(tp => {
            return tp.id !== testPlanId;
          }),
        );
        setTestPlanNames(names => 
          names.filter(name => {
            return name != testPlan?.name;
        }))
      })
    } else if(isCopy === true){
      dispatch(copyTestPlan({values, testPlanId})).then((res) => {
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsCopy(false)
        setTestPlanData([res?.payload?.testPlan, ...testPlanData])
      });
    }
  };

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 350,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record?.name).toLowerCase().includes(value.toLowerCase())
      },
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name); 
      },
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer", color:"#036DAD" }}>
          {text}
        </div>
      ),
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {
            dispatch(getTestPlansById(record?.id)).then((res) => {
              if(res?.payload?.testPlan?.systemUnderTest?.toLowerCase() === SystemUnderTest.Acquirer){
                localStorage.setItem('TestCaseSUT', SystemUnderTest.Acquirer)
              }
              else{
                localStorage.setItem('TestCaseSUT', SystemUnderTest.Issuer)
              }
            })
            localStorage.setItem("TestPlanId", record?.id);
            navigate({
              pathname: "/testplan/testcases",
              search: `scheme=${record?.scheme}`,
            });
          },
        };
      },
    },
    {
      title: "Scheme",
      dataIndex: "scheme",
      width: 280,
      key: "scheme",
      render: (text, record: any) => {
        const tagInfo = SchemeTag[text]; 
        if (tagInfo) {
          return (
            <div className="project-content" key={record.id + record.scheme}>
              {tagInfo}
            </div>
          );
        }
        return (
          <div className="project-content" key={record.id + record.scheme}>
            {text}
          </div>
        );
      },
      sorter: (a: any, b: any) => a.scheme - b.scheme,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      width: 280,
      render: (text) => <div className="project-content">{text}</div>,
      sorter: (a: any, b: any) => a.version - b.version,
    },
    {
      title: "System under test",
      key: "systemUnderTest",
      dataIndex: "systemUnderTest",
      width: 300,
      render: (text: any) => <div className="project-content">{text}</div>,
    },
    {
      ...(userType !== 'TESTER'
          ? {
              title: "Owner",
              key: "owner",
              dataIndex:  ["owner", "email"],
              render: (text) => <div className="project-content">{text}</div>,
              sorter: (a: any, b: any) => a.owner - b.owner,
          }
          : null),
    },
    {
      ...(userType === 'TESTER'
        ? {
            title: "Actions",
            key: "actions",
            width: 400,
            dataIndex: "actions",
            render: (text: any, record: any) => (
              <div className="combined-icon" onClick={() => setTestPlan(record)}>
                <Space size={"large"}>
                  <div
                    onClick={() => {
                      setIsCopy(true);
                      setTestPlanId(record.id)
                    }}
                    className="project-content"
                  >
                    <Tooltip title="copy">
                      <img className="icons" src={Copy} />
                    </Tooltip>
                  </div>
                  {!record?.isCertification && 
                  <div
                    onClick={() => {
                      setIsDelete(true);
                      setTestPlanId(record.id)
                    }}
                    className="project-content"
                  >
                    <Tooltip title="Delete">
                      <img className="icons" src={Delete} />
                    </Tooltip>
                  </div>}
                </Space>
              </div>
            ),
          } : null),
    },
  ];
  const TestPlanData: any = testPlanData;
  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {
              const currValue = e.target.value;
              setSearchedText(currValue)
            }}/>
      <TableContainer
        tableData={TestPlanData}
        column={columns}
        className={"test-plan-table"}
        loading={loading}
        scrollY='65vh'
      />
      {isModalOpen && <CreateTestPlanModal
        isOpen={isModalOpen}
        onClose={oncloseModal}
        onFinish={onFinish}
      />}
      {isDelete && <DeleteModal
        isOpen={isDelete}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Testplan"
        values={undefined}
      />}
      {isCopy && <CopyModal
        isOpen={isCopy}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Testplan"
        values={testPlanNames}
      />}
    </>
  );
}
