import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import { BreadCrumbConfig, HeaderTitle } from "../../Redux/Reducers/UtilReducer";
import { getLogs } from "../../Redux/Actions/Actions";
import { Button, Col, Collapse, Dropdown, Input, Pagination, Row, Space, PaginationProps, Tooltip, MenuProps, Menu } from "antd";
import dayjs from "dayjs";
import { ArrowsAltOutlined, DownOutlined, ReloadOutlined, ShrinkOutlined, SlidersOutlined, UpOutlined } from "@ant-design/icons";
import LogModal from "./LogModal";
import FilterForm from "./FilterForm";
import { SchemeTag } from "../../Constants/Constants";

export const SystemLogStreamLogContext = createContext({});

export default function SystemLogs() {
  const dispatch = useDispatch()
  const { Panel } = Collapse
  const [logData, setLogData] = useState<any>()
  const [activeKeys, setActiveKeys] = useState([])
  const [isExpanded, setIsExpanded] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPageNo, setTotalPageNo] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [isLogModalOpen, setIsLogModalOpen] = useState(false)
  const [streamLog, setStreamLog] = useState<any>()
  const [isFinancial, setIsFinancial] = useState(true)
  const [searchedText, setSearchedText] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFilterClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  useEffect(() => {
    dispatch(HeaderTitle("System logs"));
    dispatch(
      BreadCrumbConfig({
        title: "System logs",
        href: "/systemlogs",
        preventPush: true,
      } as any)
    );
  }, [dispatch])

  const searchDto: any = {
    page: currentPage,
    isFinancial: isFinancial,
  }

  useEffect(() => {
    fetchLogs(searchDto)
  }, [currentPage, isFinancial])

  const handlePanelChange = (keys) => {
    setActiveKeys(keys);
  };

  const OnClickEvent = (value: any) => {
    if((isFinancial && value === 'btnFinancialLogs') || (!isFinancial && value === "btnNetworkLogs"))
        return
    
    setCurrentPage(1)
    setTotalPageNo(0)
    setPageSize(0)
    setActiveKeys([])
    setLogData({})
    if(value === 'btnNetworkLogs'){
      setIsFinancial(false)
      setSearchedText('')
    }else if(value === 'btnFinancialLogs'){
      setIsFinancial(true)
      setSearchedText('')
    }
  }

  const fetchLogs = (values: any) => {
    dispatch(getLogs(values)).then((res: any) => {
      if (res.payload?.searchResult?.financialTransactionsLogs?.length > 0) {
        setLogData(res.payload?.searchResult.financialTransactionsLogs)
        setTotalPageNo(res?.payload?.searchResult.totalPage)
        setActiveKeys(res.payload?.searchResult.financialTransactionsLogs?.map((log) => log?.uniqueId))
        setIsExpanded(true)
        setPageSize(res.payload?.searchResult.financialTransactionsLogs?.length)
      }
      else{
        setLogData({})
        setTotalPageNo(0)
        setActiveKeys([])
        setPageSize(0)
      }
    });
  };

  const headerExtra = (data :any) =>{
    return (
      <Row gutter={16}>
        <Col span={3} offset={6} style={{marginLeft:'2%'}}>{data.stan}</Col>
        <Col span={4} offset={1}>{dayjs(data.timeStamp).format("MM/DD/YYYY h:mm A")}</Col>
        <Col span={1} offset={1} style={{marginLeft:'5%'}}>{SchemeTag[data.scheme] || data.scheme}</Col>
    </Row>
    )
}

  const handleToggleAll = () => {
    if(logData !== undefined){
      if (isExpanded) {
        setActiveKeys([]);
        setIsExpanded(false);
      } else {
        const allPanelKeys = logData?.map(item => item.uniqueId);
        setActiveKeys(allPanelKeys);
        setIsExpanded(true);
      }
    }
  };

const customExpandIcon = ( isActive: any ) => {
  const iconClassName = isActive ? 'custom-collapse-icon active' : 'custom-collapse-icon';
  return isActive ? <DownOutlined className={iconClassName} /> : <UpOutlined className={iconClassName} />;
};

const handleCardClick = (log: any) => {
  setIsLogModalOpen(true)
  setStreamLog(log)
}
    
const oncloseModal = () => {
    setIsLogModalOpen(false)
    setStreamLog({})
};

const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page)
};

const onFinish = async (values) => {
  values.isFinancial = isFinancial;
  values.page = currentPage;
  fetchLogs(values)
  setIsDropdownOpen(false)
};

  const searchLogsWithInput = () => {
      let length = searchedText.length
      if (length > 6) 
        searchDto.stan = searchedText.slice(0, 6);
      else 
        searchDto.stan = searchedText.padStart(6, '0');
      searchDto.acquirerId = searchedText;
      searchDto.operator = "OR";
      if (/[0-9a-zA-Z]/.test(searchedText))
        searchDto.rrn = searchedText;
      if(isFinancial){
        if (/^[0-9]+$/.test(searchedText)) {
          searchDto.TerminalId = searchedText;
          if (length < 12)
            searchDto.Pan = searchedText.padStart(12, '0');
          else if (length > 19)
            searchDto.Pan = searchedText.slice(0, 19);
          else
            searchDto.Pan = searchedText;
          if (length < 12)
            searchDto.Amount = searchedText.padStart(12, '0');
          else
            searchDto.Amount = searchedText.slice(0, 12);
        }
      }
      fetchLogs(searchDto)
  }

  const expandIcon = isExpanded ? <Tooltip title='Collapse all'><ShrinkOutlined style={{ fontSize: '18px' }} /></Tooltip> 
  : <Tooltip title='Expand all'><ArrowsAltOutlined style={{ fontSize: '18px' }} /></Tooltip>;
    
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <FilterForm onFinish={onFinish} isFinancial={isFinancial}/>
      ),
    },
  ];

  return (
    <>
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <Dropdown
        className="filter-dropdown"
        open={isDropdownOpen}
        onOpenChange={setIsDropdownOpen}
        menu={{ items }}
        trigger={['click']}
      >
        <div className="system-logs-search">
          <Space.Compact size="large" style={{width: '100%'}} className='search-bar'>
            <Input size="large"  placeholder="Search" value={searchedText} onChange={(e) => setSearchedText(e.target.value)} 
              onPressEnter={searchLogsWithInput} onClick={(e) => e.stopPropagation()}/>
            <Button type="default" icon={<SlidersOutlined style={{color: '#838383'}}/>} onClick={handleFilterClick}></Button>
          </Space.Compact>  
        </div>  
      </Dropdown>
      <div className='systemlogs-border'>
        <div className="log-header">
          <div className="sticky-header">
            <Row className='header-content'>
              <Col span={1} offset={1}>Stan</Col>
              <Col span={3} offset={3}>Time stamp</Col>
              <Col span={3} offset={2}>Scheme</Col>
              <Col span={1} offset={10}><Button onClick={handleToggleAll} icon={expandIcon} style={{color: '#838383'}}></Button></Col>
            </Row>
          </div>
          <div className="collapse-panels">
              {logData && logData?.length > 0 ? (
                  <Collapse
                    activeKey={activeKeys}
                    onChange={handlePanelChange}
                    expandIcon={({ isActive }) => customExpandIcon(isActive)}
                    >
                    {logData?.map((log: any) => (
                        <Panel key={log.uniqueId} header={headerExtra(log)}>
                            {log.transactions?.map((streamLogs: any) => (
                                <div className={`log-stream-header ${streamLogs?.error?.length > 0 ? 'stream-log-error-header' : 'stream-log-header'}`} key={streamLogs.stream}
                                    onClick={() => handleCardClick(streamLogs)}
                                >
                                  <p className={`stream ${streamLogs?.error?.length > 0 ? 'error-stream-name' : 'stream-name'}`}>{streamLogs.stream}
                                  <div className='error-count'>{streamLogs?.error?.length > 0 && streamLogs?.error?.length}</div></p>
                                </div>
                            ))}
                        </Panel>
                    ))}
                  </Collapse>
              ) : (
                  <p className='para-text'>No logs found!</p>
              )}
          </div>
        </div>
        <div className="pagination">
          <Col className="pagination-reload" flex="auto">
              <Row align="middle" gutter={[8, 0]} >
              <div onClick={() => fetchLogs(searchDto)} style={{display: "flex"}}>
                  <ReloadOutlined />
                  <p style={{ marginLeft: '6px'}}>Refresh</p>
              </div>
              </Row>
          </Col>
          <Col className="pagination-pagination">
              <Pagination current={currentPage} onChange={onChange}  total={totalPageNo * pageSize} pageSize={pageSize} showSizeChanger={false}/>
          </Col>
        </div>
      </div>
      {isLogModalOpen && <SystemLogStreamLogContext.Provider value={streamLog}>
          <LogModal
              isOpen={isLogModalOpen}
              onClose={oncloseModal}
              onFinish={onFinish}
          />
      </SystemLogStreamLogContext.Provider> }
    </>
  )
}