import React from "react";
import { IConfirmModal } from "../../Interface/Interfaces";
import { Button, Modal } from "antd";

const OverwriteWarningModal: React.FC<IConfirmModal> = ({ isOpen, onClose, onConfirm  }) => {


return (
    <Modal
        title={`Overwrite Test case(s)`}
        okText="Confirm"
        centered
        cancelText="Discard"
        className="centered-modal"
        open={isOpen}
        onOk={() => onConfirm(true)}
        onCancel={()=>{onClose()}}
        width={300}
        footer={[
            <Button key="ok" type="primary" className='delete-btn' onClick={() => onConfirm(true)}>
                    Continue
            </Button>,
            <div key="cancel" className='tertiary-btn cancel-btn' onClick={()=>{onClose()}}>
                    Cancel
            </div>,
        ]}
        >
            <div className="delete-modal-content">
                <span className="delete-warning-msg">
                    The selected test cases(s) will be overwritten & not selected will be skipped
                    <br></br>
                    Are you sure you want to continue?
                </span>
            </div>
    </Modal>
);
};

export default OverwriteWarningModal;
