import { AxiosRequestConfig } from "axios";
import { Axios } from "../../ServerConfig/Axios";

class LithosApiServices {
  //Login
  LoginHandler(req: any) {
    return Axios.post("Login", req);
  }

  Logout(req: any) {
    return Axios.get("Login/signout", {
      params: { email: req },
    });
  }

  ChangePassword(req: any){
    return Axios.post("Login/resetpwd", req);
  }

  getUserById(req: any){
    return Axios.get('User/id', {
      params: {userId: req}
    })
  }

  getUsersByType(req: any){
    return Axios.get('User/type', {
      params: {userId: req}
    })
  }
  
  CreateUser(req: any){
    return Axios.post('User', req)
  }

  UpdateUser(req: any) {
    return Axios.patch("User", req);
  }

  deleteUser(req: any) {
    return Axios.delete("User", {
      params: { userId: req },
    });
  }

  EndSession(req: any) {
    return Axios.get("Login/endSession", {
      params: { email: req },
    });
  }

  //Project
  CreateProject(req: any) {
    return Axios.post("Project", req);
  }
  getProjectsByUserId(req: any) {
    return Axios.get("Project/user", {
      params: { userId: req },
    });
  }
  getProjectById(req: any) {
    return Axios.get("Project", {
      params: { Id: req },
    });
  }

  projectUpdate(req: any) {
    return Axios.patch("Project", req);
  }

  projectTestCaseUpdate(req: any) {
    return Axios.patch("TestCase/project", req);
  }

  deleteProject(req: any) {
    return Axios.delete("Project", {
      params: { Id: req },
    });
  }

  projectTestcaseDeleteById(req: any) {
    return Axios.delete("TestCase/project", {
      params: { Id: req },
    });
  }

  CopyProject(req: any) {
    return Axios.get("Project/copy", { params: { Id: req.projectId, name: req.values.name }});
  }

  //Card Profile
  CreateCardProfile(req: any) {
    return Axios.post("CardProfile", req);
  }

  GetCardProfilesByUserId(req: any) {
    return Axios.get("CardProfile/user", {
      params: { userId: req },
    });
  }

  GetMerchantProfilesByUserId(req: any) {
    return Axios.get("MerchantProfile/user", {
      params: { userId: req },
    });
  }

  CreateMerchantProfile(req: any) {
    return Axios.post("MerchantProfile", req);
  }

  UpdateMerchantProfile(req: any) {
    return Axios.patch("MerchantProfile", req);
  }

  getCardProfileById(req: any) {
    return Axios.get("CardProfile", {
      params: { Id: req },
    });
  }

  getMerchantProfileById(req: any) {
    return Axios.get("MerchantProfile", {
      params: { Id: req },
    });
  }

  cardProfileUpdate(req: any) {
    return Axios.patch("CardProfile", req);
  }

  deleteCardProfile(req: any) {
    return Axios.delete("CardProfile", {
      params: { Id: req },
    });
  }

  getCardProfileByScheme(req: any) {
    return Axios.get("CardProfile/format", {
      params: { scheme: req }
    })
  }

  getMerchantProfileByScheme(req: any) {
    return Axios.get("MerchantProfile/format", {
      params: { scheme: req }
    })
  }

  deleteMerchantProfile(req: any) {
    return Axios.delete("MerchantProfile", {
      params: { Id: req },
    });
  }

  copyCardProfile(req: any) {
    return Axios.get("CardProfile/copy", {
      params: { Id: req.cardProfileId, name: req.values.name }
    })
  }

  copyMerchantProfile(req: any) {
    return Axios.get("MerchantProfile/copy", {
      params: { Id: req.merchantProfileId, name: req.values.name }
    })
  }

  //Chip key
  CreateChipKey(req: any) {
    return Axios.post("Chipkey", req);
  }

  GetChipKeyByUserId(req: any) {
    return Axios.get("Chipkey/user", {
      params: { userId: req },
    });
  }

  getChipkeyById(req: any) {
    return Axios.get("Chipkey", {
      params: { Id: req },
    });
  }

  chipKeyUpdate(req: any) {
    return Axios.patch("Chipkey", req);
  }

  keySetUpdate(req: any) {
    return Axios.patch("MemberKey", req);
  }

  deleteChipKey(req: any) {
    return Axios.delete("Chipkey", {
      params: { Id: req },
    });
  }

  copyChipKey(req: any) {
    return Axios.get("ChipKey/copy", {
      params: { Id: req.chipKeyId, name: req.values.name }
    })
  }

  //Test Case
  CreateTestCase(req: any) {
    return Axios.post("TestCase/project", req);
  }

  getTestCasesByProjectId(req: any) {
    return Axios.get("TestCase/project", {
      params: { projectId: req },
    });
  }

  getTestCaseById(req: any) {
    return Axios.get("TestCase/project", {
      params: { testCaseId: req },
    });
  }

  executeTestCase(req: any){
    return Axios.get("TestCase/execute", {
      params: { testCaseId: req },
    });
  }

  bulkExecuteTestCase(req: any){
    const config = {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }
    const requestBody = {
      testCaseIds: req,
    };
    return Axios.post("TestCase/bulkExecute", requestBody, config)
  }

  updateTestCase(req: any) {
    return Axios.patch("TestCase/project", req);
  }

  copyProjectTestcase(req: any) {
    return Axios.get("TestCase/project/copy", { params: { testCaseId: req.testCaseId, testCaseName: req.values.name, projectId: req.projectId }});
  }

  getTestCaseGroups(req: any) {
    return Axios.get("TestCase/project/groups", {
      params: { projectId: req.projectId, testPlanName: req.testPlanName },
    });
  }

  SelectCategoryTestCases(req: any) {
    const paginationDto = {
      PageNo: req.pageNo,
      PageSize: req.pageSize
    }
    return Axios.post("TestCase/project/selectCategory", paginationDto, {
      params: { category: req.category, testPlanId: req.testPlanId, projectId: req.projectId, searchedString: req.searchedValue },
    });
  }

  SearchLogs(req: any) {
    const logSearchDto = {
      TestCaseId : req.testCaseId,
      Field7 : req.values.Field7,
      Field11: req.values.Field11,
      Field12 : req.values.Field12
    }
    return Axios.post("TestCase/searchLog", logSearchDto);
  }

  getDataFields(req: any) {
    return Axios.get("Profile/dataFields", {
      params: {
        scheme: req.scheme,
        MTI: req.mti,
        testCaseId: req.testCaseId,
        schemeSpecific: req.schemeSpecific,
        profileIDNo: req.profileIDNo
      },
    });
  }

  saveConfiguration(req: any) {
    return Axios.post("Profile/save", req);
  }

  createProfile(req: any) {
    return Axios.get("Profile/profile", {
      params: { mti: req.mti, testCaseId: req.testCaseId, isSchemeSpecific: req.isSchemeSpecific, scheme: req.scheme },
    });
  }

  deleteProfile(req: any) {
    return Axios.delete("Profile/profile", {
      params: { profileIDNo: req.profileIDNo, testCaseId: req.testCaseId, isSchemeSpecific: req.isSchemeSpecific, scheme: req.scheme },
    });
  }

  //Test Plan
  CreateTestPlan(req: any) {
    return Axios.post("TestPlan", req);
  }

  getTestPlansByUserId(req: any) {
    return Axios.get("TestPlan/user", {
      params: { userId: req },
    });
  }

  getTestPlanTestCasesByUserId(req: any){
    return Axios.get("TestCase/testPlan/user", {
      params: {userId:req.userId, scheme: req.scheme, version: req.version, systemUnderTest: req.systemUnderTest, testMode: req.testMode},
    });
  }

  getTestPlanTestCasesByProjectId(req: any){
    return Axios.get("TestCase/testPlanId", {
      params: {userId:req.userId, projectId: req.projectId, testPlanId: req.testPlanId},
    });
  }

  getTestPlansById(req: any) {
    return Axios.get("TestPlan", {
      params: { Id: req },
    });
  }

  testPlanUpdate(req: any) {
    return Axios.patch("TestPlan", req);
  }

  deleteTestPlan(req: any) {
    return Axios.delete("TestPlan", {
      params: { Id: req },
    });
  }

  copyTestPlan(req: any) {
    return Axios.get("TestPlan/copy", {
      params: { Id: req.testPlanId, name: req.values.name }
    })
  }

  getTestCasesByTestPlanId(req: any) {
    return Axios.get("TestCase/testPlan/all", {
      params: { testPlanId: req.id, scheme: req.scheme },
    });
  }

  createTestCaseByTestPlan(req: any) {
    return Axios.post("TestCase/testPlan",req);
  }

  updateTestCaseByTestPlan(req: any) {
    return Axios.patch("TestCase/testPlan",req,{
      params:{
        scheme:req.Scheme
      }
    });
  }

  getTestPlanTestCaseById(req: any) {
    return Axios.get("TestCase/testPlan",{
      params: { testCaseId: req.testCaseId, scheme: req.scheme },
    });
  }

  deleteTestCaseByTestPlan(req: any) {
    return Axios.delete("TestCase/testPlan",{
      params: { Id: req.testCaseId, scheme: req.scheme },
    });
  }

  copyTestPlanTestCase(req: any) {
    return Axios.get("TestCase/testPlan/copy",{
      params: { testCaseId: req.testCaseId, testCaseName: req.values.name, testPlanId: req.TestPlanId, scheme: req.scheme },
    });
  }

  getTestCaseGroupsByTestPlanId(req: any) {
    return Axios.get("TestCase/testPlan/groups",{
      params: { testPlanId: req },
    });
  }

  getTestPlanGroups(req: any) {
    return Axios.get("Project/groups", {
      params: { projectId: req },
    });
  }

  SelectCategoryTestCasesByTestPlanId(req: any) {
    return Axios.get("TestCase/testPlan/selectCategory",{
      params: { category: req.category, testPlanId: req.testPlanId },
    });
  }

  //Master Data
  fetchMasterData() {
    return Axios.get("MasterData");
  }

  //Users
  GetAllUser() {
    return Axios.get("User/all");
  }

  getHostLog(req: any) {
    return Axios.get("TestCase/hostLog", {
      params: { testCaseId: req },
    });
  }

  downloadLogs(req: any) {
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer'
    };
    return Axios.post('TestCase/downloadLogs', req, config)
  }

  acceptHostLogErrors(req: any) {
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'application/json' },
      params: { testCaseId: req.testCaseId, stream: req.stream, key: req.key }
    };
    return Axios.post('TestCase/acceptErrors', req.errors, config)
  }

  rejectHostLogErrors(req: any) {
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'application/json' },
      params: { testCaseId: req.testCaseId, stream: req.stream, key: req.key }
    };
    return Axios.post('TestCase/rejectErrors', req.errors, config)
  }

  getPassCriteriaLog(req: any) {
    return Axios.get("TestCase/passCriteriaLog", {
      params: { testCaseId: req },
    });
  }

  importTestPlan(req: any){
    const config = {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }
    return Axios.post("TestCase/importTestPlan", req, config)
  }

  getLogs(req: any){
    return Axios.post("SystemLogs", req);
  }

  getKeySet(req: any){
    return Axios.get("MemberKey", {
      params: { Id: req.storedProjectId, keyName: req.keyName },
    })
  }

  checkConnectionStatus(req: any){
    return Axios.get("Project/connectionStatus", {
      params: {projectId: req}
    })
  }

  checkStatus(){
    return Axios.get("MasterData/status")
  }

  checkUniqueAcqIdTerId(req: any){
    return Axios.get("Project/checkUniqueAcqTerId",{
      params: {acquirerId: req.acquirerId, terminalId: req.terminalId, testMode: req.testMode, systemUnderTest: req.systemUnderTest}
    })
  }

  checkProjectUniquePANAndAmount(req: any){
    return Axios.get("TestCase/project/validatePanAmount",{
      params: {projectId: req.projectId, testPlanId: req.testPlanId, pan: req.pan, amount: req.amount}
    })
  }

  checkTestPlanUniquePANAndAmount(req: any){
    return Axios.get("TestCase/testPlan/validatePanAmount",{
      params: {testPlanId: req.testPlanId, pan: req.pan, amount: req.amount}
    })
  }

  getDashboardData(req: any){
    return Axios.get("Dashboard", {params: {userId: req}})
  }

  getOrganizations(){
    return Axios.get("Organization/all")
  }

  CreateOrganization(req: any) {
    return Axios.post("Organization",req);
  }

  UpdateOrganization(req: any) {
    return Axios.patch("Organization",req);
  }

  DeleteOrganization(req: any) {
    return Axios.delete("Organization", {
      params: { Id: req },
    });
  }

  UpdateProjectTestPlanStatus(req: any) {
    return Axios.get("Project/testPlanStatus", {
      params: {projectId: req.projectId, testPlanId: req.testPlanId}
    })
  }

  UpdateProjectTestCaseStatus(req: any) {
    return Axios.get("TestCase/testCaseStatus", {
      params: {projectId: req.projectId, testCaseId: req.testCaseId}
    })
  }
}

let lithosApiServices = new LithosApiServices()

export default lithosApiServices;
