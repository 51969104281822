import { Pie } from "@ant-design/plots";

export default function PieChart(chartData: any) {
  const data = chartData.chartData ? chartData.chartData : [];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "status",
    radius: 0.8,
    color: ["#0079BE", "#EC6B29"],
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };
  return <Pie className="piechart-style" {...config} />;
}
