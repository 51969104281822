import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import LithosApiReducers from "./Reducers/LithosApiReducers";
import UtilReducer from "./Reducers/UtilReducer";

const combineReducers = {
  utilReducer: UtilReducer,
  lithosApiReducer: LithosApiReducers
};

export const Store = configureStore({
  reducer: combineReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

setupListeners(Store.dispatch);
export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
