import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons"

export const ShowToaster = (response: any, api: any) =>{
  response.responseCode === 'OK' ? (
    api.success({
      message: `${response.responseMessage}`,
      placement: "top",
      style: {
        width: 500,
      },
      icon: <CheckCircleTwoTone twoToneColor={'#8BAB58'}/>
    })
  ) : (
    api.error({
      message: `${response.responseMessage}`,
      placement: 'top',
      style: {
        width: 500,
      },
      icon: <WarningTwoTone twoToneColor={'#D13F3F'}/>,
    })
  )
}