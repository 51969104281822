import {Col, Form, Input, Modal, Row} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IModal } from "../../Interface/Interfaces";
import { FormatSelectOption, getPatternByFormat } from "../../Utils/Utils";
import FloatingLabelDatePicker from "../../Components/FloatingLabel/DatePicker/FloatingLabelDatePicker";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { getCardProfileByScheme } from "../../Redux/Actions/Actions";

const CreateEditCardProfileModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value, isUpdate  }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [cardNames, setCardNames] = useState<any>([''])
  const [schemeOptions, setSchemeOptions] = useState<any>([])
  const [chipKeyOptions, setChipKeyOptions] = useState<any>([])
  const [scheme, setScheme] = useState('')
  const [cardProfile, setCardProfile] = useState<any>(value)

  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  )
  const chipkeyDtoList = useSelector(
    (state: any) => state.lithosApiReducer.chipkeyDtoList
  )
  const cardProfileList = useSelector(
    (state: any) => state.lithosApiReducer.cardProfileList
  )

  const initialValues = {
    scheme: cardProfile?.scheme,
    pan: cardProfile?.pan?.value,
    cvV2: cardProfile?.cvV2?.value,
    cvv: cardProfile?.cvv,
    icvv: cardProfile?.icvv,
    issuerName: cardProfile?.issuerName,
    pin: cardProfile?.pin?.value,
    track1: cardProfile?.track1?.value,
    track2: cardProfile?.track2?.value,
    chipKeyId: cardProfile?.chipKeyId,
    id: cardProfile?.id,
  };

  useEffect(() => {
    cardProfileList?.map((card: any) => (
      card?.name !== "" && !cardNames.includes(card.name) && setCardNames((cardNames) => [...cardNames, card.name.toLowerCase()])
    ))
    const schemes = masterData?.map((S: any) => ({
      value: S.schemeName,
      label: S.schemeName.toUpperCase(),
    }));
    setSchemeOptions(schemes)
    if (schemes?.length === 1) {
      form.setFieldsValue({
        scheme: schemes[0]?.value
      });
      setScheme(schemes[0]?.value?.toUpperCase())
    }
    if(isUpdate){
      setScheme(cardProfile?.scheme?.toUpperCase())
    }
  }, []);

  useEffect(() => {
    if(scheme !== "" && scheme !== undefined){
      dispatch(getCardProfileByScheme(scheme)).then((res: any) => {
        setCardProfile(res?.payload?.cardProfile)
      });
    }
    const selectedTestCases = chipkeyDtoList?.filter(chipKey => chipKey?.scheme === scheme);
    const chipKeys = FormatSelectOption(selectedTestCases)
    setChipKeyOptions(chipKeys)
    if (chipKeys?.length === 1) {
      form.setFieldsValue({
        chipKeyId: chipKeys[0]?.value
      });
    }
  }, [scheme])

  return (
    <Modal
        className="create-card-profile-modal"
        title={isUpdate ? 'Update Card profile' : 'Create Card profile'}
        okText={isUpdate ? 'Update' : 'Create'}
        centered
        cancelText="Discard"
        open={isOpen}
        onOk={()=>form.submit()}
        onCancel={()=>{onClose();form.resetFields()}}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          {!isUpdate && <>
            <Form.Item
              className="modal-form"
              name="name"
              rules={[
                { required: true, message: "Name cannot be empty!" },
                {
                  message: 'Cardprofile name already exists!',
                  validator: (_, val) => {
                    if (val !== "" && cardNames.includes(val.toLowerCase().trim())) {
                      return Promise.reject(new Error('Cardprofile name already exists!'));
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            > 
              <FloatingLabelInput label='Name' placeholder="Name" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="scheme"
              rules={[{ required: true, message: "Scheme cannot be empty!" }]}
            >
            <FloatingLabelSelect
                label="Scheme"
                showSearch
                className="modal-input"
                optionFilterProp="children"
                options={schemeOptions}
                onChange={(e) => {          
                  setScheme(e.toUpperCase());
                }}
              />
            </Form.Item>
          </>}
          <Form.Item
              className="modal-form"
              name="issuerName"
              rules={[
                { required: true, message: "Issuer name cannot be empty!" },
              ]}
            >
              <FloatingLabelInput label='Issuer name' placeholder="Issuer name" />
            </Form.Item>
          <Form.Item
            className="modal-form"
            name="pan"
            rules={[
              { required: true, message: "PAN cannot be empty!" },
              {
                message: `PAN should be in ${cardProfile?.pan?.format} format and length shall be ${cardProfile?.pan?.minLength} to ${cardProfile?.pan?.maxLength} digits!`,
                validator: (_, val) => {
                  if (!getPatternByFormat(cardProfile?.pan?.format).test(val)) {
                    return Promise.reject();
                  } else if (val !== "" && (val?.length < cardProfile?.pan?.minLength || val?.length > cardProfile?.pan?.maxLength)) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                }
              }
          ]}
          >
            <FloatingLabelInput label='PAN' placeholder="PAN" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="pin"
            rules={[
              { required: true, message: "PIN cannot be empty!" },
              {
                message: `PIN length shall be ${cardProfile?.pin?.minLength} to ${cardProfile?.pin?.maxLength} digits!`,
                validator: (_, val) => {
                  if (val !== "" && (val?.length < cardProfile.pin?.minLength || val?.length > cardProfile.pin?.maxLength)) {
                    return Promise.reject(new Error(`PIN length shall be ${cardProfile?.pin?.minLength} to ${cardProfile?.pin?.maxLength} digits!`));
                  } else {
                    return Promise.resolve();
                  }
                }
              }
          ]}
          >
            <FloatingLabelInput label="PIN" placeholder="PIN" />
          </Form.Item>
          <Row className="card-profile-input-row" gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                className="modal-form"
                name="cvv"
                rules={[
                  { required: true, message: "CVV cannot be empty!" },
                  { pattern: /^\d{3}$/, message: "CVV should be 3-digit in numeric format" },
                ]}
              >
                <FloatingLabelInput label='CVV' placeholder="CVV" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="modal-form"
                name="cvV2"
                rules={[
                  { required: true, message: "CVV2 cannot be empty!" },
                  { pattern: getPatternByFormat(cardProfile?.cvV2?.format), message: `CVV2 should be ${cardProfile?.cvV2?.maxLength} digit in ${cardProfile?.cvV2?.format} format` },
                  { max: cardProfile?.cvV2?.maxLength, message: `CVV2 should be maximum of ${cardProfile?.cvV2?.maxLength} digit`},
                  { min: cardProfile?.cvV2?.minLength, message: `CVV2 should be minimum of ${cardProfile?.cvV2?.maxLength} digit`}
                ]} 
              >
                <FloatingLabelInput label='CVV2' placeholder="CVV2" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            className="modal-form"
            name="icvv"
            rules={[
              { required: true, message: "ICVV cannot be empty!" },
              { pattern: /^\d{3}$/, message: "ICVV should be 3-digit in numeric format" },
            ]}
          >
            <FloatingLabelInput label='ICVV' placeholder="ICVV" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="track1"
            rules={[{ required: true, message: "Track1 cannot be empty!" }]}
          >
            <FloatingLabelInput label='Track1' placeholder="Track1" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="track2"
            rules={[{ required: true, message: "Track2 cannot be empty!" }]}
          >
          <FloatingLabelInput label='Track2' placeholder="Track2" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="chipKeyId"
            rules={[
              { required: true, message: "Please select the chipkey!" },
            ]}
          >
              <FloatingLabelSelect
              label="Chip key"
              showSearch
              className="modal-input"
              optionFilterProp="children"
              options={chipKeyOptions}
            />
          </Form.Item>
          <FloatingLabelDatePicker
            label="Expiry Date"
            name="expiryDate"
            picker="month"
            format="YYMM"
            initialValue={cardProfile ? dayjs(cardProfile?.expiryDate?.value, 'YYMM') : dayjs(dayjs().format("YYMM"), "YYMM")}
          />
          <Form.Item hidden name="id">
            <Input  />
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default CreateEditCardProfileModal;
