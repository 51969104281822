import { useEffect, useRef, useState } from "react";
import {
  BreadCrumbConfig,
  HeaderTitle,
} from "../../Redux/Reducers/UtilReducer";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import { Col, Input, Row, Space, Tooltip, notification } from "antd";
import TableContainer from "../../Components/Table/TableContainer";
import { useNavigate } from "react-router-dom";
import {
  SelectCategoryTestCasesByTestPlanId,
  createTestCaseByTestPlan,
  deleteTestCaseByTestPlan,
  getTestCaseGroupsByTestPlanId,
  copyTestPlanTestCase,
  getTestPlanTestCaseById,
  updateTestCaseByTestPlan,
} from "../../Redux/Actions/Actions";
import {
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import SelectionIcon from "../../Assets/selectionIcon.svg";
import { ColumnsType } from "antd/es/table";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import Delete from "../../Assets/Delete.svg"
import Copy from "../../Assets/Copy.svg"
import Edit from "../../Assets/Edit.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import Configure from "../../Assets/Configure.svg"
import { SchemeTag } from "../../Constants/Constants";
import { ShowToaster } from "../../Components/Notifications/Notifications";
import CreateEditTestCaseModal from "./CreateEditTestCaseModal";

export default function TestPlanTestCase() {
  const dispatch = useDispatch();
  const [isCreate, setIsCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testCaseValue, setTestCaseValue] = useState<any>();
  const [testCaseGroups, setTestCaseGroups] = useState<any>();
  let TestPlanId = localStorage.getItem("TestPlanId");
  const [scheme, setScheme] = useState('')
  const [api, contextHolder] = notification.useNotification();
  const [isDelete, setIsDelete] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false);
  const [testCaseId, setTestCaseId] = useState('')
  const [searchedText, setSearchedText] = useState('')
  const navigate = useNavigate()
  const [testCaseNames, setTestCaseNames] = useState([''])
  const [testCaseData, setTestCaseData] = useState<any>()

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );

  const cardProfileList = useSelector(
    (state: any) => state.lithosApiReducer.cardProfileList
  );
  const merchantProfileList = useSelector(
    (state: any) => state.lithosApiReducer.merchantProfileList
  )

  const scrollContainerRef: any = useRef(null);

  const handleScroll = (direction: string) => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const scrollDistance = 200;

    if (direction === "prev") {
      container.scrollBy({
        left: -scrollDistance,
        behavior: "smooth",
      });
    } else if (direction === "next") {
      container.scrollBy({
        left: scrollDistance,
        behavior: "smooth",
      });
    }
  };

  const isScrollable =
  scrollContainerRef.current &&
  scrollContainerRef.current.scrollWidth >
    scrollContainerRef.current.clientWidth;

  const oncloseModal = () => {
    setIsCreate(false)
    setIsDelete(false)
    setIsUpdate(false)
    setIsCopy(false)
  };

  const SelectCategory = (groupName: any) => {
    setLoading(true)
    localStorage.setItem('SelectedTestPlanCard', groupName);
    dispatch(SelectCategoryTestCasesByTestPlanId({
      category: groupName,
      testPlanId: TestPlanId,
    })).then((data: any) => {
      setTestCaseData(data?.payload)
      data?.payload?.map((testCase: any) => (
        testCase?.name !== "" && !testCaseNames?.includes(testCase.name) && setTestCaseNames((testCaseNames: any) => [...testCaseNames, testCase.name.toLowerCase()])
      ))
      setLoading(false)
    });
  }

  useEffect(() => {
    setLoading(true);
    dispatch(HeaderTitle("Test cases"));
    dispatch(
      BreadCrumbConfig({
        title: "Test cases",
        href: "/testplan/testcases",
        preventPush: false,
      } as any)
    );
    RenderHandler()
  }, []);
  
  const OnClickEvent = () => {
    setIsCreate(true);
  };

  const RenderHandler = () => {
    dispatch(getTestCaseGroupsByTestPlanId(TestPlanId)).then((data: any) => {
      setTestCaseGroups(data?.payload);
      setLoading(false);
      let selectedCard = localStorage.getItem('SelectedTestPlanCard') 
      if(selectedCard === '' || !data?.payload?.find((item) => item.name === selectedCard)){
          SelectCategory(data?.payload[0]?.name)
      }
      else{   
        SelectCategory(selectedCard)
      }
    });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (isCreate === true) {
      values.TestPlanId = TestPlanId;
      dispatch(createTestCaseByTestPlan(values)).then((res) => {
        RenderHandler()
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsCreate(false)
      });
    } else if(isUpdate === true) {
      dispatch(updateTestCaseByTestPlan(values)).then((res) => {
        RenderHandler()
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsUpdate(false)
        setTestCaseValue(res?.payload?.testCase)
      });
    }else if(isDelete === true){
      dispatch(deleteTestCaseByTestPlan({testCaseId , scheme})).then((res) => {
        RenderHandler()
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsDelete(false)
      });
    } else if(isCopy === true){
      dispatch(copyTestPlanTestCase({testCaseId, values, TestPlanId , scheme})).then((res) => {
        RenderHandler()
        setLoading(false)
        ShowToaster(res.payload, api)
        setIsCopy(false)
      }); 
    }
  };

  const rowClassName = (record) => {
    if (testCaseValue && testCaseValue?.id === record.id) {
      return 'selected-row';
    }
    return '';
  };

  const columns: ColumnsType = [
    {
      title: "Test case",
      dataIndex: "name",
      key: "name",
      width: 330,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase())
      },
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {
            setTestCaseValue(record);
          },
        };
      },
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer" }}>
          {text}
        </div>
      ),
    },
    {
      ...(userType === 'TESTER'
      ? {
          title: "Actions",
          key: "actions",
          dataIndex: "actions",
          render: (text: any, record: any) => (
            <>
            {userType === "TESTER" &&
            <div className="combined-icon">
            <Space size={"large"}>
              {!record?.isCertification && 
              <div
                onClick={() => {
                  setIsCopy(true);
                  setTestCaseId(record.id)
                  setScheme(record.scheme)
                }}
                className="project-content"
              >
                <Tooltip title="Copy">
                  <img className="icons" src={Copy} />
                </Tooltip>
              </div>}
              <div
                onClick={() => {
                    setTestCaseValue(record)
                    setIsUpdate(true)
                }}
                className="project-content"
              >
                <Tooltip title="Edit">
                  <img className="icons" src={Edit} />
                </Tooltip>
              </div>
              <div className="project-content"
                  onClick={() => {
                    dispatch(getTestPlanTestCaseById({ testCaseId: record.id, scheme: record.scheme })).then(() => {
                      navigate({
                        pathname: "configuration",
                        search: `?scheme=${record.scheme}&mti=${record.mti}&testCaseId=${record.id}&isSchemeSpecific=${true}`,
                      })
                    })
                  }}
              >
                  <Tooltip title={`${record?.isCertification ? 'View Configuration' : 'Configure'}`}>
                  <img className="icons" src={Configure} />
                  </Tooltip>
              </div>
              {!record?.isCertification && 
              <div
                onClick={() => {
                  setIsDelete(true);
                  setTestCaseId(record.id)
                  setScheme(record.scheme)
                }}
                className="project-content"
              >
                  <Tooltip title="Delete">
                    <img className="icons" src={Delete} />
                </Tooltip>
              </div>}
            </Space>
          </div>
            }</>
          ),
        } : null),
    },
  ];

  const testCaseTableData: any = testCaseData;

  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false} SelectedIds={null} />
      <div className="test-case-card-container">
        {isScrollable && (<div onClick={() => handleScroll("prev")} className="scroll-button left">
          <LeftOutlined />
        </div>)}
        <div className="test-case-card-container" ref={scrollContainerRef}>
          {testCaseGroups?.map((T: any, index: any) => {
            return (
              <div
              onClick={() => {
                SelectCategory(T.name);
                setTestCaseValue("")
              }}
              className={
                T.name === localStorage.getItem('SelectedTestPlanCard') 
                  ? "test-case-card content active"
                  : "test-case-card content"
              }
                key={T.name}
              >
                <div className="mti-value">{T.name}</div>
                <div style={{color: '#8A98Ac'}}>Total : {T.total}</div>
              </div>
            );
          })}
        </div>
        {isScrollable && (<div onClick={() => handleScroll("next")} className="scroll-button right">
          <RightOutlined />
        </div>)}
      </div>
      <Row className="card-profile-row">
        <Col className="test-case-col" xs={2} sm={4} md={8} lg={8} xl={8}>
        <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {
              const currValue = e.target.value;
              setSearchedText(currValue)
            }}/>
          <TableContainer
            tableData={testCaseTableData}
            column={columns}
            className={"card-profile-table"}
            loading={loading}
            scrollY='65vh'
            rowClassName={rowClassName}
          />
        </Col>
        <Col
          className="test-case-col scrollable-div"
          span={1}
          xs={2}
          sm={4}
          md={15}
          lg={15}
          xl={15}
          style={{marginTop: '11px'}}
        >
          {testCaseValue?.name === undefined ? (
            <div className="testplan-container">
              <img className="testplan-img" src={SelectionIcon} alt="" />
              <div className="card-profile-title">
                Please select a test case
              </div>
            </div>
          ) : (
            <div className="testplan-content-container">
              <div className="card-profile-title-name">{testCaseValue?.name}</div>
              <div className="test-case-data-container">
                <div className="card-content-container">
                  <div className="card-key-title">Scheme</div>
                  <div className="card-value">{SchemeTag[testCaseValue.scheme] || testCaseValue.scheme}</div>
                </div>
                <div className="card-content-container">
                  <div className="card-key-title">Version</div>
                  <div className="card-value">{testCaseValue?.version}</div>
                </div>
                {testCaseValue?.matchPan &&             
                  <div className="card-content-container">
                    <div className="card-key-title">Match PAN</div>
                    <div className="card-value">{testCaseValue?.matchPan}</div>
                  </div>}
                {testCaseValue?.matchAmount &&             
                  <div className="card-content-container">
                    <div className="card-key-title">Match amount</div>
                    <div className="card-value">{testCaseValue?.matchAmount}</div>
                  </div>}
                {cardProfileList?.map((card: any) => {
                  if (card?.id === testCaseValue?.cardID) {
                    return (
                      <div className="card-content-container" key={card.id}>
                        <div className="card-key-title">Card</div>
                        <div className="card-value" key={card.name}>{card?.name}</div>
                      </div>
                    );
                  }
                  return null; 
                })}
                {merchantProfileList?.map((merchantProfile: any) => {
                  if (merchantProfile?.id === testCaseValue?.merchantProfileID) {
                    return (
                      <div className="card-content-container" key={merchantProfile.id}>
                        <div className="card-key-title">Merchant profile</div>
                        <div className="card-value" key={merchantProfile.name}>{merchantProfile?.name}</div>
                      </div>
                    );
                  }
                  return null; 
                })}
                <div className="card-content-container">
                  <div className="card-key-title">Description</div>
                  <div className="card-value">{ testCaseValue?.description !== null ? testCaseValue?.description : `N/A` }</div>
                </div>
                <div className="card-content-container">
                  <div className="card-key-title">Test information</div>
                  <div className="card-value" dangerouslySetInnerHTML={{__html: testCaseValue?.objective !== null ? testCaseValue?.objective : `N/A`}} />
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {isCreate && <CreateEditTestCaseModal
        isOpen={isCreate}
        onClose={oncloseModal}
        onFinish={onFinish}
        isUpdate={false}
      />}
      {isUpdate && <CreateEditTestCaseModal
        isOpen={isUpdate}
        onClose={oncloseModal}
        onFinish={onFinish}
        value={testCaseValue}
        isUpdate={true}
      />}
      {isDelete && <DeleteModal
        isOpen={isDelete}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Testcase"
        values={undefined}
      />}
      {isCopy && <CopyModal
        isOpen={isCopy}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Testcase"
        values={testCaseNames}
      />}
    </>
  );
}
