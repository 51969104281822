import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';

const Loader = ({ loading, children }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 28 }} spin />
  const [dots, setDots] = useState('.');

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setDots((prevDots) => {
          if (prevDots === '...') {
            return '.';
          } else {
            return prevDots + '.';
          }
        });
      }, 400);
    } else {
      setDots('.');
    }
    return () => clearInterval(interval);
  }, [loading]);

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div className='loader'>
          <Spin indicator={antIcon} /> <p className='loading-text'>Please wait, loading{dots}</p>
        </div>
      )}
      {children}
    </div>
  );
};

export default Loader;

