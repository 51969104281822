import { createSlice } from "@reduxjs/toolkit";
import { ILithosApi } from "../../Interface/Interfaces";
import { AuthorizationHeader, JsonParse, handleLogoutOrEndSession } from "../../Utils/Utils";
import {
  CreateCardProFile,
  CreateChipKey,
  CreateProject,
  CreateTestCase,
  CreateTestPlan,
  GetAllUser,
  getCardProfileById,
  getCardProfilesByUserId,
  getChipkeyById,
  getChipKeyByUserId,
  getHostLog,
  getProjectById,
  getProjectsByUserId,
  getTestCaseById,
  getTestPlanTestCaseById,
  getTestCaseGroups,
  getTestCasesByProjectId,
  getTestCasesByTestPlanId,
  getTestPlansById,
  getTestPlansByUserId,
  getTestPlanTestCasesByUserId,
  LoginHandler,
  SelectCategoryTestCases,
  SelectCategoryTestCasesByTestPlanId,
  getKeySet,
  fetchMasterData,
  getTestCaseGroupsByTestPlanId,
  Logout,
  EndSession,
  getMerchantProfilesByUserId,
  getMerchantProfileById,
  ChangePassword,
  getOrganizations,
  getUsersByType,
  getTestPlanTestCasesByProjectId,
  CreateOrganization,
  CreateUser,
  CreateMerchantProFile,
  copyCardProfile,
  copyMerchantProfile,
  copyChipKey,
  copyTestPlan,
  deleteProfile,
  deleteProject,
  deleteCardProfile,
  deleteMerchantProfile,
  deleteChipKey,
  deleteTestPlan,
  deleteUser,
  DeleteOrganization,
} from "../Actions/Actions";
import { AddAxiosHeaders, removeAxiosHeader } from "../../ServerConfig/Axios";
import Cookies from 'js-cookie';

type UserDetailsType = {
  Name: string;
  Id: string;
  Email: string;
  Organization: string;
};

const initialState: ILithosApi = {
  userDetails: {
    Name: "",
    Id: "",
    Email: "",
    Organization: "",
  },
  token: "",
  isAuth: false,
  projectDtoList: null,
  cardProfileList: null,
  merchantProfileList: null,
  chipkeyDtoList: null,
  projectDto: null,
  testPlanDtoList: null,
  testCaseDtoList: null,
  masterData: null,
  userType: null,
  testPlanDto: null,
  chipkeyDto: null,
  cardProfileDto: null,
  merchantProfileDto: null,
  testCaseDto:null,
  testCaseGroups:null,
  categoryTestCases:null,
  categoryTestCasesByTestPlan:null,
  usersList:null,
  hostLog:null,
  testCaseList:null,
  keySet:null,
  loading: false,
  sideBarConfig: null,
  orgList: null,
  forbiddenError: false,
  notification: null,
};

export const LithosApiSlice = createSlice({
  name: "lithosApiSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setForbiddenError: (state, action) => {
      state.forbiddenError = action.payload
    },
    NotificationHandler: (state, action) => {
      state.notification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //login
      .addCase(LoginHandler.fulfilled, (state, { payload }) => {
        if (payload.responseCode === "OK") {
          const userDetails = JsonParse(payload.userObject)
          state.userDetails = Object.fromEntries(
            Object.entries(userDetails).filter(([key]) =>
              Object.keys(initialState.userDetails).includes(key)
            )
          ) as UserDetailsType;
          localStorage.setItem("UserDetails", JSON.stringify(state.userDetails));
          state.isAuth = true;
          localStorage.setItem("IsAuth", "true");
          Cookies.set('token', payload.token, { expires: 7, secure: false });
          state.token = payload.token;
          const authHeader = AuthorizationHeader(payload.token)
          AddAxiosHeaders(authHeader)
        }
      })
      .addCase(Logout.fulfilled, (state, { payload }) => {
        handleLogoutOrEndSession(state);
      })
      .addCase(ChangePassword.fulfilled, (state, { payload }) => {
        if (payload.responseCode === "OK") {
          state.isAuth = true;
          localStorage.setItem("IsAuth", "true");
        }
      })
      .addCase(EndSession.fulfilled, (state, { payload }) => {
        handleLogoutOrEndSession(state);
      })
      .addCase(fetchMasterData.fulfilled, (state, { payload }) => {
        state.masterData = payload?.masterData;
        state.userType = payload?.userType;
      })
      //project
      .addCase(CreateProject.fulfilled, (state, { payload }) => {
        return {
          ...state,
          projectDtoList: [...state.projectDtoList, payload.project]
        };
      })
      .addCase(deleteProject.fulfilled, (state, { payload }) => {
        return {
          ...state,
          projectDtoList: state.projectDtoList.filter(project => project.id !== payload.projectId)
        };
      })  
      .addCase(getProjectsByUserId.fulfilled, (state, { payload }) => {
        state.projectDtoList = payload.projectList;
      })
      .addCase(getProjectById.fulfilled, (state, { payload }) => {
        state.projectDto = payload.project;
      })
      //card profile
      .addCase(CreateCardProFile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          cardProfileList: [...state.cardProfileList, payload.cardProfile]
        };
      })
      .addCase(deleteCardProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          cardProfileList: state.cardProfileList.filter(cp => cp.id !== payload.cardId)
        };
      })  
      .addCase(copyCardProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          cardProfileList: [...state.cardProfileList, payload.cardProfile]
        };
      })
      .addCase(getCardProfilesByUserId.fulfilled, (state, { payload }) => {
        state.cardProfileList = payload.cardProfileList;
      })
      .addCase(getCardProfileById.fulfilled, (state, { payload }) => {
        state.cardProfileDto = payload.cardProfile;
      })
      //merchantProfile
      .addCase(CreateMerchantProFile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          merchantProfileList: [...state.merchantProfileList, payload.merchantProfile]
        };
      })
      .addCase(deleteMerchantProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          merchantProfileList: state.merchantProfileList.filter(mp => mp.id !== payload.merchantProfileById)
        };
      })  
      .addCase(copyMerchantProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          merchantProfileList: [...state.merchantProfileList, payload.merchantProfile]
        };
      })
      .addCase(getMerchantProfilesByUserId.fulfilled, (state, { payload }) => {
        state.merchantProfileList = payload.merchantProfileList;
      })
      .addCase(getMerchantProfileById.fulfilled, (state, { payload }) => {
        state.merchantProfileDto = payload.merchantProfile;
      })
      //chip key
      .addCase(CreateChipKey.fulfilled, (state, { payload }) => {
        return {
            ...state,
            chipkeyDtoList: [...state.chipkeyDtoList, payload.keySet]
        };
      })   
      .addCase(deleteChipKey.fulfilled, (state, { payload }) => {
        return {
          ...state,
          chipkeyDtoList: state.chipkeyDtoList.filter(ck => ck.id !== payload.keySetId)
        };
      })   
      .addCase(copyChipKey.fulfilled, (state, { payload }) => {
        return {
            ...state,
            chipkeyDtoList: [...state.chipkeyDtoList, payload.keySet]
        };
      })    
      .addCase(getChipKeyByUserId.fulfilled, (state, { payload }) => {
        state.chipkeyDtoList = payload.keySetList;
      })
      .addCase(getChipkeyById.fulfilled, (state, { payload }) => {
        state.chipkeyDto = payload.chipKey;
      })
      //test case
      .addCase(CreateTestCase.fulfilled, (state, { payload }) => {
      })
      .addCase(getTestCasesByProjectId.fulfilled, (state, { payload }) => {
        state.testCaseDtoList = payload;
      })
      .addCase(getTestCaseById.fulfilled, (state, { payload }) => {
        state.testCaseDto = payload.testCase;
      })
      .addCase(getTestPlanTestCaseById.fulfilled, (state, { payload  }) => {
        state.testCaseDto = payload.testCase;
      })
      //groups
      .addCase(getTestCaseGroupsByTestPlanId.fulfilled, (state, {payload}) => {
        state.testCaseGroups = payload;
      })
      .addCase(getTestCaseGroups.fulfilled, (state, { payload }) => {
        state.testCaseGroups = payload;
      })
      .addCase(SelectCategoryTestCases.fulfilled, (state, { payload }) => {
        state.categoryTestCases = payload.testCaseList;
      })
      .addCase(getHostLog.fulfilled, (state, { payload }) =>{
        state.hostLog = payload?.FinancialTransactionsLogs;
      })
      //Test plan
      .addCase(CreateTestPlan.fulfilled, (state, { payload }) => {
        return {
          ...state,
          testPlanDtoList: [...state.testPlanDtoList, payload.testPlan]
        };
      })
      .addCase(deleteTestPlan.fulfilled, (state, { payload }) => {
        return {
          ...state,
          testPlanDtoList: state.testPlanDtoList.filter(tp => tp.id !== payload.testPlanId)
        };
      })  
      .addCase(copyTestPlan.fulfilled, (state, { payload }) => {
        return {
          ...state,
          testPlanDtoList: [...state.testPlanDtoList, payload.testPlan]
        };
      })
      .addCase(getTestPlansByUserId.fulfilled, (state, { payload }) => {
        state.testPlanDtoList = payload.testPlanList;
      })
      .addCase(getTestPlansById.fulfilled, (state, { payload }) => {
        state.testPlanDto = payload.testPlan;
      })
      .addCase(getTestCasesByTestPlanId.fulfilled, (state, { payload }) => {
        state.testCaseDtoList = payload.testCaseList;
      })
      .addCase(getTestPlanTestCasesByUserId.fulfilled, (state, { payload }) => {
        state.testCaseList = payload;
      })
      .addCase(getTestPlanTestCasesByProjectId.fulfilled, (state, { payload }) => {
        state.testCaseList = payload;
      })
      .addCase(SelectCategoryTestCasesByTestPlanId.fulfilled, (state, { payload }) => {
        state.categoryTestCasesByTestPlan = payload;
      })
      //Users
      .addCase(CreateUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          usersList: [...state.usersList, payload.user]
        };
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          usersList: state.usersList.filter(user => user.id !== payload.userId)
        };
      })  
      .addCase(GetAllUser.fulfilled, (state, { payload }) => {
        state.usersList = payload.userList;
      })
      .addCase(getUsersByType.fulfilled, (state, { payload }) => {
        state.usersList = payload.userList;
      })
      .addCase(getKeySet.fulfilled, (state, {payload}) => {
        state.keySet = payload.keySet;
      })
      //Organization
      .addCase(getOrganizations.fulfilled, (state, { payload}) => {
        state.orgList = payload.organizationList;
      })
      .addCase(CreateOrganization.fulfilled, (state, { payload }) => {
        return {
          ...state,
          orgList: [...state.orgList, payload.organization]
        };
      })
      .addCase(DeleteOrganization.fulfilled, (state, { payload }) => {
        return {
          ...state,
          orgList: state.orgList.filter(org => org.id !== payload.organizationId)
        };
      })  
    //loading matcher for pending, fulfilled and rejected state 
    .addMatcher(
      (action) => action.type.endsWith("/pending"),
      (state) => {
        state.loading = true;
      }
    )
    .addMatcher(
      (action) => action.type.endsWith("/fulfilled"),
      (state) => {
        state.loading = false;
      }
    )
    .addMatcher(
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        if (action?.error?.message?.includes("401")) {
          removeAxiosHeader("Authorization");
          state.isAuth = false;
          localStorage.setItem("IsAuth", "false");
          Cookies.remove("token");
          state.token = "";
        }
        else if(action?.error?.message?.includes('403')) {
          state.forbiddenError = true;
        }
        state.loading = false;
      }
    );
  },
});

export const { setForbiddenError, NotificationHandler } = LithosApiSlice.actions;
export default LithosApiSlice.reducer;

