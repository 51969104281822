import React from 'react'
import { IModalList } from '../../Interface/Interfaces';
import { Form, Modal } from 'antd';
import FloatingLabelInput from '../FloatingLabel/Input/FloatingLabelInput';

const CopyModal: React.FC<IModalList> = ({isOpen, onClose, onFinish, title, values: nameList}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      className="create-project-modal"
      title={`Copy ${title}`}
      open={isOpen}
      onOk={() => form.submit()}
      okText="Copy"
      centered
      cancelText="Discard"
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
    >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          autoComplete="off"
          onFinish={onFinish}
        >
        <Form.Item
            className="modal-form"
            name="name"
            rules={[{ required: true, message: "Please enter the name!" },
            {
              message: `${title} name already exists!`,
              validator: (_, value) => {
                if (value !== "" && nameList.includes(value.toLowerCase().trim())) {
                  return Promise.reject(new Error(`${title} name already exists!`))
                } else {
                  return Promise.resolve()
                }
              }
            }
          ]}
          >
            <FloatingLabelInput label='Name' placeholder="Name" />
          </Form.Item>
        </Form>
    </Modal>
  )
}

export default CopyModal;