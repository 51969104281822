import { ColumnsType } from "antd/es/table";
import { Key, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import TableContainer from "../../Components/Table/TableContainer";
import {
  BreadCrumbConfig,
  HeaderTitle,
} from "../../Redux/Reducers/UtilReducer";
import CreateEditChipKeyModal from "./CreateEditChipKeyModal";
import {
  CreateChipKey,
  deleteChipKey,
  chipKeyUpdate,
  copyChipKey,
  getChipKeyByUserId,
} from "../../Redux/Actions/Actions";
import { JsonParse } from "../../Utils/Utils";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Space, Tooltip, notification } from "antd";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import Edit from "../../Assets/Edit.svg"
import Copy from "../../Assets/Copy.svg"
import Delete from "../../Assets/Delete.svg"
import CopyModal from "../../Components/CopyModal/CopyModal";
import SelectionIcon from "../../Assets/selectionIcon.svg";
import EditableFormItem from "../../Components/InlineEdit/EditableFormItem";
import { ShowToaster } from "../../Components/Notifications/Notifications";
import { SchemeTag } from "../../Constants/Constants";

export default function ChipKey() {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [chipKey, setChipKey] = useState<any>({})
  const [loading, setLoading] = useState(false);
  const [chipkeyDtoList, setChipkeyDtoList] = useState(useSelector(
    (state: any) => state.lithosApiReducer.chipkeyDtoList
  ));
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const userDetails = JsonParse(localStorage.getItem("UserDetails"))
  const [chipKeyId, setChipKeyId] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [searchedText, setSearchedText] = useState('')
  const [keyNames, setKeyNames] = useState<any>([''])
  const [referredList, setReferredList] = useState<any>([])

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );
  
  useEffect(() => {
    dispatch(HeaderTitle("Chip keys"));
    dispatch(
      BreadCrumbConfig({
        title: "Chip keys",
        href: "/chipkeys",
        preventPush: true,
      } as any)
    );
    RenderHandler()
  }, []);

  const RenderHandler = () => {
    setLoading(true);
    dispatch(getChipKeyByUserId(userDetails?.Id)).then((data: any) => {
      setChipkeyDtoList(data.payload?.keySetList)
      data.payload?.keySetList?.map((key) => (
        key?.name !== "" && !keyNames.includes(key.name) && setKeyNames((keyNames) => [...keyNames, key.name.toLowerCase()])
      ))  
      setLoading(false);
    });
  };

  const oncloseModal = () => {
    setIsModalOpen(false)
    setIsUpdate(false)
    setIsDelete(false)
    setIsCopy(false)
  };

  const OnClickEvent = () => {
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);

  const buildFinalResult = () => {
    let result: any = {};
    let Properties: any = [];

    result.Name = values.Name;
    result.CreatedBy = values.CreatedBy;
    result.Id = values.Id;
    result.Scheme = values.scheme;

    delete values.Name;
    delete values.CreatedBy;
    delete values.Id;
    delete values.scheme;

    for (let key in values) {
      Properties.push({
        Type: key,
        Value: values[key],
        Kcv: null,
        PlainKey: false,
      });
    }
    result.Properties = Properties;
    
    return result;
  };
  
    if (isModalOpen) {
      const finalResult = buildFinalResult();
      dispatch(CreateChipKey(finalResult)).then((res) => {
        setLoading(false);
        ShowToaster(res.payload, api)
        setIsModalOpen(false);
        setChipkeyDtoList([res?.payload?.keySet, ...chipkeyDtoList])
      });
    } else if (isUpdate) {
      const finalResult = buildFinalResult();
      dispatch(chipKeyUpdate(finalResult)).then((res) => {
        setLoading(false);
        ShowToaster(res.payload, api)
        setIsUpdate(false);
        updateRecord(res)
      });
    } else if (isDelete) {
      dispatch(deleteChipKey(chipKeyId)).then((res) => {
        setLoading(false);
        ShowToaster(res.payload, api)
        setIsDelete(false);
        setChipkeyDtoList(chipKey =>
          chipKey.filter(mp => {
            return mp.id !== chipKeyId;
          }),
        );
        setKeyNames(names => 
          names.filter(name => {
            return name != chipKey?.name;
          }))
      });
    } else if (isCopy) {
      dispatch(copyChipKey({ values, chipKeyId })).then((res) => {
        setLoading(false);
        ShowToaster(res.payload, api)
        setIsCopy(false);
        setChipkeyDtoList([res?.payload?.keySet, ...chipkeyDtoList])
      });
    }
  };

  const columns: ColumnsType = [
    {
      title: "Chip key",
      dataIndex: "name",
      key: "name",
      width: 330,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record?.name).toLowerCase().includes(value.toLowerCase())
      },
      onCell: (record: any) => {
        return {
          onClick: (D: any) => {
            setChipKey(record);
            setChipKeyId(record?.id)
          },
        };
      },
      render: (text) => (
        <div className="project-content" style={{ cursor: "pointer" }}>
          {text}
        </div>
      ),
    },
    {...(userType === 'TESTER')
    ? {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      width: 160,
      render: (text: any, record: any) => (
        <>
        {userType === "TESTER" && 
        <div className="combined-icon" onClick={() => setChipKey(record)}>
        <Space size={'large'}>
          <button
            onClick={() => {
              setIsCopy(true);
              setChipKeyId(record.id)
            }}
            className="project-content no-border-bg-transparent"
          >
            <Tooltip title="Copy">
              <img className="icons" src={Copy} alt="copy icon"/>
            </Tooltip>
          </button>
          {!record?.isCertification && <>
            <button
            onClick={() => {
                setChipKey(record)
                setIsUpdate(true)
            }}
            className="project-content no-border-bg-transparent"
          >
            <Tooltip title="Edit">
              <img className="icons" src={Edit} alt="edit icon"/>
            </Tooltip>
          </button>
          <button
            onClick={() => {
              setChipKeyId(record.id)
              setReferredList(record.referredIn)
              setIsDelete(true)
            }}
            className="project-content no-border-bg-transparent"
          >
            <Tooltip title="Delete">
              <img className="icons" src={Delete} alt="delete icon"/>
            </Tooltip>
          </button></>}
        </Space>
      </div>}</>
      
      ),
    } : null},
  ];

  const ChipKeyData: any = chipkeyDtoList;

  const handleUpdate = (changedValues) => {
    const updatedProperties = chipKey.properties.map((property) => {
      const changedValue = changedValues[property.type];
      return changedValue ? { ...property, value: changedValue } : property;
    });
    const updatedChipKey = {
      ...chipKey,
      properties: updatedProperties,
    };
    dispatch(chipKeyUpdate(updatedChipKey)).then((res) => {
      updateRecord(res)
    });
  };

  const updateRecord = (res: any) => {
    const updatedChipKeys = chipkeyDtoList.map(ck => {
      if (ck.id === res?.payload?.keySet?.id) {
        return res?.payload?.keySet;
      } else {
        return ck;
      }
    });
    setChipkeyDtoList(updatedChipKeys);
    setChipKey(res?.payload?.keySet);
  }

  const RenderProperty = (type: string) => {
    return (
      <div key={type} className="card-content-container">
        <div className="card-key-title">{type}</div>
        {chipKey.properties?.map((data: any, index: Key) => (
          <>
            {data.type === type && (
              <div key={type} className="card-profile-value">
                <EditableFormItem
                  fieldKey={type}
                  rules={[
                    { required: true, message: `Please enter the ${type}!` },
                    {
                      pattern: /^[0-9A-Fa-f]{32}$/,
                      message: `${type} should be 32 digit in hexadecimal format!`,
                    },
                  ]}
                  value={data.value}
                  onUpdate={handleUpdate}
                  isEditable={chipKey.isCertification || userType !== 'TESTER'}
                  form={form}
                />
              </div>
            )}
          </>
        ))}
      </div>
  );
};

const rowClassName = (record) => {
  if (chipKey && chipKey?.id === record.id) {
    return 'selected-row';
  }
  return '';
};

  return (
    <>
      {contextHolder}
      <SubHeader OnClickEvent={OnClickEvent} IsSelectAll={false}  SelectedIds={null} />
      <Row className="card-profile-row">
        <Col className="card-profile-col" xs={2} sm={4} md={8} lg={8} xl={8}>
          <Input size="large" className='search-bar' placeholder="Search by name" suffix ={<SearchOutlined />} onChange={e => {
              const currValue = e.target.value;
              setSearchedText(currValue)
            }}/>
          <TableContainer
            tableData={ChipKeyData}
            column={columns}
            className={"card-profile-table"}
            loading={loading}
            scrollY='65vh'
            rowClassName={rowClassName}
          />
        </Col>
        <Col
          className="card-profile-col"
          span={1}
          xs={2}
          sm={4}
          md={15}
          lg={15}
          xl={15}
          style={{marginTop: '0.6%'}}
        >
          {chipKey?.name === undefined ? (
            <div className="card-profile-container">
              <img className="card-profile-img" src={SelectionIcon} alt="" />
              <div className="card-profile-title">Please select a chip key</div>
            </div>
          ) : (
            <div className="card-profile-content-container">
              <div className="card-profile-title-name">{chipKey?.name}</div>
              <div className="card-content-container">
                  <div className="card-key-title">Scheme</div>
                  <div className="card-profile-value">{SchemeTag[chipKey?.scheme] || chipKey?.scheme}</div>
              </div>
              {userType !== 'TESTER' && <div className="card-content-container">
                  <div className="card-key-title">Owner</div>
                  <div className="card-profile-value">{chipKey?.owner?.email}</div>
              </div>}
              <div className="card-data-container">
                <Form
                  form={form}
                  key={chipKey.id}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  {RenderProperty('AC')}
                  {RenderProperty('SMC')}
                  {RenderProperty('SMI')}
                  {RenderProperty('CVV')}
                  {RenderProperty('ICVV')}
                  {RenderProperty('CVV2')}
                  {RenderProperty('dCVV')}
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {isModalOpen && <CreateEditChipKeyModal
          isOpen={isModalOpen}
          onClose={oncloseModal}
          onFinish={onFinish}
          isUpdate={false}
        />}
      {isUpdate && <CreateEditChipKeyModal
        isOpen={isUpdate}
        onClose={oncloseModal}
        onFinish={onFinish}
        value={chipKey}
        isUpdate={true}
      />}
      {isDelete && <DeleteModal
        isOpen={isDelete}
        onClose={oncloseModal}
        onFinish={onFinish}
        title="Chip key"
        values={referredList}
      />}
      {isCopy && <CopyModal
          isOpen={isCopy}
          onClose={oncloseModal}
          onFinish={onFinish}
          title="Chip key"
          values={keyNames}
        />}
    </>
  );
}
