import { Modal, Progress } from "antd";
import { IModal } from "../../Interface/Interfaces";
import { useEffect, useState } from "react";

const ProgressModal : React.FC<IModal> = ({isOpen, onClose}) => {
    const [seconds, setSeconds] = useState(1);

    useEffect(() => {
      let interval;
      if(seconds <= 100) {
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
          }, 1000);
      } else {
          onClose()
          clearInterval(interval)
      }

      return () => {setSeconds(0); clearInterval(interval)}
    }, [])

    return(
      <Modal
        className="progress-modal"
        centered
        open={isOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        closable={false}
      >
        <span>Please wait while executing testcase(s)</span>
            <Progress percent={seconds} status="active" 
                size={[400, 15]} strokeColor={'#44916C'} /> 
      </Modal>
    )
}

export default ProgressModal;