import { Col, Modal, Row } from "antd";
import React from "react";

interface IFilterModal {
  isOpen: boolean;
  onClose: () => void;
  onChange: (values: any) => void;
  columns: any;
  selectedColumns: any;
}

const excludedKeys = ["name", "actions", "status"];

const TestCaseFilterModal: React.FC<IFilterModal> = ({
  isOpen,
  onClose,
  onChange,
  columns,
  selectedColumns
}) => {
  const filteredColumns = columns.filter(
    (column: any) => !excludedKeys.includes(column.key)
  );

  const activeBtnStyle: React.CSSProperties = {
    color: '#fff',
    backgroundColor: '#44916c',
    boxShadow: '0 2px 0 rgba(3, 55, 20, 0.23)',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 500,
    border: '1px solid #ccc',
    borderRadius: '3px'
  };

  const inActiveBtnStyle: React.CSSProperties = {
    color: 'black',
    backgroundColor: '#fff',
    boxShadow: '0 2px 0 rgba(1, 1, 1, 0.10)',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 500,
    border: '1px solid #ccc',
    borderRadius: '3px'
  };

  return (
    <Modal
      open={isOpen}
      width={800}
      title="Column visibility control"
      footer={null}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText='Filter'
    >
      <Row>
        {filteredColumns?.map((C: any,index:any) => (
          <Col xs={24} sm={8} md={6} lg={6} xl={6} key={C.title}>
            <div
              className="filter-wrapper" 
              style={index === 0 || index === 1 ? activeBtnStyle : selectedColumns.includes(C.key) ? activeBtnStyle : inActiveBtnStyle}
              onClick={() => index === 0 || index === 1 ? '':onChange(C.key)}
            >
              {C.title}
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default TestCaseFilterModal;
