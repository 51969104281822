import { Modal  } from "antd";
import { IModal } from "../../Interface/Interfaces";
import { WarningTwoTone } from "@ant-design/icons";

const WarningModal: React.FC<IModal> = ({ isOpen,  onClose, onFinish,  }) => {
    return(
        <Modal
            title={
            <span>
                <WarningTwoTone twoToneColor={'#faad14'}/> Warning
            </span>
            }
            okText='End session'
            centered
            open={isOpen}
            cancelText="Cancel"
            className="centered-modal warning-modal"
            onOk={onFinish}
            onCancel={()=>{onClose()}}
            width={350}
            okButtonProps={{ style: { backgroundColor: '#D13F3' } }}
        >
            <div className='delete-modal-content'>
                <span>
                    There is already a session running, please end the session to continue!
                </span>
            </div>
        </Modal>
    )
};

export default WarningModal;
