import { useState, useEffect } from 'react';

const ErrorBoundary = ({ children }) => {
const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const errorHandler = (event) => {
            const { error, message, filename, lineno, colno } = event;
            console.error(error, message, filename, lineno, colno);
            setHasError(true);
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    useEffect(() => {
        setHasError(false);
    }, [children]);

if (hasError) {
    return (
        <div className="ErrorBoundary">
            <h2>Oops! Something went wrong.</h2>
            <p className='para-info'>We apologize for the inconvenience. Please try again later or contact support.</p>
        </div>
        );
}

    return <>{children}</>;
};

export default ErrorBoundary;
