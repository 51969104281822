import { Table } from "antd";

const TableContainer = ({
  tableData,
  column,
  className,
  pagination,
  loading,
  size,
  scrollY,
  style,
  rowClassName,
  expandedRowKeys,
  expandHandler,
}: any) => {
  return (
    <Table
        showSorterTooltip={false}
        rowKey={(record) => record?.id}
        scroll={pagination === 'false' ? { y: scrollY }  : {}} 
        className={className}
        columns={column}
        loading={loading}
        dataSource={tableData}
        pagination={pagination === "false" ? false : { pageSize: 6, showSizeChanger: false }}
        size={size}
        style={style}
        rowClassName={rowClassName}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpand: expandHandler,
        }}
        sortDirections={['descend']}
      />
  );
};

export default TableContainer;
