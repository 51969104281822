import React from 'react';
import { Table } from 'antd';
import { ITable } from '../../Interface/Interfaces';

const MultiSelectionTableContainer: React.FC<ITable> = ({
  tableData,
  column,
  className,
  loading,
  scrollX,
  scrollY,
  pagination,
  selectedRowKeys, 
  onSelectedRowsChange, 
  hideCheckbox, 
  rowClassName,
  onRow
}: any) => {
  const rowSelection = hideCheckbox
    ? undefined  
    : {
        selectedRowKeys,
        onChange: (keys) => {
          onSelectedRowsChange(keys);
        },
      };

  return (
    <Table
      showSorterTooltip={false}
      rowKey={(record) => record.id}
      rowSelection={rowSelection}
      className={className}
      columns={column}
      loading={loading}
      dataSource={tableData}
      pagination={pagination === false ? false : { pageSize: 5 }}
      scroll={{ x: scrollX, y: scrollY }}
      size="large"
      rowClassName={rowClassName}
      onRow={onRow}
    />
  );
};

export default MultiSelectionTableContainer;
