import { Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IModal } from "../../Interface/Interfaces";
import FloatingLabelInput from "../../Components/FloatingLabel/Input/FloatingLabelInput";
import { useSelector } from "react-redux";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";

const CreateEditChipKeyModal: React.FC<IModal> = ({ isOpen, onClose, onFinish, value, isUpdate  }) => {
  const [form] = Form.useForm();
  const [keyNames, setKeyNames] = useState<any>([''])
  const [schemeOptions, setSchemeOptions] = useState<any>([])
  
  const chipKeyDtoList = useSelector(
    (state: any) => state.lithosApiReducer.chipkeyDtoList
  )
  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  )

  const initialValues = {
    Name: value?.name,
    AC: value?.properties[0]?.value,
    SMC: value?.properties[1]?.value,
    SMI: value?.properties[2]?.value,
    CVV: value?.properties[3]?.value,
    ICVV: value?.properties[4]?.value,
    CVV2: value?.properties[5]?.value,
    dCVV: value?.properties[6]?.value,
    Id: value?.id,
  };

  useEffect(() => {
    chipKeyDtoList?.map((key) => (
      key?.name !== "" && !keyNames.includes(key.name) && setKeyNames((keyNames) => [...keyNames, key.name.toLowerCase()])
    ))  
    const schemes = masterData?.map((S: any) => ({
      value: S.schemeName,
      label: S.schemeName.toUpperCase(),
    }));
    setSchemeOptions(schemes)
    if (schemes?.length === 1) {
      form.setFieldsValue({
        scheme: schemes[0]?.value
      });
    }
  },[])

  return (
    <Modal
        className="create-chip-key-modal"
        title={isUpdate ? 'Update Chip key' : 'Create Chip key'}
        okText={isUpdate ? 'Update' : 'Create'}
        centered
        open={isOpen}
        onOk={()=>form.submit()}
        cancelText="Discard"
        onCancel={()=>{onClose();form.resetFields()}}
      >
        <Form
          form={form}
          name="basic"
          className="modal-form-container"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          {!isUpdate && <>
            <Form.Item
              className="modal-form"
              name="Name"
              rules={[
                { required: true, message: "Please enter the name!" },
                {
                  message: 'Chipkeys name already exists!',
                  validator: (_, value) => {
                    if (value !== "" && keyNames.includes(value.toLowerCase())) {
                      return Promise.reject(new Error('Chipkeys name already exists!'));
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <FloatingLabelInput label='Name' placeholder="Name" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="scheme"
              rules={[{ required: true, message: "Scheme cannot be empty!" }]}
            >
            <FloatingLabelSelect
                label="Scheme"
                showSearch
                className="modal-input"
                optionFilterProp="children"
                options={schemeOptions}
              />
            </Form.Item>
          </>}
          <Form.Item
            className="modal-form"
            name="AC"
            rules={[
                {
                  required: true,
                  message: "Please enter the AC!",
                },
                {
                  pattern: /^[0-9A-Fa-f]{32}$/,
                  message: "AC should be 32 digit in hexadecimal format!",
                },
              ]}
          >
            <FloatingLabelInput label='AC' placeholder="AC" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="SMC"
            rules={[
                {
                  required: true,
                  message: "Please enter the SMC!",
                },
                {
                  pattern: /^[0-9A-Fa-f]{32}$/,
                  message: "SMC should be 32 digit in hexadecimal format!",
                },
            ]}
          >
          <FloatingLabelInput label='SMC' placeholder="SMC" />
          </Form.Item>
          <Form.Item
            className="modal-form"
            name="SMI"
            rules={[
              {
                required: true,
                message: "Please enter the SMI!",
              },
              {
                pattern: /^[0-9A-Fa-f]{32}$/,
                message: "SMI should be 32 digit in hexadecimal format!",
              },  
            ]}
          >
            <FloatingLabelInput label='SMI' placeholder="SMI" />
          </Form.Item>
          <Form.Item
              className="modal-form"
              name="CVV"
              rules={[
                { required: true, message: "Please enter the CVV!" },
                {
                  pattern: /^[0-9A-Fa-f]{32}$/,
                  message: "CVV should be 32 digit in hexadecimal format!",
                },  
              ]}
            >
            <FloatingLabelInput label='CVV' placeholder="CVV" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="ICVV"
              rules={[
                { required: true, message: "Please enter the iCVV!" },
                {
                  pattern: /^[0-9A-Fa-f]{32}$/,
                  message: "iCVV should be 32 digit in hexadecimal format!",
                },  
              ]}
            >
            <FloatingLabelInput label='iCVV' placeholder="iCVV" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="CVV2"
              rules={[
                { required: true, message: "Please enter the CVV2!" },
                {
                  pattern: /^[0-9A-Fa-f]{32}$/,
                  message: "CVV2 should be 32 digit in hexadecimal format!",
                },  
              ]}
            >
            <FloatingLabelInput label='CVV2' placeholder="CVV2" />
            </Form.Item>
            <Form.Item
              className="modal-form"
              name="dCVV"
              rules={[
                { required: true, message: "Please enter the dCVV!" },
                {
                  pattern: /^[0-9A-Fa-f]{32}$/,
                  message: "dCVV should be 32 digit in hexadecimal format!",
                },  
              ]}
            >
            <FloatingLabelInput label='dCVV' placeholder="dCVV" />
            </Form.Item>
            <Form.Item hidden name="Id" >
            <Input  />
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default CreateEditChipKeyModal;
