import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import { SideBarConfig } from "../../Constants/Constants";
import PayhuddleLogo from "../../Assets/PHLogo.svg";
import PayhuddleLogoCollapsed from "../../Assets/PHLogoCollapse.svg";
import Icon, { AppstoreFilled, HddFilled } from "@ant-design/icons";
import TestCaseIcon from "../../Assets/testcases.svg";
import Users from "../../Assets/UserManagement.svg";
import { useSelector } from "react-redux";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SidebarContainer = () => {
  const [index, setIndex] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const testPlanLabel = 'Test plans'
  const administrationLabel = "Administration"
  const usersLabel = "Users"
  const orgLabel = 'Organization'

  const handleMouseEnter = () => {
    setIsCollapsed(false);
  };

  const handleMouseLeave = () => {
    setIsCollapsed(true);
  };

  const removeLabelFromSideBar = (label: string) => {
    const updatedSideBarConfig = SideBarConfig.filter(item => item.label !== label);
    SideBarConfig.length = 0;
    Array.prototype.push.apply(SideBarConfig, updatedSideBarConfig);
  }

  const masterData = useSelector(
    (state: any) => state.lithosApiReducer.masterData
  );

  const userType = useSelector(
    (state: any) => state.lithosApiReducer.userType
  );

  useEffect(() => {
    const isTestPlanPresent = SideBarConfig.find(item => item.label === testPlanLabel)
    const isAdministrationPresent = SideBarConfig.find(item => item.label === administrationLabel)
    const hasCustomTestplan = masterData?.some(
      (item: { properties: { CustomTestplan: boolean; }; }) => item?.properties?.CustomTestplan === true
    );
    const hasCertificationTestplan = masterData?.some(
      (item: { properties: { CertificationTestplan: boolean; }; }) => item?.properties?.CertificationTestplan === true
    );
    if(userType === 'ADMIN'){
      if(!isAdministrationPresent){
        const userManagement = {
          label: administrationLabel,
          key: 1,
          icon: <HddFilled style={{fontSize: '18px'}} /> ,
          URL: "/administration",
          title: "administration",
          children: [
            {
              label: usersLabel,
              key: 11,
              icon: <Icon component={() => <img src={Users} alt="users"/>} />,
              URL: "/users",
              title: "Help",
            },
            {
              label: orgLabel,
              key: 12,
              icon: <AppstoreFilled style={{fontSize: '18px'}} />,
              URL: "/organization",
              title: "Help",
            }
          ],
        }
        SideBarConfig.splice(userManagement.key, 0, userManagement);
      }
      if(!isTestPlanPresent){
        const testPlan = {
          label: testPlanLabel,
          key: 3,
          icon: <Icon component={() => <img src={TestCaseIcon} alt="testplans" />} />,
          URL: "/testplans",
          title: "Settings",
        };
        SideBarConfig.splice(testPlan.key, 0, testPlan);
      }
    }
    else{
      if(isAdministrationPresent){
        removeLabelFromSideBar(administrationLabel)
      }
      else if(hasCustomTestplan || hasCertificationTestplan){
        if(!isTestPlanPresent){
          const testPlan = {
            label: testPlanLabel,
            key: 3,
            icon: <Icon component={() => <img src={TestCaseIcon} alt="testplans" />} />,
            URL: "/testplans",
            title: "Settings",
          };
          SideBarConfig.splice(testPlan.key, 0, testPlan);
        }
      }
      else{
        removeLabelFromSideBar(testPlanLabel)
      } 
    }
  }, [masterData, userType]);

  return (
    <Sider
      className="sidebar-container"             
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-wrapper">
        <div className="sidebar-upper">
          <Menu mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']}>
            {SideBarConfig?.map((r: any) => {
              return r?.children?.length > 0 ? (
                <SubMenu
                  key={r.key + 'sub-menu'}
                  className={index === r.key ? 'menu-item-active' : 'menu-item'}
                  icon={r.icon}
                  title={
                    <span key={r.key + 'sub-menu-title'}>
                      <span className="menu-child-label" key={r.key + r.URL + 'sub-menu-title'}>
                        {r.label}
                      </span>
                    </span>
                  }
                >
                  {r.children.map((c: any) => (
                    <Menu.Item
                      key={c.key + 'sub-menu-item'}
                      onClick={() => setIndex(c.key)}
                      className={index === c.key ? 'submenu-item-active' : 'submenu-item'}
                      icon={c.icon}
                    >
                      <NavLink to={c.URL}>{c.label}</NavLink>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                r.key <= 7 && (
                  <Menu.Item
                    key={r.key + 'menu-items'}
                    onClick={() => setIndex(r.key)}
                    className={index === r.key ? 'menu-item-active' : 'menu-item'}
                    icon={r.icon}
                  >
                    <NavLink to={r.URL}>{r.label}</NavLink>
                  </Menu.Item>
                )
              );
            })}
          </Menu>
        </div>
        <div className="sidebar-footer">
          {isCollapsed ? (
            <img src={PayhuddleLogoCollapsed} alt="Footer Logo" className="payhuddle-logo-collapsed" />
          ) : (
            <div>
              <p className="footer-text">powered by</p>
              <img src={PayhuddleLogo} alt="Footer Logo" className="payhuddle-logo-expanded" />
              <p className="footer-text">{`Version ${process.env.REACT_APP_LITHOS_VERSION}`}</p>
            </div>
          )}
        </div>
      </div>
    </Sider>
  );
};

export default React.memo(SidebarContainer);