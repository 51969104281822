import React, { useState } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

interface OptionType {
  label: string;
  value: string;
}

interface FloatingLabelSelectProps extends SelectProps<string> {
  label: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  options?: OptionType[]; 
}

const FloatingLabelSelect: React.FC<FloatingLabelSelectProps> = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, required, options, disabled } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        showSearch
        className='modal-input'
        value={value}
        disabled={label !== 'Group name' && options?.length === 1}
        filterOption={(input, option) =>
          (option?.label || "").toString().toLowerCase().includes(input.toLowerCase()) as boolean
        }
        options={options}
        {...props} 
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatingLabelSelect;
