import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export default function SearchBar({className}:any) {  
  return (
    <>
    <Input size="large" className={className !== undefined ? `search-bar ${className}` : 'search-bar'} placeholder="Search" suffix ={<SearchOutlined />}/>
    </>
  )
}
