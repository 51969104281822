import axios from "axios";
import jwt_decode from "jwt-decode";
import { AuthorizationHeader } from "../Utils/Utils";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: AuthorizationHeader(''),
  withCredentials: true,
});

export const AddAxiosHeaders = (headers) => {
  Axios.defaults.headers = {
    ...Axios.defaults.headers,
    ...headers,
  };
};

export const checkHeaderExistence = (headerName: string) => {
  if(Axios.defaults.headers.hasOwnProperty(headerName)){
    return true
  }
  else{
    localStorage.setItem("IsAuth", "false")
    return false
  }
};

export const removeAxiosHeader = (headerName) => {
  if (Axios.defaults.headers.hasOwnProperty(headerName)) {
    delete Axios.defaults.headers[headerName];
  }
};


export const isTokenExpired = (token: any): boolean => {
  if (!token) {
    localStorage.setItem("IsAuth", "false")
    return true;
  }
  const decodedToken: { exp: number } = jwt_decode(token);
  const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
  const currentTime = Date.now();
  if(currentTime > expirationTime){
    localStorage.setItem("IsAuth", "false")
    return true
  }
  else{
    return false
  }
};