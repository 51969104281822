import { Form, Modal } from "antd";
import { IModal } from "../../Interface/Interfaces";
import FloatingLabelSelect from "../../Components/FloatingLabel/Select/FloatingLabelSelect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormatSelectOption } from "../../Utils/Utils";

const CreateProfileModal: React.FC<IModal> = ({ isOpen,  onClose, onFinish, value  }) => {
    const [form] = Form.useForm()
    const [mti, setMTI] = useState<any>([])
    
    const masterData = useSelector(
        (state: any) => state.lithosApiReducer.masterData
    )
    
    useEffect(() => {
        const selectedSchemeData = masterData?.find((data) => data.schemeName?.toLowerCase() === value?.scheme?.toLowerCase());
        if (selectedSchemeData) {
            const libraryData = selectedSchemeData?.libraries?.find((data) => data.version === value?.version)
            setMTI(libraryData?.supportedMessages)
        }
    }, [])

    return(
        <Modal
            className="create-test-plan-modal"
            title="Create Profile"
            centered
            open={isOpen}
            onOk={() => {form.submit();}}
            okText="Create"
            cancelText="Discard"
            onCancel={()=>{onClose();form.resetFields()}}
        >
            <Form
                form={form}
                name="basic"
                className="modal-form-container"
                onFinish={onFinish}
                autoComplete="off"
            >
            <Form.Item
                className="modal-form"
                name="MTI"
                rules={[{ required: true, message: "Please select the MTI!" }]}
            >
            <FloatingLabelSelect
                label="MTI"
                showSearch
                className="modal-input"
                optionFilterProp="children"
                options={FormatSelectOption(mti)}
            />
            </Form.Item>
            </Form>
        </Modal>
    )
};

export default CreateProfileModal;
