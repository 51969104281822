import { Input, Space, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react'
import Delete from "../../Assets/Delete.svg";
import Edit from "../../Assets/Edit.svg"
import { useDispatch } from 'react-redux';
import { CreateOrganization, DeleteOrganization, UpdateOrganization, getOrganizations } from '../../Redux/Actions/Actions';
import { BreadCrumbConfig, HeaderTitle } from '../../Redux/Reducers/UtilReducer';
import SubHeader from '../../Components/Header/SubHeader';
import TableContainer from '../../Components/Table/TableContainer';
import { SearchOutlined } from '@ant-design/icons';
import DeleteModal from '../../Components/DeleteModal/DeleteModal';
import { ShowToaster } from '../../Components/Notifications/Notifications';
import CreateEditOrganizationModal from './CreateEditOrganizationModal';

const Organization = () => {
    const [api, contextHolder] = notification.useNotification()
    const dispatch = useDispatch();
    const [searchedText, setSearchedText] = useState("");
    const [org, setOrg] = useState<any>({})
    const [modalState, setModalState] = useState({
        isCreate: false,
        isDelete: false,
        isUpdate: false,
        orgId: "",
    });
    const [orgData, setOrgData] = useState<any>([]);

    useEffect(() => {
    dispatch(HeaderTitle("Organization"));
    dispatch(
        BreadCrumbConfig({
        title: "Organization",
        href: "/organization",
        preventPush: true,
        } as any)
    );
    loadData();
    }, [])

const loadData = async () => {
    dispatch(getOrganizations()).then((data: any) => {
        setOrgData(data?.payload?.organizationList || []);
    });
};

const onOrgDeleteClick = (orgId) => {
    setModalState({ ...modalState, isDelete: true, orgId: orgId });
};

const oncloseModal = () => setModalState((prevState) => ({ ...prevState, isCreate: false,isUpdate: false, isDelete: false }));

const onFinish = async (values) => {
    const { isCreate, isDelete, isUpdate, orgId: userId } = modalState;
    if (isCreate) {
        await dispatch(CreateOrganization(values)).then((res) => {
            ShowToaster(res.payload, api);
            setOrgData([res?.payload?.organization, ...orgData])
        });
    } else if (isDelete) {
        await dispatch(DeleteOrganization(userId)).then((res) => {
            ShowToaster(res.payload, api);
            setOrgData(data =>
                data.filter(org => {
                    return org.id !== modalState.orgId;
                }),
            );
        });
    } else if (isUpdate) {
        await dispatch(UpdateOrganization(values)).then((res) => {
            ShowToaster(res.payload, api);
            const updatedOrgs = orgData.map(org => {
                if (org.id === res?.payload?.organization?.id) {
                    return res?.payload?.organization;
                } else {
                    return org;
                }
            });
            setOrgData(updatedOrgs);
            setOrg(res?.payload?.organization)
        });
    }
    oncloseModal();
};

const columns: ColumnsType = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        filteredValue: [searchedText],
        onFilter: (value: any, record: any) => {
        return String(record?.name).toLowerCase().includes(value.toLowerCase())
        },
        sorter: (a: any, b: any) => {
            return a.name.localeCompare(b.name); 
        },
        render: (text) => <div className="session-content">{text}</div>,
    },
    {
        title: "Contact number",
        dataIndex: "contactNo",
        key: "contactNo",
        render: (text) => <div className="session-content">{text}</div>,
        sorter: (a: any, b: any) => a.contactNo.length - b.contactNo.length,
    },
    {
        title: "Contact email",
        dataIndex: "contactEmail",
        key: "contactEmail",
        render: (text) => <div className="session-content">{text}</div>,
        sorter: (a: any, b: any) => a.contactEmail.length - b.contactEmail.length,
    },
    {
        title: "Created By",
        key: "owner",
        dataIndex:  ["owner", "email"],
        render: (text) => <div className="session-content">{text}</div>,
        sorter: (a: any, b: any) => a.owner - b.owner,
    },
    {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text: any, record: any) => (
        <div className="combined-icons">
            <Space size={"large"}>
                <button
                    onClick={() => {
                    setModalState({ ...modalState, isUpdate: true });
                    setOrg(record);
                    }}
                    className="project-content no-border-bg-transparent"
                >
                    <Tooltip title="Copy">
                    <img className="icons" src={Edit} alt="Edit icon" />
                    </Tooltip>
                </button>
                <button
                    onClick={() => onOrgDeleteClick(record?.id)}
                    className="project-content no-border-bg-transparent"
                >
                    <Tooltip title="Delete">
                    <img className="icons" src={Delete} alt="Delete icon" />
                    </Tooltip>
                </button>
            </Space>
        </div>
        ),
    },
    ];

    return (
    <>
        {contextHolder}
        <SubHeader OnClickEvent={() => setModalState({ ...modalState, isCreate: true })} IsSelectAll={false} SelectedIds={null}/>
        <Input size="large" className="search-bar" placeholder="Search by name" suffix={<SearchOutlined />} onChange={(e) => setSearchedText(e.target.value)} />
        <TableContainer tableData={orgData} column={columns} className="session-table" scrollY="65vh" />
        {modalState.isCreate && (
        <CreateEditOrganizationModal isOpen onClose={oncloseModal} onFinish={onFinish} isUpdate={false}/>
        )}
        {modalState.isUpdate && (
        <CreateEditOrganizationModal isOpen onClose={oncloseModal} onFinish={onFinish} isUpdate={true} value={org}/>
        )}
        {modalState.isDelete && (
        <DeleteModal            
            title="Organization"
            values={undefined}
            isOpen={modalState.isDelete}
            onClose={oncloseModal}
            onFinish={onFinish}
        />
        )}
    </>
    );
}

export default Organization;