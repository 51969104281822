
import { Button, Checkbox, Col, Modal, Row, Space } from "antd";
import { IModal } from "../../Interface/Interfaces";
import { createContext, useContext, useMemo, useState } from "react";
import { ProjectStreamLogContext } from "../Projects/ProjectLogs";
import Errors from '../../Assets/Errors.svg'
import Info from '../../Assets/Info.svg'
import Transaction from '../../Assets/Transaction.svg'
import RawLogModal from "./RawLogModal";
import { useLocation } from "react-router-dom";
import { SystemLogStreamLogContext } from "./SystemLogs";
import { HostLogLogStreamLogContext } from "../Projects/AnalyzeLogs";
import TableContainer from "../../Components/Table/TableContainer";
import { ColumnsType } from "antd/es/table";
import { getSubRowKeys, renameSubElements } from "../../Utils/Utils";

export const RawLogContext = createContext({})

const LogModal: React.FC<IModal> = ({isOpen, onClose, onFinish }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedErrors, setSelectedErrors] = useState<any>([]);
    const location = useLocation();
    let tempContext;
    
    if(location.pathname === '/projectlogs'){
        tempContext = ProjectStreamLogContext;
    }
    else if(location.pathname === '/systemlogs'){
        tempContext = SystemLogStreamLogContext
    }   
    else if(location.pathname === '/testcases/analyzelogs'){
        tempContext = HostLogLogStreamLogContext
    }


    const handleCheckboxChange = (error, isChecked) => {
    if (isChecked) {
        setSelectedErrors([...selectedErrors, error]);
    } else {
        setSelectedErrors(selectedErrors.filter(e => e !== error));
    }
    };

    const handleButtonClick = (action) => {
        onFinish({action: action, errors: selectedErrors, stream: stream})
    };

    const streamLog = useContext<any>(tempContext)
    const { elements, error, info, stream, requestDump, responseDump, acceptedErrors } = streamLog;
    const logData = useMemo(() => renameSubElements(elements), [elements]);

    const rawLog = useMemo(
        () => ({
            sentValue: requestDump,
            receivedValue: responseDump,
        }),
        [requestDump, responseDump]
    );

    const columns: ColumnsType = [
        {
            title: <div style={{ marginLeft: logData.some(record => record.children) ? '32px' : '28px' }}>DE</div>,
            dataIndex: "id",
            key: "id",
            width: 120,
            render: (text) => <div className="project-content" style={{marginLeft: '30px'}}>{text?.toUpperCase()}</div>
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: 200,
            render: (text) => <div className="project-content">{text}</div>
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            width: 100,
            render: (text) => <div className="project-content">{text}</div>,
        },
        {
            title: "Request",
            dataIndex: "sentValue",
            key: "sentValue",
            width: 200,
            render: (text) => <div className="project-content">{text}</div>
        },
        {
            title: "Response",
            dataIndex: "receivedValue",
            key: "receivedValue",
            width: 200,
            render: (text) => <div className="project-content">{text}</div>
        },
    ];
    
    const rowClassName = (record) => {
        const subRowKeys = getSubRowKeys(logData);
        return subRowKeys?.some(subKey => record.id !== subKey && record.id.startsWith(subKey)) ? 'sub-elements-row' : '';
    };

    return (
    <>
    <Modal
        className="log-modal"
        title={`${stream} logs`}
        centered
        open={isOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={onClose}
        >
            <div >
                <div>
                    <Row>
                        <Col span={12}>
                        <div className='log-error-info-table'>
                            <div className='log-t-head'>
                                <img src={Errors} style={{marginRight:'8px'}}/>
                                Error<div className={`${error?.length > 0 && 'error-count'}`}>{error?.length > 0 && error?.length}</div>
                                {(location.pathname === '/testcases/analyzelogs' && selectedErrors?.length > 0) &&
                                <div className="tertiary-btn raw-log-btn" style={{marginTop: '-2px'}}>
                                    <Space>
                                        <Button size="small" type="primary" danger ghost onClick={() => handleButtonClick('Reject')}>Reject</Button>
                                        <Button size="small" ghost style={{color: "#44916c", border: "1px solid #44916c"}} onClick={() => handleButtonClick('Accept')}>Accept</Button>
                                    </Space>
                                </div>}
                            </div>
                            <div className="log-error-info-body">
                                {(error === null || error?.length === 0)? (
                                <div>
                                    <div className='no-data-message'>No errors!</div>
                                </div>
                                ) : (
                                error?.map((error, index) => (
                                    <div key={index} className='log-error-info-data'>
                                        <Space>
                                            {location.pathname === '/testcases/analyzelogs' && <Checkbox onChange={(e) => handleCheckboxChange(error, e.target.checked)} />}
                                            <div className={acceptedErrors?.includes(error) ? 'grey' : 'red'}>{error}</div>
                                        </Space>
                                    </div>
                                ))
                                )}
                            </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='log-error-info-table'>
                                <div className='log-t-head'> <img style={{marginRight:'8px'}} src={Info}/>Info</div>
                                    <div className="log-error-info-body">
                                        {(info === null || info?.length === 0) ? (
                                        <div>
                                            <div className='no-data-message'>No info!</div>
                                        </div>
                                        ) : (
                                        info?.map((info: any, index: number) => (
                                            <div key={index} className='log-error-info-data'>
                                                <div className="grey">{info}</div>
                                            </div>
                                        ))
                                        )}
                                    </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col span={24}>
                            <div className='log-data-table'>
                                    <div className='log-head'> <img src={Transaction} style={{marginRight:'8px'}}/>Transaction 
                                        <div className="tertiary-btn raw-log-btn" onClick={() => setIsModalOpen(true)}>Raw log</div>
                                    </div>  
                                    <TableContainer
                                        tableData={logData}
                                        column={columns}
                                        className={"log-data-antd-table"}
                                        scrollY='330px'
                                        pagination='false'
                                        rowClassName={rowClassName}
                                    />
                            </div> 
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
        <RawLogContext.Provider value={rawLog}>
            <RawLogModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onFinish={onFinish}
                />
        </RawLogContext.Provider>
    </>
    )
}

export default LogModal;