import { Button, Card, Col, Form, Input, Row, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import PayhuddleLogo from "../../Assets/PHLogo.svg";
import LithosLogo from "../../Assets/lithoslogo.png";
import { md5HashHandler } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivateRouteUrl, PublicRouterUrl } from "../../Constants/Constants";
import { EndSession, LoginHandler, checkStatus } from "../../Redux/Actions/Actions";
import WarningModal from "./WarningModal";
import { CheckCircleTwoTone } from "@ant-design/icons";

export default function Login() {
  const [form] = Form.useForm()
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const isAuthenticated = useSelector(
    (state: any) => state.lithosApiReducer.isAuth
  );
  const [errorMessage, setErrorMessage] = useState("");
  
  const isAuth = localStorage.getItem("IsAuth");

  useEffect(() => {
    dispatch(checkStatus()).then((result:any) => {
      if(result.payload === undefined){
        navigate("/sessiontimeout")
      }
    })
    const PrivateRoutes = PrivateRouteUrl.filter(
      (R: any) => R.URL === location.pathname
    );
    const PublicRoutes = PublicRouterUrl.filter(
      (R: any) => R.URL === location.pathname
    );
    if (isAuth === "false" || isAuth === null) {
      PrivateRoutes.length > 0 && navigate("/login");
    } else {
      PublicRoutes.length > 0 && navigate("/dashboard");
    }
  }, [isAuthenticated]);

  const onFinish = async (values: any) => {
    setIsLoading(true)
    if(isLoggedIn){
      const email = form.getFieldValue('EmailId')
      await dispatch(EndSession(email)).then(() => {
        setIsLoggedIn(false)
        setErrorMessage("")
        api.success({
          message: `Session ended successfully`,
          placement: "top",
          style: {
            width: 500,
          },
          icon: <CheckCircleTwoTone twoToneColor={'#8BAB58'}/>
        })
      })
    } else {
      setErrorMessage("");
      values.Password = md5HashHandler(values.Password);
      await dispatch(LoginHandler(values)).then((result: any) => {
        if (result.payload?.responseCode !== 'OK') {
          setErrorMessage(result.payload?.responseMessage);
        } 
        if(result?.payload?.responseCode === 'Unauthorized'){
          setIsLoggedIn(true)
        }
        if (isAuthenticated === true) {
          navigate("/dashboard");
        }
      });
    }
    setIsLoading(false)
  };

  const oncloseModal = () => {
    setIsLoggedIn(false)
  };

  return (
    <>
      {contextHolder}
      <div className="login-container">
      <Row>
        <div className={`loader ${isLoading ? "" : "hidden"}`}>
          <Spin size="default" />
        </div>
        <Col span={4} offset={8}>
          <Card
            title={
              <span className="payhuddle-logo">
                <img src={LithosLogo} alt="" />
              </span>
            }
            className="login-container-card"
          >
            <Form
              form={form}
              name="basic"
              className="login-form-container"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                className="login-form"
                name="EmailId"
                rules={[
                  { required: true, message: "Please enter your username!" },
                ]}
              >
                <Input className="login-input" placeholder="Username" />
              </Form.Item>
              <Form.Item
                className="login-form"
                name="Password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  className="login-input"
                  placeholder="Password"
                />
              </Form.Item>
              {errorMessage && (
                <p className="invalid-credentials">{errorMessage}</p>
              )}
              <Form.Item className="login-form-btn">
                <Button type="primary" className="login-btn" htmlType="submit">
                  Let's Begin
                </Button>
              </Form.Item>
              <div className="disclaimer-container">
                <div className="terms-title" >
                  <a href="https://www.payhuddle.com/terms-conditions" style={{ color: '#333333' }}>Terms & Conditions&nbsp; </a>
                </div>
                |
                <div className="policy-title" >
                  <a href="https://www.payhuddle.com/privacy-policy" style={{ color: '#333333' }}>&nbsp;Privacy Policy</a>
                </div>
              </div>
            </Form>
            <div className="card-footer-container">
              <div>
                <img className="litho-logo" src={PayhuddleLogo} alt="" />
              </div>
              <div className="copy-right-container">
                <div className="copy-right-content">
                  Copyright @ 2023{" "}
                  <a href="https://www.payhuddle.com/">PayHuddle</a> | All
                  Rights Reserved
                </div>
              </div>
            </div>
            </Card>
          </Col>
        </Row>
      </div>
      {isLoggedIn && <WarningModal
        isOpen={isLoggedIn}
        onClose={oncloseModal}
        onFinish={onFinish}
      />}
    </>
  );
}
