import { Button, Modal } from "antd";
import { IModal } from "../../Interface/Interfaces";

const SaveWithErrorsModal: React.FC<IModal> = ({ isOpen,  onClose, onFinish,  }) => {
    return(
        <Modal
            className="centered-modal"
            title="Save with error(s)"
            centered
            open={isOpen}
            onOk={onFinish}
            okText="Save"
            cancelText="Cancel"
            width={350}
            onCancel={()=>{onClose()}}
            footer={[
                <Button key="ok" type="primary" className='delete-btn' onClick={onFinish}>
                    Save
                </Button>,
                <div key="cancel" className='tertiary-btn cancel-btn' onClick={()=>{onClose()}}>
                    Cancel
                </div>,
        ]}
        >
            <div className='delete-modal-content'>
                <span>
                    Do you save configuration with error(s)?
                </span>
            </div>
        </Modal>
    )
};

export default SaveWithErrorsModal;
