import { createSlice } from "@reduxjs/toolkit";
import { IUtils } from "../../Interface/Interfaces";

const initialState: IUtils = {
  header_title: "",
  breadCrumbs: [
    {
      title: "Dashboard",
      href: "/dashboard",
      preventPush: true,
    },
  ],
};

export const UtilSlice = createSlice({
  name: "utilReducer",
  initialState,
  reducers: {
    HeaderTitle: (state, action) => {
      state.header_title = action.payload;
    },
    BreadCrumbConfig: (state, action: any) => {
      if (action.payload.preventPush === true) {
        state.breadCrumbs.length <= 2 && state.breadCrumbs.splice(1, 1);
        const exists = state.breadCrumbs.filter(
          (val: any) => val.title === action.payload.title
        );
        if (exists.length <= 0) {
          state.breadCrumbs.push(action.payload);
        }
        state.breadCrumbs = state.breadCrumbs.filter(
          (val: any) => val.preventPush == true
        );
        state.breadCrumbs.length > 2 && state.breadCrumbs.splice(1, 1);
      } else {
        const exists = state.breadCrumbs.filter(
          (val: any) => val.title === action.payload.title
        );
        if (exists.length <= 0) {
          state.breadCrumbs.push(action.payload);
          state.breadCrumbs = [...new Set(state.breadCrumbs)];
        }
        const index = state.breadCrumbs.findIndex(
          (item: any) => item.title === action.payload.title
        );

        if (index !== -1 && index < state.breadCrumbs.length - 1) {
          state.breadCrumbs.splice(index + 1);
        }
      }

      localStorage.setItem('Breadcrumbs', JSON.stringify(state.breadCrumbs));
    },
    InitializeBreadCrumbs: (state) => {
      const breadcrumbs = localStorage.getItem('Breadcrumbs');
      if (breadcrumbs) {
        state.breadCrumbs = JSON.parse(breadcrumbs);
      }
    },
  },
});

export const { BreadCrumbConfig, HeaderTitle, InitializeBreadCrumbs } = UtilSlice.actions;

export default UtilSlice.reducer;
